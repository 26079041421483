import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ZoneResponse } from '../../../../service/store/modules/zone/ZoneService.model';
import { useTranslation } from 'react-i18next';
import getTimerOptions from '../../../../service/store/modules/timer/util/getTimerOptions';
import ZoneConnected from '../DeviceOperation/ZoneConnected';
import type { TimerOperationViewProps } from './TimerOperation';
import TimerSelectConnected from './TimerSelectConnected';
import { ComposableDevicesStoreInfoResponse } from '../../../../service/store/hooks/useDevicesOperator';

/**
 * Part of AuthenticatedPages
 * @returns
 */
function TimerOperationHorizontal(props: TimerOperationViewProps) {
  const { composedDevice, deviceId, showTime } = props;
  const { t } = useTranslation();

  const { timers, device, menuItems } = composedDevice;
  const zones = device?.shelfSettings;

  return (
    <Box>
      <Stack direction={'column'} spacing={2} marginTop={3}>
        {Object.entries(zones ?? {})
          .filter(
            ([zoneIndex, zone]: [string, ZoneResponse]) =>
              zone.shelfStatus.operatingMode !== 'UNUSED'
          )
          .map(([zoneIndex, zone]: [string, ZoneResponse]) => (
            <Stack
              direction={'row'}
              key={zoneIndex}
              spacing={1}
              alignItems={'center'}
            >
              <Typography
                variant="subtitle2"
                color={'primary'}
                textTransform={'uppercase'}
                textAlign={'center'}
                marginRight={4}
                width={'100px'}
              >
                {t('timers.zone')} {zoneIndex}
              </Typography>
              <ZoneConnected
                key={`${deviceId}-${zoneIndex}`}
                item={{
                  zone,
                  device: device as ComposableDevicesStoreInfoResponse,
                }}
                zoneIndex={+zoneIndex}
              />
              <Stack direction={'row'} spacing={1}>
                {timers?.[zoneIndex]?.map((timer, indexTimer) => (
                  <TimerSelectConnected
                    key={`${indexTimer}-${timer?.menuItemId}`}
                    timer={timer}
                    showTime={showTime}
                    options={getTimerOptions(menuItems, zone)}
                    deviceId={deviceId ?? ''}
                    zoneIndex={zoneIndex}
                    indexTimer={indexTimer}
                  />
                ))}
              </Stack>
            </Stack>
          ))}
      </Stack>
    </Box>
  );
}

export default TimerOperationHorizontal;
