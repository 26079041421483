import { createSelector } from '@reduxjs/toolkit';
import { NormalizedGroup } from '../GroupsAdapter';
import {
  getDevicesIdsFromGroupDictionary,
  predicateFromGroupAndChildrenDictionaryRecursive,
} from './Group.selector';
import { fromBase64, toBase64 } from '../../../shared/util/base64';
import { RootState } from '../../store.model';
import StructureAPI from '../StructureAPI';
import { isNotNullOrUndefined } from '../../../shared/util/collection/isNotNullOrUndefined';
import _ from 'lodash';
export const getCommanderIdsFlexeserveDevicesSelector = createSelector(
  [
    (state: RootState) =>
      StructureAPI.endpoints.getStructureV2.select()(state).data,
    (_state: RootState, props: { groupId: string }) => toBase64(props.groupId),
  ],
  (structure, encodedGroupId) => {
    const composableDevicesCommanderIds =
      (structure?.entities &&
        getComposableDeviceFromGroupDictionary(
          structure?.entities.groups,
          encodedGroupId
        )
          .map(
            (encodedGroupId) =>
              structure?.entities.groups[encodedGroupId].devices?.[0]
          )
          .filter(isNotNullOrUndefined)
          .map(fromBase64)) ??
      [];

    const commandersIds =
      structure?.entities &&
      getDevicesIdsFromGroupDictionary(
        structure?.entities.groups,
        encodedGroupId
      );

    const commanderIdsNotInComposableDevice =
      commandersIds?.filter(
        (commanderId) => !composableDevicesCommanderIds?.includes(commanderId)
      ) ?? [];

    return _.concat(
      composableDevicesCommanderIds,
      commanderIdsNotInComposableDevice
    );
  }
);

export const getComposableDeviceFromGroupDictionary = (
  groupDictionary: Record<string, NormalizedGroup>,
  encodedGroupId: string
): string[] => {
  const groupId: string[] = [];
  predicateFromGroupAndChildrenDictionaryRecursive(
    groupDictionary,
    encodedGroupId,
    (group) => {
      group.composableDevice && groupId.push(group.id);
    }
  );
  return groupId;
};
