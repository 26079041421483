import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../shared/axiosBaseQuery';
import { REHYDRATE } from 'redux-persist';
import {
  DayPartAPIResponse,
  DayPartEditAPIResponse,
  ScheduleAPIResponse,
  ScheduleEditAPIResponse,
  StoreSchedulesUpdateRequiredAPIResponse,
} from './ScheduleAPI.model';
import { v4 as uuidv4 } from 'uuid';
import { toBase64 } from '../../shared/util/base64';

const ScheduleAPI = createApi({
  reducerPath: 'schedule',
  tagTypes: ['day-part-actions', 'schedule-actions', 'schedule-update'],

  baseQuery: axiosBaseQuery({ baseUrl: 'flexeserve/' }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints(build) {
    return {
      getDayPartByGroup: build.query<DayPartAPIResponse[], string>({
        query: (groupId: string) => {
          const encodedGroupId = toBase64(groupId);
          return {
            url: `group/${encodedGroupId}/dayparts`,
            method: 'get',
          };
        },
        providesTags: ['day-part-actions'],
      }),
      setDayPartByGroup: build.mutation<
        any,
        {
          dayPart: DayPartEditAPIResponse;
          groupId: string;
        }
      >({
        query: ({ dayPart, groupId }) => {
          const encodedGroupId = toBase64(groupId);
          const dayPartId = dayPart.id ?? uuidv4();
          return {
            url: `group/${encodedGroupId}/dayparts/${dayPartId}?recursive=true`,
            method: 'put',
            data: dayPart,
          };
        },
        invalidatesTags: ['day-part-actions'],
      }),
      deleteDayPartByGroup: build.mutation<
        any,
        {
          dayPartId: string;
          groupId: string;
        }
      >({
        query: ({ dayPartId, groupId }) => {
          const encodedGroupId = toBase64(groupId);
          return {
            url: `group/${encodedGroupId}/dayparts/${dayPartId}`,
            method: 'delete',
          };
        },
        invalidatesTags: ['day-part-actions'],
      }),
      getSchedulesByGroup: build.query<ScheduleAPIResponse[], string>({
        query: (groupId: string) => {
          const encodedGroupId = toBase64(groupId);
          return {
            url: `group/${encodedGroupId}/schedules`,
            method: 'get',
          };
        },
        providesTags: ['schedule-actions'],
      }),

      setSchedulesByRegion: build.mutation<
        any,
        {
          schedule: ScheduleEditAPIResponse;
          groupId: string;
        }
      >({
        query: ({ schedule, groupId }) => {
          const encodedGroupId = toBase64(groupId);
          const scheduleId = schedule.scheduleId ?? uuidv4();
          return {
            url: `group/${encodedGroupId}/schedules/${scheduleId}?recursive=true`,
            method: 'put',
            data: schedule,
          };
        },
        invalidatesTags: ['schedule-actions'],
      }),
      deleteScheduleByRegion: build.mutation<
        any,
        {
          scheduleId: string;
          groupId: string;
        }
      >({
        query: ({ scheduleId, groupId }) => {
          return {
            url: `group/${groupId}/schedules/${scheduleId}`,
            method: 'delete',
          };
        },
        invalidatesTags: ['schedule-actions'],
      }),
      // Operator
      getSchedulesUpdateRequiredByStore: build.query<
        StoreSchedulesUpdateRequiredAPIResponse['devices'],
        void
      >({
        query: () => {
          return {
            url: `operator/store/schedules`,
            method: 'get',
          };
        },
        providesTags: ['schedule-update'],
        transformResponse: (
          response: StoreSchedulesUpdateRequiredAPIResponse
        ) => {
          return response.devices;
        },
      }),
    };
  },
});

export default ScheduleAPI;
