import React, { useContext } from 'react';
import { useAppSelector } from '../../../../../redux/store.model';
import { SearchList } from '../SearchList/SearchList';
import { getAllBusinessUnits } from '../../../../../redux/structure/selectors/BusinessUnit.selector';
import { isLoadingStructure } from '../../../../../redux/structure/selectors/Structure.selector';
import { SearchContext } from '../SearchBar';

export const BUSearch = () => {
  const { search } = useContext(SearchContext);

  const searchQuery = search.toLowerCase();

  const businessUnits = useAppSelector(getAllBusinessUnits);
  const isLoading = useAppSelector(isLoadingStructure);

  const filteredBusinessUnits =
    businessUnits
      ?.filter((businessUnit) =>
        businessUnit.name.toLowerCase().includes(searchQuery)
      )
      .map((item) => {
        return { ...item, key: item.id ?? '', value: item.name ?? '' };
      }) ?? [];

  return (
    <SearchList
      header="search.business_unit"
      list={filteredBusinessUnits}
      loading={isLoading}
    />
  );
};
