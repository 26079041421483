import { AxiosInstance } from 'axios';
import AxiosFlexeServeInstance from '../../shared/AxiosFlexeServe';
import { DeviceStatusAPIResponse } from '../device.model';
import _ from 'lodash';

export class CommanderAPIService {
  public constructor(private axios: AxiosInstance = AxiosFlexeServeInstance) {}

  getDevicesStatus = (
    commandersId: string[]
  ): Promise<DeviceStatusAPIResponse[]> => {
    if (commandersId.length > 50) {
      // split it and call the api multiple times recursively
      // because the api can only handle 50 ids at a time (url long limitation)
      return Promise.all(
        _.chunk(commandersId, 50).map((chunk) => {
          return this.getDevicesStatus(chunk);
        })
      ).then((responses) => {
        return _.flatten(responses);
      });
    }

    return this.axios({
      url: `devicestate/devicestates?ids=${JSON.stringify(commandersId)}`,
      method: 'get',
    }).then((response) => response.data) as Promise<DeviceStatusAPIResponse[]>;
  };
}

export default new CommanderAPIService();
