import { Grid, Typography } from '@mui/material';
import React from 'react';

export interface TextValueProps {
  value: React.ReactNode;
  label: React.ReactNode;
}
function TextValue(props: TextValueProps) {
  const { value, label } = props;
  return (
    <>
      <Grid item sm={12} md={3}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item sm={12} md={9}>
        <Typography variant="body2" color="textSecondary">
          {value}
        </Typography>
      </Grid>
    </>
  );
}

export default TextValue;
