import { createSelector } from '@reduxjs/toolkit';
import { getBusinessUnitById } from './BusinessUnit.selector';
import { getStructure } from './Structure.selector';
import { NormalizedGroup } from '../GroupsAdapter';
import { getGroupsChildIdsFromGroupSelector } from './Group.selector';

export const getAllStores = createSelector([getStructure], (structure) => {
  return structure.data?.stores.map(
    (store) => structure.data?.entities.groups[store] as NormalizedGroup
  );
});

export const getAllStoreChildIdsFromGroupSelector = createSelector(
  [getStructure, getGroupsChildIdsFromGroupSelector],
  (structure, groupChildIds) => {
    return groupChildIds?.filter((encodedId) =>
      structure.data?.stores.includes(encodedId)
    );
  }
);

export const getStoresFromBusinessUnit = createSelector(
  [getStructure, getBusinessUnitById],
  (structure, businessUnit) => {
    return {
      ...businessUnit,
      childGroups:
        businessUnit?.childGroups?.map(
          (id) => structure.data?.entities.groups[id]
        ) ?? [],
    };
  }
);
