/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar, OptionsObject } from 'notistack';
import { useAppSelector } from '../../redux/store.model';
import { getNetworkIsOnline } from '../../redux/network/selectors';
import useParseErrorMessage from './useParseErrorMessage';
import { ErrorContext } from '../context/ErrorContext';

/**
 * function to get error message from error object or string and sent to `enqueueSnackbar`
 * extracted from hook with `variant` and `error` and other options
 *
 * @returns `errorMessage` function
 * @usage
 * ```ts
 * const errorMessage = useErrorMessage()
 * errorMessage(axiosError)
 * errorMessage('Ups, something went wrong')
 * ```
 */
function useErrorMessage() {
  const { enqueueSnackbar } = useSnackbar();
  const parseErrorMessage = useParseErrorMessage();
  const online = useAppSelector(getNetworkIsOnline);
  const { errors, addError } = useContext(ErrorContext);

  return useCallback(
    (error: AxiosError | string | unknown, options: OptionsObject = {}) => {
      const { variant = 'error', ...restOptions } = options;
      const message = parseErrorMessage(error);
      console.error(error);
      if (!online && message === 'Network Error') return;
      if (checkIfMessageWasAlreadyShown(errors, message)) return;
      addError(message);
      enqueueSnackbar(message, {
        variant,
        ...restOptions,
      });
    },
    [enqueueSnackbar, parseErrorMessage, online, errors, addError]
  );
}

const checkIfMessageWasAlreadyShown = (
  errors: React.MutableRefObject<{ message: string; timestamp: number }[]>,
  message: string
) => {
  const now = Date.now();
  return errors.current.some(
    (entry) => entry.message === message && now - entry.timestamp < 5000
  );
};

export default useErrorMessage;
