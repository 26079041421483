import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextValue from '../TextValue';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../theme/ui/Atoms/Button';
import useBack from '../../../../shared/util/route-dom/hooks/useBack';
import CommanderUpdateSteeper from './CommanderUpdateSteeper';
import ContainerBox from '../ContainerBox';
import BackButton from '../BackButton';
import CommanderUpdateAPI from '../../../../service/device/CommanderUpdate/CommanderUpdateAPI';
import _ from 'lodash';
import {
  CommanderTriggerUpdateStartOptionType,
  CommanderUpdateLockState,
  CommanderUpdateStateType,
} from '../../../../service/device/CommanderUpdate/CommanderUpdate.model';
import useWatchError from '../../../../shared/hooks/useWatchError';
import moment from 'moment';
import { CommanderAPI } from '../../../../service/device/CommanderAPI/CommanderAPI';

const POOLING_UPDATE_STEP_INTERVAL = 5000;

function CommanderUpdate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickBack = useBack('/');

  const { commanderId = '', serialNumber = '' } = useParams<{
    commanderId: string;
    serialNumber: string;
    storeId: string;
  }>();
  const {
    data: updatesAvailable,
    isFetching: loadingUpdatesAvailable,
    error: errorUpdatesAvailable,
  } = CommanderUpdateAPI.useGetUpdatesAvailableQuery(commanderId, {
    refetchOnMountOrArgChange: true,
  });

  // FIXME: change to biggest version
  const lastUpdate = _.last(updatesAvailable);

  const [
    triggerUpdate,
    {
      isLoading: isTriggeringUpdate,
      error: errorTriggeringUpdate,
      isSuccess: successTriggeringUpdate,
      startedTimeStamp: startedTimeStampTriggeringUpdate,
    },
  ] = CommanderUpdateAPI.useTriggerUpdateMutation();
  const {
    data: lastStateInstallation,
    isFetching: loadingLastStateInstallation,
    error: errorLastStateInstallation,
  } = CommanderUpdateAPI.useGetCommanderLastStateInstallationQuery(
    commanderId,
    {
      refetchOnMountOrArgChange: true,
      skip: !successTriggeringUpdate,
      pollingInterval: POOLING_UPDATE_STEP_INTERVAL,
    }
  );

  const {
    data: stateInstallation,
    error: errorStateInstallation,
    isFetching: isFetchingStateInstallation,
  } = CommanderUpdateAPI.useGetStateUpdateInstallationQuery(commanderId, {
    refetchOnMountOrArgChange: true,
    pollingInterval: POOLING_UPDATE_STEP_INTERVAL,
  });

  const {
    data: deviceData,
    isFetching: loadingDeviceData,
    error: errorDeviceData,
  } = CommanderAPI.useGetDeviceBySerialNumberQuery(serialNumber, {
    refetchOnMountOrArgChange: true,
  });

  const onClickStartUpdate = () => {
    triggerUpdate({
      commanderId,
      data: {
        startOption: CommanderTriggerUpdateStartOptionType.CLOUD_TRIGGERED,
        updateId: lastUpdate?.uuid ?? '',
      },
    });
  };

  const error =
    errorUpdatesAvailable ||
    errorLastStateInstallation ||
    errorStateInstallation ||
    errorDeviceData ||
    errorTriggeringUpdate;

  useWatchError(error);

  const isFinished = Boolean(
    successTriggeringUpdate &&
      stateInstallation?.step ===
        CommanderUpdateStateType.INSTALLATION_FINISHED &&
      !isFetchingStateInstallation &&
      stateInstallation.completionTime &&
      startedTimeStampTriggeringUpdate &&
      moment(stateInstallation.completionTime).isAfter(
        moment(startedTimeStampTriggeringUpdate)
      )
  );

  // navigate when installation is finished and update was requested
  useEffect(() => {
    if (isFinished) {
      navigate('../commander-update-success');
    }
  }, [navigate, isFinished]);

  return (
    <ContainerBox>
      <Typography variant="h1" textAlign={'center'}>
        {t('initial_setup.update_commander.title')}{' '}
      </Typography>

      <Box minHeight={320} padding={4}>
        {successTriggeringUpdate ? (
          <>
            <CommanderUpdateSteeper
              stateInstallation={stateInstallation}
              lastStateInstallation={lastStateInstallation}
              isFetching={isFetchingStateInstallation}
            />
            <Divider sx={{ my: 2 }} />
          </>
        ) : null}

        <Grid container spacing={2}>
          <TextValue
            label={t('initial_setup.update_commander.current_version')}
            value={
              loadingDeviceData ? (
                <Skeleton variant="text" width={60} />
              ) : (
                deviceData?.version
              )
            }
          />
          <TextValue
            label={t('initial_setup.update_commander.version_to_update')}
            value={
              loadingUpdatesAvailable ? (
                <Skeleton variant="text" width={60} />
              ) : (
                lastUpdate?.updateVersion
              )
            }
          />

          <TextValue
            label={t('initial_setup.update_commander.device_ready')}
            value={
              loadingLastStateInstallation ? (
                <Skeleton variant="text" width={60} />
              ) : (
                t(
                  `initial_setup.update_commander.device_ready_state_${
                    stateInstallation &&
                    stateInstallation?.step ===
                      CommanderUpdateStateType.INSTALLATION_FINISHED
                      ? CommanderUpdateLockState.UNLOCKED
                      : CommanderUpdateLockState.LOCKED
                  }`
                )
              )
            }
          />
        </Grid>
        {!successTriggeringUpdate && (
          <Box textAlign={'center'} mt={4}>
            <Divider sx={{ my: 4 }} />

            <Button
              variant="contained"
              color="primary"
              size="large"
              rounded
              sx={{ width: 500 }}
              loading={isTriggeringUpdate}
              onClick={onClickStartUpdate}
            >
              {t('initial_setup.update_commander.button_action')}
            </Button>
          </Box>
        )}
      </Box>

      <Grid container spacing={2} justifyContent={'space-between'}>
        <Grid item xs={12} sm={2}>
          <BackButton onClick={onClickBack} />
        </Grid>
      </Grid>
    </ContainerBox>
  );
}

export default CommanderUpdate;
