import { createSelector } from '@reduxjs/toolkit';
import LocationAPI from '../LocationAPI';
import { getRegionSelector } from './getRegionSelector';
import { filterNotEmpty } from '../../../shared/ArrayUtil';

export const getDevicesByRegionSelector = createSelector(
  [
    (state) => LocationAPI.endpoints.getLocations.select()(state),
    getRegionSelector,
  ],
  (locationsData, region) =>
    locationsData.data?.devices.filter(filterNotEmpty) ?? []
);

export const getAllDevicesSelector = createSelector(
  [(state) => LocationAPI.endpoints.getLocations.select()(state)],
  (locationsData) => locationsData.data?.devices ?? []
);
