import { createSelector } from '@reduxjs/toolkit';
import LocationAPI from '../LocationAPI';

export const getRegionSelector = createSelector(
  [
    LocationAPI.endpoints.getLocations.select(),
    (state, props: { groupId?: string }) => props.groupId,
  ],
  (locations, groupId) =>
    locations.data?.regions.find((region) => region.groupId === groupId)
);

export const getAllRegionsSelector = createSelector(
  [LocationAPI.endpoints.getLocations.select()],
  (locations) => {
    return { regions: locations.data?.regions, isLoading: locations.isLoading };
  }
);
