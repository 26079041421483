import React from 'react';
import { Navigate, RouteProps, Outlet } from 'react-router-dom';
import AuthenticatedPages from './AuthenticatedPages';

type GuardedRouteProps = RouteProps & {
  /** if is allow user would enter to the route normally, otherSide will redirected to `redirectPath` */
  isAllowed: boolean;
  /**
   * path to been redirected if the user is not allowed,
   * @default '/login'
   */
  redirectPath?: string;
  children?: JSX.Element;
};

const GuardedRoute = ({
  isAllowed,
  redirectPath = '/',
  children,
}: GuardedRouteProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <AuthenticatedPages>{children ? children : <Outlet />}</AuthenticatedPages>
  );
};
export default GuardedRoute;
