import React, { useMemo } from 'react';
import Table from '../../../../theme/ui/Atoms/Table/Table';
import Button from '../../../../theme/ui/Atoms/Button';
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { AirConnectIcon, ReinstallIcon } from '../../../../assets';
import { useTranslation } from 'react-i18next';
import { getIn, useFormik } from 'formik';
import * as yup from 'yup';
import useBack from '../../../../shared/util/route-dom/hooks/useBack';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../../../theme/ui/Atoms/Input';
import InputSelect from '../../../../theme/ui/Atoms/InputSelect';
import BackButton from '../BackButton';
import CommanderAirConnectAPI from '../../../../service/device/CommanderAirConnectAPI/CommanderAirConnectAPI';
import {
  AirConnectNode,
  CommanderAirConnectCreateComposableDeviceItemAPI,
  ComposableDeviceControlUnitMap,
} from '../../../../service/device/CommanderAirConnectAPI/CommanderAirConnectAPI.model';
import useParseErrorMessage from '../../../../shared/hooks/useParseErrorMessage';
import useErrorMessage from '../../../../shared/hooks/useErrorMessage';
import FlexeserveDeviceAPI, {
  M_SECONDS_POOLING_SETUP,
} from '../../../../service/device/FlexeserveDevice/FlexeserveDeviceAPI';
import { DevicesStoreInfoResponse } from '../../../../service/store/StoreService.model';
import ZoneManagerConnected from '../ZoneManagerConnected';
import useMyWorkspace from '../../../../redux/workspace/hooks/useMyWorkspace';
import useMachineTypes from '../../../../redux/workspace/hooks/useMachineTypes';
import cleanObject from '../../../../shared/util/lodash/object/cleanObject';
import { isNotNullOrUndefined } from '../../../../shared/util/collection/isNotNullOrUndefined';
import {
  AIR_CONNECT_NAME_OPTIONS,
  AIR_CONNECT_ZONE_DESCRIPTION_OPTIONS,
} from '../../../../service/device/CommanderAirConnectAPI/CommanderAirConnectAPI.constants';

// one array of at least one string
const validationSchema = yup.array().of(
  yup.object({
    mac: yup.string().required(),
    name: yup
      .string()
      .required('initial_setup.device_name_required')
      .nullable(),
    composableDevice: yup.object({
      machineTypeId: yup.string().required(),
    }),
  })
);

const getDefaultMachineType = (machineTypes?: any[], nodeType?: string) => {
  return machineTypes?.find(
    (machineType) => machineType.labels.nodeType === nodeType
  );
};
const parseNodesToInitValues = (
  nodes?: AirConnectNode[],
  flexeserveDevice?: DevicesStoreInfoResponse,
  machineTypes?: any[]
): CommanderAirConnectCreateComposableDeviceItemAPI[] | undefined => {
  if (!nodes || !flexeserveDevice) return undefined;
  return nodes.map((node) => {
    const defaultMachineType = getDefaultMachineType(
      machineTypes,
      node.nodeType.toString()
    );
    const addressRelated = Array.from(
      { length: node.numberOfControllers },
      (_, i) => (node.nodeAddress + i).toString()
    );
    const controlUnitsRelated =
      flexeserveDevice?.shelfMappings
        ?.filter(isNotNullOrUndefined)
        .filter((shelfMapping) =>
          addressRelated.includes(shelfMapping.cuaddr)
        ) || [];
    const controlUnits: ComposableDeviceControlUnitMap[] =
      controlUnitsRelated.map((controlUnit) => ({
        deviceId: flexeserveDevice?.commanderId,
        controlUnitId: controlUnit.cuid.toString(),
      }));
    const zoneMapping: Record<string, string> = controlUnitsRelated.reduce(
      (acc, controlUnit, currentIndex) => {
        return {
          ...acc,
          [(currentIndex + 1).toString()]: controlUnit.cuaddr,
        };
      },
      {}
    );
    return {
      mac: node.macAddress,
      name: null,
      serialnumber: null,
      model: 'AIR_CONNECT',
      composableDevice: {
        machineTypeId: defaultMachineType?.id,
        controlUnits,
        labels: {
          'node.bitmask': node.bitmask.toString(),
          'node.nodeNumber': node.nodeNumber.toString(),
          'node.nodeAddress': node.nodeAddress.toString(),
          'node.numberOfControllersInstalled':
            node.numberOfControllersInstalled.toString(),
          'node.numberOfControllers': node.numberOfControllers.toString(),
          'node.nodeType': node.nodeType.toString(),
          'node.nodeReachable': node.nodeReachable.toString(),
        },
      },
      zoneMapping,
    };
  });
};

function DevicesSetUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const parseError = useParseErrorMessage();
  const showErrorMessage = useErrorMessage();

  const {
    commanderId = '',
    serialNumber = '',
    storeId = '',
  } = useParams<{
    commanderId: string;
    serialNumber: string;
    storeId: string;
  }>();

  const {
    isFetching: isFetchingNodesInstalled,
    data: nodesInstalled,
    error: errorNodesInstalled,
  } = CommanderAirConnectAPI.useGetNodesInstalledQuery(commanderId, {
    refetchOnMountOrArgChange: true,
    skip: !commanderId,
  });

  const [
    registerComposableDevice,
    {
      isLoading: isRegisteringComposableDevice,
      error: errorRegisterComposableDevice,
    },
  ] = CommanderAirConnectAPI.useRegisterNewComposableDeviceMutation();

  const {
    data: myWorkspace,
    error: errorMyWorkspace,
    isFetching: isFetchingMyWorkspace,
  } = useMyWorkspace();

  const {
    data: flexeserveDevice,
    error: errorFlexeserveDevice,
    isFetching: isFetchingFlexeserveDevice,
  } = FlexeserveDeviceAPI.useGetDeviceByFlexeserveDeviceIdQuery(commanderId, {
    refetchOnMountOrArgChange: true,
    skip: !commanderId,
    pollingInterval: M_SECONDS_POOLING_SETUP,
  });

  const {
    data: machineTypes,
    isLoading: isLoadingMachineTypes,
    error: errorMachineTypes,
  } = useMachineTypes(undefined);

  const [
    reinstallNode,
    { isLoading: isReinstallingNode, error: errorReinstallingNode },
  ] = CommanderAirConnectAPI.useReinstallNodeMutation();

  const currentInitialValues = useMemo(
    () =>
      parseNodesToInitValues(nodesInstalled, flexeserveDevice, machineTypes) ??
      [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodesInstalled, flexeserveDevice, machineTypes, isLoadingMachineTypes]
  );

  const formik = useFormik({
    initialValues: currentInitialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      return registerComposableDevice({
        workspaceId: myWorkspace?.id ?? '',
        storeId,
        data: {
          commanderSerialNum: serialNumber,
          devices: cleanObject(values),
          storeId,
        },
      })
        .unwrap()
        .then(() => navigate('../devices-overview-manager'))
        .catch(showErrorMessage);
    },
  });

  const onClickBack = useBack('/');
  const loading =
    isFetchingNodesInstalled ||
    isRegisteringComposableDevice ||
    isFetchingFlexeserveDevice ||
    isFetchingMyWorkspace ||
    isLoadingMachineTypes ||
    isReinstallingNode;
  const error =
    errorNodesInstalled ||
    errorRegisterComposableDevice ||
    errorFlexeserveDevice ||
    errorMyWorkspace ||
    errorMachineTypes ||
    errorReinstallingNode;
  return (
    <Container maxWidth={false} sx={{ width: '85vw', margin: 'auto' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fill: (theme) => theme.palette.text.primary,
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          {loading ? <CircularProgress /> : <AirConnectIcon height={'2em'} />}
          <Typography
            variant="subtitle2"
            marginY={2}
            sx={{
              display: 'flex',
              marginLeft: 3,
              alignItems: 'center',
            }}
          >
            {t('initial_setup.scan_air_connect.node_found', {
              count: nodesInstalled?.length || 0,
            })}
            : {nodesInstalled?.length || 0}
          </Typography>
        </Stack>
      </Box>

      <Container
        maxWidth={false}
        sx={{ mt: 4, overflow: 'auto', width: '100%' }}
      >
        {!!error && (
          <Alert severity="error" sx={{ my: 2 }}>
            {parseError(error)}
          </Alert>
        )}
        <Table
          sx={{
            '& th.header': {
              minWidth: 300,
              textAlign: 'left',
            },
          }}
        >
          <caption></caption>

          <thead style={{ border: 'none' }}>
            <tr>
              <Typography
                component={'th'}
                className="header"
                color="primary"
                variant="subtitle2"
              >
                {t('initial_setup.scan_air_connect.mac_address')}
              </Typography>
              <Typography
                component={'th'}
                className="header"
                color="primary"
                variant="subtitle2"
              >
                {t('initial_setup.scan_air_connect.device_name')}
              </Typography>

              <Typography
                component={'th'}
                className="header"
                color="primary"
                variant="subtitle2"
              >
                {t('initial_setup.scan_air_connect.device_type')}
              </Typography>
              <Typography
                component={'th'}
                className="header"
                color="primary"
                variant="subtitle2"
              >
                {t('initial_setup.scan_air_connect.device_description')}
              </Typography>
              <Typography component={'th'} color="primary" variant="subtitle2">
                {t('initial_setup.scan_air_connect.device_action_test')}
              </Typography>
              <Typography
                component={'th'}
                color="primary"
                variant="subtitle2"
                sx={{
                  width: '130px',
                }}
              >
                {t('initial_setup.connect_commander.reinstall')}
              </Typography>
            </tr>
          </thead>
          <tbody>
            {formik.values?.map((device, i) => {
              return (
                <Paper component={'tr'} key={device.mac} elevation={undefined}>
                  <td>
                    <Typography variant="body1">{device.mac}</Typography>
                  </td>
                  <td colSpan={2} style={{ padding: '1em 0px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputSelect
                          select
                          id={`[${i}].name`}
                          name={`[${i}].name`}
                          options={AIR_CONNECT_NAME_OPTIONS?.map((name) => ({
                            value: name,
                            text: name,
                          }))}
                          placeholder={t(
                            'initial_setup.scan_air_connect.device_name'
                          )}
                          fullWidth
                          required
                          aria-required
                          value={formik.values[i].name}
                          onChange={(e: any, v: string) =>
                            formik.setFieldValue(`${i}.name`, v)
                          }
                          onBlur={formik.handleBlur}
                          error={
                            formik.errors[i]?.name &&
                            Boolean(formik.errors[i]?.name)
                          }
                          helperText={t(formik.errors[i]?.name ?? '')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputSelect
                          select
                          id={`[${i}].device_type`}
                          name={`[${i}].device_type`}
                          options={machineTypes?.map((machineType) => ({
                            value: machineType.id,
                            text: machineType.name,
                          }))}
                          disable={isLoadingMachineTypes}
                          placeholder={t(
                            'initial_setup.scan_air_connect.device_type'
                          )}
                          fullWidth
                          required
                          aria-required
                          value={
                            formik.values[i].composableDevice.machineTypeId
                          }
                          onChange={(e: any, v: string) =>
                            formik.setFieldValue(
                              `${i}.composableDevice.machineTypeId`,
                              v
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} textAlign={'left'}>
                        <Input
                          label={t(
                            'initial_setup.scan_air_connect.serial_number'
                          )}
                          placeholder={t(
                            'initial_setup.scan_air_connect.serial_number'
                          )}
                          fullWidth
                          id={`[${i}].serialnumber`}
                          name={`[${i}].serialnumber`}
                          value={formik.values[i].serialnumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Grid>
                    </Grid>
                  </td>
                  <td>
                    <Stack
                      textAlign={'left'}
                      py={1}
                      spacing={2}
                      justifyContent={'flex-start'}
                      height={'100%'}
                    >
                      {device.composableDevice.controlUnits.map(
                        (controlUnit, j) => (
                          <InputSelect
                            key={controlUnit.controlUnitId}
                            select
                            id={`[${i}].composableDevice.labels.zone_${j}_description`}
                            name={`[${i}].composableDevice.labels.zone_${j}_description`}
                            options={AIR_CONNECT_ZONE_DESCRIPTION_OPTIONS?.map(
                              (description) => ({
                                value: description,
                                text: description,
                              })
                            )}
                            placeholder={t(
                              'initial_setup.scan_air_connect.placeholder_zone_description'
                            )}
                            value={getIn(
                              formik.values,
                              `[${i}].composableDevice.labels.zone_${j}_description`
                            )}
                            //   onChange={formik.handleChange}
                            onChange={(e: any, v: string) =>
                              formik.setFieldValue(
                                `${i}.composableDevice.labels.zone_${j}_description`,
                                v
                              )
                            }
                            onBlur={formik.handleBlur}
                          />
                        )
                      )}
                    </Stack>
                  </td>
                  <td>
                    <Stack
                      textAlign={'left'}
                      justifyContent={'flex-start'}
                      height={'100%'}
                      py={1}
                      spacing={1}
                    >
                      {device.composableDevice.controlUnits.map(
                        (controlUnit, j) => {
                          const zoneIndex = parseInt(device.zoneMapping[j + 1]);
                          const zone =
                            flexeserveDevice?.shelfSettings[zoneIndex];
                          if (!zone) return null;
                          return (
                            <ZoneManagerConnected
                              size="small"
                              key={`${controlUnit.controlUnitId}`}
                              item={{
                                device: {
                                  ...flexeserveDevice,
                                  composableDevice: device.composableDevice,
                                } as any,
                                zone,
                              }}
                              zoneIndex={zoneIndex}
                              loadingState={isFetchingFlexeserveDevice}
                            />
                          );
                        }
                      )}
                    </Stack>
                  </td>
                  <td>
                    {
                      <Button
                        sx={{
                          padding: 3,
                          borderRadius: '1em',
                          height: '85px',
                          width: '85px',
                        }}
                        variant="contained"
                        onClick={() => {
                          reinstallNode({
                            commanderId,
                            nodeNumber: parseInt(
                              device.composableDevice.labels['node.nodeNumber']
                            ),
                            nodeType:
                              device.composableDevice.labels['node.nodeType'],
                          });
                        }}
                        loading={isReinstallingNode}
                      >
                        <ReinstallIcon height={'3em'} />
                      </Button>
                    }
                  </td>
                </Paper>
              );
            })}
          </tbody>
        </Table>
      </Container>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={2} justifyContent={'space-between'} mt={2}>
          <Grid item xs={12} sm={2}>
            <BackButton onClick={onClickBack} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              rounded
              fullWidth
              loading={formik.isSubmitting}
              disabled={!formik.isValid}
              onClick={() => formik.handleSubmit()}
            >
              {t('button.next')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default DevicesSetUp;
