/*
 {
        "uuid": "0b069ebf-e4fa-4cb7-a547-ffb9d0c1c5a5",
        "articlenum": "900333.014",
        "filepath": "0b069ebf-e4fa-4cb7-a547-ffb9d0c1c5a5/900333.014_Flexeserve_AirConnect_CBoxImage_Flexeserve_AirConnect_update-2.22.710+snapshot.pkg",
        "uploaded": 1738571598404,
        "updateName": "Dev test 01",
        "updateVersion": "2.22.710",
        "updateDescription": "Dev test Snapshot",
        "internal": true
    }
*/
export interface CommanderUpdate {
  uuid: string;
  articlenum: string;
  filepath: string;
  uploaded: number;
  updateName: string;
  updateVersion: string;
  updateDescription: string;
  internal: boolean;
}

export enum CommanderTriggerUpdateStartOptionType {
  CLOUD_TRIGGERED = 'CLOUD_TRIGGERED',
  MANUAL = 'MANUAL',
  AUTOSTART = 'AUTOSTART',
}
/**
 * {
    "updateId":"0b069ebf-e4fa-4cb7-a547-ffb9d0c1c5a5",
    "startOption":"CLOUD_TRIGGERED"
}
 */
export interface CommanderTriggerUpdate {
  updateId: string;
  /** prefer`CLOUD_TRIGGERED`*/
  startOption: CommanderTriggerUpdateStartOptionType;
}

/**
 * {
    "uuid": "1a5295d0-8dff-4738-a81f-93a477721aee",
    "articlenum": "900333.014",
    "uploaded": 0,
    "updateName": "Dev test 03",
    "updateVersion": "2.22.720",
    "updateDescription": "Dev test Snapshot",
    "internal": false,
    "progressPercentage": 0,
    "triggerTime": 1739485952905,
    "completionTime": 1739486085095,
    "step": "INSTALLATION_FINISHED",
    "startOption": "CLOUD_TRIGGERED"
}
 */

export interface CommanderUpdateStateResponseAPI {
  uuid: string;
  articlenum: string;
  uploaded: number;
  updateName: string;
  updateVersion: string;
  updateDescription: string;
  internal: boolean;
  progressPercentage: number;
  triggerTime: number;
  completionTime?: number;
  step?: CommanderUpdateStateType;
  startOption: CommanderTriggerUpdateStartOptionType;
}

export enum CommanderUpdateStateType {
  CUSTOM = 'CUSTOM',
  DOWNLOAD_STARTED = 'DOWNLOAD_STARTED',
  DOWNLOAD_FINISHED = 'DOWNLOAD_FINISHED',
  VERIFICATION_STARTED = 'VERIFICATION_STARTED',
  READY_TO_UPDATE = 'READY_TO_UPDATE',
  INSTALLATION_STARTED = 'INSTALLATION_STARTED',
  INSTALLATION_FINISHED = 'INSTALLATION_FINISHED',
}

/**
 * {
    "step": "INSTALLATION_STARTED",
    "lockState": "UNLOCKED",
    "state": "INSTALLING"
}
 */

export const enum CommanderUpdateLockState {
  UNLOCKED = 'UNLOCKED',
  LOCKED = 'LOCKED',
}
export interface CommanderUpdateLastStateResponseAPI {
  step: CommanderUpdateStateType;
  lockState: CommanderUpdateLockState;
  state: string;
}
