import StoreAPI from '../StoreAPI';
import useWatchError from '../../../shared/hooks/useWatchError';
import { M_SECONDS_POOLING_OPERATOR_INTERVAL_DATA } from '../Store.constants';
import { UseQuerySubscription } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useGetGroupByIdQuery } from '../../group/GroupAPI';
import { toBase64 } from '../../../shared/util/base64';
import { DevicesStoreInfoResponse } from '../StoreService.model';
import { ComposableDevice } from '../../group/group.model';

type Options = Parameters<UseQuerySubscription<any>>[1];
export type ComposableDevicesStoreInfoResponse = {
  composableDevice?: ComposableDevice;
} & DevicesStoreInfoResponse;
function useDevicesOperator(options: Options = {}) {
  const responseAPI = StoreAPI.useGetStoreInfoDetailsQuery(undefined, {
    pollingInterval: M_SECONDS_POOLING_OPERATOR_INTERVAL_DATA,
    ...options,
  });
  const { data: storeInfoDetails, ...rest } = responseAPI;

  const { data: storeGroup } = useGetGroupByIdQuery(
    storeInfoDetails?.uuid ?? ''
  );
  const groupChildren = storeGroup?.childGroups ?? [];

  const devices: ComposableDevicesStoreInfoResponse[] =
    storeInfoDetails?.devices
      .filter((device) => device.visible)
      .map((device) => {
        const composableDeviceGroup = groupChildren.find(
          (group) => group.id === toBase64(device.composableDeviceId || '')
        );
        return {
          ...device,
          composableDevice: composableDeviceGroup?.composableDevice,
        };
      }) ?? [];
  useWatchError(rest.error);
  return {
    devices,
    ...rest,
  };
}

export default useDevicesOperator;
