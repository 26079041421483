import { isNotNullOrUndefined } from '../../../shared/util/collection/isNotNullOrUndefined';
import { ComposableDevicesStoreInfoResponse } from '../../store/hooks/useDevicesOperator';
import { ZoneResponse } from '../../store/modules/zone/ZoneService.model';
import { ComposableDevicesManagerStoreInfoResponse } from '../hooks/useDevicesManager';

export function getShelfMapping(
  device:
    | ComposableDevicesStoreInfoResponse
    | ComposableDevicesManagerStoreInfoResponse
) {
  /* If the device is not a composable device, 
    display all the settings that contains information */
  if (!device.composableDevice) {
    return device.shelfMappings
      .filter((shelf) => shelf && device.shelfSettings[shelf.id])
      .filter(isNotNullOrUndefined);
  } else {
    /* If its a composable device, 
      display the mappings added in the composable device information */
    return device.shelfMappings
      .filter(isNotNullOrUndefined)
      .filter(
        (shelf) =>
          shelf &&
          Object.values(device?.composableZoneMapping ?? {}).find(
            (zone) => Number(zone) === shelf.id
          )
      );
  }
}

export function getShelfSettings(
  device:
    | ComposableDevicesStoreInfoResponse
    | ComposableDevicesManagerStoreInfoResponse
): Record<string, ZoneResponse> {
  /* If the device is not a composable device, 
    display all the settings that contains information */
  if (!device.composableDeviceId) {
    return device.shelfSettings || {};
  } else {
    /* If its a composable device, 
      display the settings added in the composable device information */
    const settings: Record<string, ZoneResponse> = {};
    if (!device.composableZoneMapping) {
      return device.shelfSettings || {};
    }
    Object.values(device.composableZoneMapping).forEach((zone) => {
      if (device.shelfSettings) {
        settings[zone] = device.shelfSettings[zone];
      }
    });
    return settings;
  }
}
