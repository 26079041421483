import React, { useState, MouseEvent, useContext } from 'react';
import { Popover, Stack } from '@mui/material';
import { SearchBarProps, SearchContext } from '../SearchBar';
import { useTranslation } from 'react-i18next';
import SearchInput from '../SearchInput/SearchInput';

export const SearchBarDesktop = (props: SearchBarProps) => {
  const { children, ...rest } = props;
  const { search, setSearch, open, setOpen, selectedItem, hasMatch } =
    useContext(SearchContext);

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleBlur = () => {
    if (selectedItem && !hasMatch) {
      setSearch(selectedItem.value);
    }
  };

  return (
    <>
      <SearchInput
        color="primary"
        type="text"
        value={search}
        placeholder={t('search.placeholder') || ''}
        onChange={(e: any) => {
          setSearch(e.target.value);
          handleClick(e);
          setOpen(true);
        }}
        onBlur={handleBlur}
        {...rest}
      />
      <Popover
        id="search-popover"
        open={open && search.length > 0}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack
          width={anchorEl?.clientWidth}
          maxHeight={'400px'}
          overflow={'auto'}
        >
          {children}
        </Stack>
      </Popover>
    </>
  );
};
