import { EntityActionsProps } from './EntityActions';
import { Box, Typography } from '@mui/material';
import { CalendarOffIcon, CalendarOnIcon } from '../../../../../../assets';
import { M_SECONDS_POOLING_INTERVAL_DATA } from '../../../../../../service/store/Store.constants';
import FlexeserveDeviceAPI from '../../../../../../service/device/FlexeserveDevice/FlexeserveDeviceAPI';

export function EntitySchedulesAction(props: EntityActionsProps) {
  const { entity } = props;

  const { data: deviceWithSchedules } =
    FlexeserveDeviceAPI.useGetStoreDevicesQuery(entity?.uuid ?? '', {
      pollingInterval: M_SECONDS_POOLING_INTERVAL_DATA,
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.filter((device) => device.schedule),
      }),
    });
  const on = deviceWithSchedules && deviceWithSchedules?.length > 0;

  return (
    <Box
      sx={{
        fontSize: '1rem',
        width: '6rem',
        height: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        component="span"
        variant={'inherit'}
        color={(theme) =>
          on ? theme.palette.success.main : theme.palette.text.disabled
        }
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {deviceWithSchedules && deviceWithSchedules?.length > 0 ? (
          <CalendarOnIcon height={'1.5em'} />
        ) : (
          <CalendarOffIcon height={'1.5em'} />
        )}
      </Typography>
    </Box>
  );
}
