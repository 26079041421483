import { DeviceControlUnitsAPIResponse } from '../../service/device/device.model';

export const nameReducer = 'controlUnit';
export interface ZipDeviceControlUnit {
  deviceId: string;
  deviceName: string;
  commanderId: string;
  controlUnits: DeviceControlUnitsAPIResponse[] | undefined;
  loading: boolean | undefined;
  error: any | undefined;
}
