import React from 'react';
import { ComposableDevicesManagerStoreInfoResponse } from '../../../../service/device/hooks/useDevicesManager';
import useAlarmsByDevice from '../../../../redux/alarm/hooks/useAlarmsByDevice';
import useWatchError from '../../../../shared/hooks/useWatchError';
import { Skeleton } from '@mui/material';
import { ComposableDevicesStoreInfoResponse } from '../../../../service/store/hooks/useDevicesOperator';

interface AlarmNumberProps {
  device:
    | ComposableDevicesManagerStoreInfoResponse
    | ComposableDevicesStoreInfoResponse;
}
/** get the number of alarms of the entity */
function AlarmNumber(props: AlarmNumberProps) {
  const { device } = props;
  const {
    data: alarms = [],
    isLoading: isLoadingAlarms,
    error: errorAlarms,
  } = useAlarmsByDevice(
    {
      device,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useWatchError(errorAlarms);
  const numberAlarms = alarms.length;

  return (
    <span>
      {isLoadingAlarms ? <Skeleton variant="text" width={50} /> : numberAlarms}
    </span>
  );
}

export default AlarmNumber;
