import { createSelector } from '@reduxjs/toolkit';
import LocationAPI from '../LocationAPI';

export const getDeviceFromParamSelector = createSelector(
  [
    LocationAPI.endpoints.getLocations.select(),
    (state, props: { deviceId?: string }) => props.deviceId,
  ],
  (locations, deviceId) =>
    locations?.data?.devices.find((item) => item.uuid === deviceId)
);
