import { toBase64 } from '../../shared/util/base64';
import LocationAPI from '../locations/LocationAPI';
import { DeviceDataAPIResponse } from './device.model';

export const DeviceAPI = LocationAPI.injectEndpoints({
  endpoints: (build) => ({
    getDeviceByCloudConnectId: build.query<
      DeviceDataAPIResponse | undefined,
      string
    >({
      query: (cloudConnectId) => {
        return {
          url: `/commander/cloudConnectID/${cloudConnectId}`,
          method: 'get',
        };
      },
      providesTags: ['device-actions'],
    }),

    getDeviceBySerialNumber: build.query<
      DeviceDataAPIResponse | undefined,
      string
    >({
      query: (serialNumber) => {
        return {
          url: `/commander/serialnum/${toBase64(serialNumber)}`,
          method: 'get',
        };
      },
      providesTags: ['device-actions'],
    }),
    setTimezoneCommander: build.mutation<
      string,
      { commanderId: string; timezone: string }
    >({
      query: ({ commanderId, timezone }) => {
        const encodedCommanderId = toBase64(commanderId);
        return {
          url: `config/${encodedCommanderId}`,
          method: 'put',
          data: [
            {
              key: 'device-timezone',
              value: timezone,
              type: 'TEXT',
            },
          ],
        };
      },
      invalidatesTags: ['device-actions'],
    }),
  }),
});
