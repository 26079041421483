import { GroupAPIResponse } from '../../service/group/group.model';
import { normalize } from 'normalizr';
import LocationAPI from '../../service/locations/LocationAPI';
import { FlexeserveGroupStructure, groupsListSchema } from './GroupsAdapter';
import { getTypeFromGroup } from './util/getTypeFromGroup';
import { fromBase64 } from '../../shared/util/base64';

const StructureAPI = LocationAPI.injectEndpoints({
  endpoints: (build) => ({
    getStructureV2: build.query<
      FlexeserveGroupStructure & {
        businessUnits: string[];
        regions: string[];
        districts: string[];
        stores: string[];
        commanderIds: string[];
      },
      void
    >({
      query: () => ({ url: 'groups/', method: 'get' }),
      transformResponse(response: GroupAPIResponse[]) {
        const normalizedGroups: FlexeserveGroupStructure = normalize(
          response,
          groupsListSchema
        );

        const businessUnits: string[] = [];
        const regions: string[] = [];
        const districts: string[] = [];
        const stores: string[] = [];
        const commanderIds: string[] = [];

        Object.values(normalizedGroups.entities.groups ?? {}).forEach(
          (group) => {
            const type = getTypeFromGroup(group);
            // has attribute with key FS_BusinessUnit_ID
            if (type === 'businessUnit') businessUnits.push(group.id);
            // has attribute with key FS_Region_ID
            if (type === 'region') regions.push(group.id);
            // has attribute with key FS_Market_ID
            if (type === 'district') districts.push(group.id);
            // has attribute with key FS_Store_ID
            if (type === 'store') stores.push(group.id);

            if (group.devices?.length)
              // add all devices to commanderIds
              commanderIds.push(...group.devices.map(fromBase64));
          }
        );
        const extendedResponse = {
          ...normalizedGroups,
          businessUnits,
          regions,
          districts,
          stores,
          commanderIds,
        };
        return extendedResponse;
      },
      providesTags: ['locations-actions', 'group-actions'],
    }),
  }),
});

export const { useGetStructureV2Query, useLazyGetStructureV2Query } =
  StructureAPI;
export default StructureAPI;
