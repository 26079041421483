import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import WarningIcon from '@mui/icons-material/WarningAmberOutlined';
import Typography from '@mui/material/Typography';
import { ReactComponent as SettingsIcon } from '../../../../assets/settings.svg';
import Button from '../../Atoms/Button';
import moment from 'moment';
import { useTick } from '../../../../shared/hooks/useTick';
import { useTranslation } from 'react-i18next';
import HeaderLogo from './HeaderLogo';

export interface HeaderProps {
  /** Usually is the name of location. e.g. `Birmingham New Street` */
  title: string;
  demo?: boolean;
  onClickHome?: () => void;
  onClickSettings?: () => void;
  offline?: boolean;
  headerLogoSrc?: string;
  headerLogoElement?: React.ReactNode;
}
/**
 * Header component
 * Inside of AppBar component should be placed Toolbar component
 * On the left: Show home icon and the title of the location on the
 * On the right: Show the settings icon and the current time
 * @param props <HeaderProps>
 * @returns <React.FC<HeaderProps>>
 
 */
export const Header = (props: HeaderProps) => {
  const time = useTick(() => moment().format('LT'), 15 * 1000);
  const date = useTick(() => moment().format('L'), 60 * 1000);
  const { t } = useTranslation();

  return (
    <AppBar position="static" color="transparent" sx={{ zIndex: 20 }}>
      <Toolbar>
        <Button
          variant="text"
          aria-label="home"
          onClick={props.onClickHome}
          sx={{ width: 'auto' }}
        >
          <HeaderLogo />
        </Button>
        <Typography variant="h6" sx={{ flexGrow: 1, marginX: 2 }}>
          {props.title}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
            marginX: 2,
            textAlign: 'right',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Typography component={'span'}>{date}</Typography>
          <Typography component={'span'} marginLeft={2}>
            {time}
          </Typography>
        </Typography>

        <Button
          variant="contained"
          aria-label="settings"
          onClick={props.onClickSettings}
          sx={{ height: 44 }}
        >
          <SettingsIcon height={'100%'} />
        </Button>
      </Toolbar>
      {props.offline ? (
        <Toolbar
          variant="dense"
          sx={{ backgroundColor: 'warning.main', minHeight: '16px' }}
        >
          <Typography
            variant="h6"
            className="d-flex-center-center"
            textAlign={'center'}
            flexGrow={1}
            color={'white'}
          >
            <WarningIcon /> {t('error.offline')}
          </Typography>
        </Toolbar>
      ) : null}
    </AppBar>
  );
};

export default Header;
