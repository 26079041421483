import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScheduleAPI from '../../../../../../../service/schedule/ScheduleAPI';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import useWatchError from '../../../../../../../shared/hooks/useWatchError';
import { DayPartAPIResponse } from '../../../../../../../service/schedule/ScheduleAPI.model';
import ZoneSettingsAPI from '../../../../../../../service/zoneSettings/ZoneSettingsAPI';
import getOptionsFromZoneSettingsWithDefaults from '../../../../../../../service/zoneSettings/serialize/getOptionsFromZoneSettingsWithDefaults';
import ZonesSettingsString from '../ZoneSettingsSmall/ZonesSettingsString';
import { EditIcon, TrashIcon } from '../../../../../../../assets';
import useErrorMessage from '../../../../../../../shared/hooks/useErrorMessage';
import Button from '../../../../../../../theme/ui/Atoms/Button/Button';
import getNumberZonesDayPart from '../../../../../../../service/schedule/util/getNumberZonesDayPart';
import { ModalInfo } from '../../../../../../../theme/ui/Atoms/Modal';
import useMessage from '../../../../../../../shared/hooks/useMessage';

const widthTotal =
  900 -
  // padding
  24 * 2;
const widthDisplayName = 350;
const widthNZonses = 80;
const widthEditIcon = 40;
const widthDeleteIcon = 40;
const widthShelfSettings =
  widthTotal -
  widthDisplayName -
  widthNZonses -
  widthEditIcon -
  widthDeleteIcon;

const TableHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      item
      sx={{ marginY: 2, ...theme.typography.h3, fontSize: '1.4rem' }}
    >
      <Grid item width={widthDisplayName} sx={{ pl: 2, margin: 'auto' }}>
        {t('schedule.settings.day_part.displayName_header')}
      </Grid>
      <Grid item width={widthNZonses} sx={{ textAlign: 'center' }}>
        {t('schedule.settings.day_part.numberZones_header')}
      </Grid>
      <Grid item width={widthShelfSettings} sx={{ margin: 'auto' }}>
        {t('schedule.settings.day_part.shelfSettings')}
      </Grid>
      <Grid item width={widthEditIcon}>
        {/* empty */}
      </Grid>
      <Grid item width={widthDeleteIcon}>
        {/* empty */}
      </Grid>
    </Grid>
  );
};

interface DayPartItemProps {
  item: DayPartAPIResponse;
  onEdit: () => void;
  onDelete: () => void;
}

const DayPartItem = (props: DayPartItemProps) => {
  const { item, onEdit, onDelete } = props;
  const { businessUnitId = '' } = useParams<{ businessUnitId: string }>();
  const {
    isFetching,
    isLoading,
    error,
    data: zoneSettings,
  } = ZoneSettingsAPI.useGetZoneSettingsByGroupQuery(businessUnitId, {
    skip: !businessUnitId,
    refetchOnMountOrArgChange: true,
  });

  useWatchError(error);
  const optionsZoneSettings = useMemo(
    () => getOptionsFromZoneSettingsWithDefaults(zoneSettings, true),
    [zoneSettings]
  );
  return (
    <Grid container item sx={{ marginY: 1, minWidth: widthTotal }}>
      <Grid
        container
        item
        component={Paper}
        width={'auto'}
        sx={{ minHeight: 43, paddingY: 1 }}
      >
        <Grid item width={widthDisplayName} sx={{ pl: 2 }}>
          {item.displayName}
        </Grid>
        <Grid item width={widthNZonses} sx={{ textAlign: 'center' }}>
          {getNumberZonesDayPart(item.shelfSettings)}
        </Grid>
        <Grid item width={widthShelfSettings} sx={{ pr: 2 }}>
          <ZonesSettingsString
            shelfSettings={item.shelfSettings}
            optionsZoneSettings={optionsZoneSettings}
            loading={isFetching || isLoading}
          />
        </Grid>
      </Grid>
      <Grid item width={widthEditIcon}>
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item width={widthDeleteIcon}>
        <IconButton onClick={onDelete}>
          <TrashIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

function DayPartListPage() {
  const { businessUnitId = '' } = useParams();
  const navigate = useNavigate();
  const showMessage = useMessage();
  const { t } = useTranslation();
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<DayPartAPIResponse | null>(
    null
  );
  const { error: errorZoneSettings } =
    ZoneSettingsAPI.useGetZoneSettingsByGroupQuery(businessUnitId || '');
  const {
    error,
    isLoading,
    isFetching,
    data: dayParts,
  } = ScheduleAPI.useGetDayPartByGroupQuery(businessUnitId, {
    refetchOnMountOrArgChange: true,
  });
  const [deleteDayPart] = ScheduleAPI.useDeleteDayPartByGroupMutation();
  useWatchError(error ?? errorZoneSettings);
  const errorMessage = useErrorMessage();
  const onDelete = async (item: DayPartAPIResponse) => {
    setDisplayConfirmModal(false);
    setItemToDelete(null);
    await deleteDayPart({ groupId: businessUnitId, dayPartId: item.id })
      .unwrap()
      .then(() => showMessage(t('schedule.settings.day_part.deleted')))
      .catch(errorMessage);
  };
  const onEdit = (item: DayPartAPIResponse) => () => {
    navigate(`./${item.id}`);
  };
  const dayPartNewLink = `/manager/businessUnit/${businessUnitId}/schedule-settings/day-part/new`;

  if (!dayParts) return null;
  return (
    <>
      <Container maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h1" m={0}>
            {t('schedule.settings.day_part.list')}
          </Typography>
          <Button
            variant="contained"
            rounded
            sx={{ m: 2 }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore forward params not working in TS
            to={dayPartNewLink}
            component={RouterLink}
          >
            {t('schedule.settings.day_part.new')}
          </Button>
        </Box>
        <Divider />
        <Grid container item sx={{ width: 'fit-content' }}>
          <TableHeader />

          {isLoading || isFetching ? (
            <CircularProgress />
          ) : (
            dayParts.map((item) => (
              <DayPartItem
                key={item.id}
                item={item}
                onEdit={onEdit(item)}
                onDelete={() => {
                  setItemToDelete(item);
                  setDisplayConfirmModal(true);
                }}
              />
            ))
          )}
        </Grid>
      </Container>
      <ModalInfo
        open={displayConfirmModal}
        description={
          <p>
            {t('schedule.settings.day_part.confirmation_delete', {
              name: itemToDelete?.displayName,
            })}
          </p>
        }
        title={t('schedule.settings.day_part.confirmation_delete_title')}
        buttonActions={
          <>
            <Button
              autoFocus
              variant="text"
              onClick={() => {
                setDisplayConfirmModal(false);
                setItemToDelete(null);
              }}
            >
              {t('button.cancel')}
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={() => {
                onDelete(itemToDelete!);
              }}
            >
              {t('button.confirm')}
            </Button>
          </>
        }
      />
    </>
  );
}

export default DayPartListPage;
