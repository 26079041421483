import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Input from '../../../../../../../theme/ui/Atoms/Input/Input';
import { useTranslation } from 'react-i18next';
import ZoneSettingsSmall from '../ZoneSettingsSmall/ZoneSettingsSmall';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../../../../theme/ui/Atoms/Button/Button';
import { ArrowBackIcon } from '../../../../../../../assets';
import {
  DayPartAPIResponse,
  POSSIBLE_SHELF_ZONE_ID,
  ShelfSettingDayPartResponse,
} from '../../../../../../../service/schedule/ScheduleAPI.model';
import ScheduleAPI from '../../../../../../../service/schedule/ScheduleAPI';
import useErrorMessage from '../../../../../../../shared/hooks/useErrorMessage';
import useWatchError from '../../../../../../../shared/hooks/useWatchError';
import getNumberZonesDayPart from '../../../../../../../service/schedule/util/getNumberZonesDayPart';
import useValidateTemperatureRestriction from '../../../../../../../service/schedule/hook/useValidateZoneSettings';
import { temperatureSetpointLimitsModalErrorPromise } from '../../../../TemperatureSetpointLimits/TemperatureSetpointLimitsModalError';
import { useTemperaturePreference } from '../../../../../../../service/temperaturePreference';
import useMessage from '../../../../../../../shared/hooks/useMessage';

const validationSchema = yup.object({
  displayName: yup
    .string()
    .required('schedule.settings.day_part.displayName_required'),
  numberZones: yup
    .number()
    .required('schedule.settings.day_part.numberZones_required'),
  shelfSettings: yup.array(
    yup.object({
      setting: yup.string().nullable(),
    })
  ),
});

export const optionsSelectZones = [
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
  { value: 5, text: '5' },
];

const clearInitialValues = {
  id: undefined as string | undefined,
  displayName: '',
  confirmable: true,
  numberZones: null as number | null | undefined,
  shelfSettings: [] as ShelfSettingDayPartResponse[],
};

function DayPartNewPage() {
  const { t } = useTranslation();
  const { businessUnitId = '' } = useParams();
  const navigate = useNavigate();
  const [setDayPart] = ScheduleAPI.useSetDayPartByGroupMutation();
  const errorMessage = useErrorMessage();
  const showMessage = useMessage();
  const temperaturePreference = useTemperaturePreference();

  // Edit daypart
  const {
    error,
    isLoading,
    isFetching,
    data: dayParts,
  } = ScheduleAPI.useGetDayPartByGroupQuery(businessUnitId);
  useWatchError(error);
  const { dayPartId = '' } = useParams<{ dayPartId: string }>();
  const dayPart = useMemo(() => {
    const dayPart = dayParts?.find((dp) => dp.id === dayPartId);
    if (dayPart) {
      const numberZones = getNumberZonesDayPart(dayPart.shelfSettings);
      return {
        ...dayPart,
        numberZones,
        shelfSettings: new Array(numberZones).fill(null).map((v, i) => ({
          zoneId: `${i + 1}` as POSSIBLE_SHELF_ZONE_ID,
          setting: dayPart?.shelfSettings.find((v) => v.zoneId === `${i + 1}`)
            ?.setting,
        })),
      };
    }
  }, [dayPartId, dayParts]);

  const validateZoneSettings =
    useValidateTemperatureRestriction(businessUnitId);
  const formik = useFormik({
    initialValues: (dayPart ?? clearInitialValues) as typeof clearInitialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const filterValues = {
        ...values,
        shelfSettings: values.shelfSettings.filter((v) => v.setting),
      };

      return await Promise.resolve()
        .then(() =>
          validateZoneSettings({
            dayParts: [filterValues as DayPartAPIResponse],
          })
        )
        .then((errors) =>
          errors.length
            ? temperatureSetpointLimitsModalErrorPromise({
                errors,
                temperaturePreference,
                description: t(
                  'schedule.temperatureSetpointLimits.daypartModal.description'
                ),
                followings: t(
                  'schedule.temperatureSetpointLimits.daypartModal.following'
                ),
              })
            : null
        )
        .then(() =>
          setDayPart({
            dayPart: filterValues,
            groupId: businessUnitId ?? '',
          }).unwrap()
        )
        .then(() => {
          onClickBack();
          showMessage(t('schedule.settings.day_part.save_success'));
        })
        .catch(errorMessage);
    },
  });

  const onClickBack = () => {
    const dayPartListLink = `/manager/businessUnit/${businessUnitId}/schedule-settings/day-part`;
    navigate(dayPartListLink);
  };

  const handleChangeNumberZones = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: string
  ) => {
    const numberZones = Number(data);
    const arrayEmpty = new Array(numberZones).fill(null).map((v, i) => ({
      zoneId: `${i + 1}` as POSSIBLE_SHELF_ZONE_ID,
      setting: v,
    }));
    formik.setValues({
      ...formik.values,
      numberZones,
      shelfSettings: arrayEmpty,
    });
  };
  if (isLoading || isFetching)
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" mt={1} fontWeight={700}>
        {t('schedule.settings.day_part.new_daypart')}
      </Typography>
      <Divider sx={{ mb: 2, mt: 2 }} />

      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Input
            id={`displayName`}
            name={`displayName`}
            label={t(`schedule.settings.day_part.displayName`)}
            placeholder={t(
              `schedule.settings.day_part.displayName_placeholder`
            )}
            value={formik.values?.displayName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.displayName &&
                formik.errors.displayName &&
                t(formik.errors.displayName)
            )}
            helperText={
              formik.touched.displayName &&
              formik.errors.displayName &&
              t(formik.errors.displayName)
            }
            required
            aria-required
            fullWidth
            sx={{ label: { fontSize: 16, mb: 2, display: 'inline-block' } }}
          />

          {/* <FormControl component="fieldset">
            <FormLabel component="legend">
              {t('schedule.settings.day_part.confirmable')}
            </FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values?.confirmable}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  slotProps={{
                    input: { id: 'confirmable', name: 'confirmable' },
                  }}
                  helperText={
                    (formik.touched.confirmable && formik.errors.confirmable
                      ? t(formik.errors.confirmable)
                      : t(
                          'schedule.settings.day_part.confirmable_help_text'
                        )) ?? ''
                  }
                />
              }
              label=""
            />
          </FormControl> */}

          <Box sx={{ '& .MuiFormControl-root': { width: '100%' } }}>
            <FormControl>
              <FormLabel
                id={`numberZones`}
                sx={{ fontSize: 16, mb: 2, display: 'inline-block' }}
              >
                {t('schedule.settings.day_part.numberZones')}*
              </FormLabel>
              <RadioGroup
                aria-labelledby="numberZones"
                name={`numberZones`}
                onChange={handleChangeNumberZones}
                value={formik.values?.numberZones}
                aria-required
                onBlur={formik.handleBlur}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                {optionsSelectZones.map((option) => (
                  <FormControlLabel
                    value={option.value}
                    sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    control={<Radio sx={{ pt: 0.5 }} />}
                    label={option.text}
                    key={option.value}
                  />
                ))}
              </RadioGroup>
              <FormHelperText
                error={Boolean(
                  formik.touched.numberZones && formik.errors.numberZones
                )}
                sx={{ ml: 0 }}
              >
                {formik.touched.numberZones && formik.errors.numberZones
                  ? t(formik.errors.numberZones) + formik.values?.numberZones
                  : t('schedule.settings.day_part.numberZones_help_text')}
              </FormHelperText>
            </FormControl>
          </Box>

          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              sx={{ fontSize: 16, mb: 2, display: 'inline-block' }}
            >
              {t('schedule.settings.day_part.shelfSettings')}
            </FormLabel>
            {!formik.values.numberZones && (
              <TextField
                disabled
                value={t('schedule.settings.day_part.shelfSettings_empty')}
              />
            )}
            {!!formik.values.numberZones && (
              <Paper sx={{ p: 2, mt: 1 }}>
                <ZoneSettingsSmall
                  numberZones={formik.values.numberZones}
                  groupId={businessUnitId}
                  values={formik.values.shelfSettings}
                  onChange={(values) => {
                    formik.setFieldValue('shelfSettings', values);
                  }}
                  dayPartId={dayPartId}
                />
              </Paper>
            )}
            <FormHelperText
              error={Boolean(
                formik.touched.shelfSettings && formik.errors.shelfSettings
              )}
            >
              {formik.touched.shelfSettings &&
                formik.errors.shelfSettings &&
                t(Object.values(formik.errors.shelfSettings)[0])}
            </FormHelperText>
          </FormControl>

          {/* debug form */}
          {/* <p style={{ width: 280, wordWrap: 'break-word' }}>
            {JSON.stringify({
              c: formik.submitCount,
              v: formik.values,
              e: formik.errors,
              t: formik.touched,
            })}
          </p> */}
        </Stack>

        <Box
          className="timer-settings-footer"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginY: 4,
          }}
        >
          <Box>
            <Button
              variant="contained-gray"
              sx={{ height: '3em' }}
              onClick={onClickBack}
              disabled={formik.isSubmitting}
            >
              <ArrowBackIcon height={'1em'} />
            </Button>
          </Box>
          <Button
            variant="contained"
            type="submit"
            size="large"
            rounded
            disabled={formik.isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Box>
      </form>
    </Container>
  );
}

export default DayPartNewPage;
