export const AIR_CONNECT_NAME_OPTIONS = [
  'Flexeserve',
  'Flexeserve – LEFT',
  'Flexeserve – RIGHT',
  'Roller Grill',
  'Roller Grill – LEFT',
  'Roller Grill – RIGHT',
  'Pizza Spinner',
  'Pizza Spinner – LEFT',
  'Pizza Spinner - RIGHT',
  'Flexeserve – LEFT*',
  'Flexeserve – RIGHT*',
].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

export const AIR_CONNECT_ZONE_DESCRIPTION_OPTIONS = [
  'Zone 1',
  'Zone 2',
  'Zone 3',
  'Zone 4',
  'Zone 5',
  'Smart Switch',
].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
