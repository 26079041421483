import { List, ListItem, ListSubheader } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchContext } from '../SearchBar';
interface KeyValue {
  key: string;
  value: string;
}
interface SearchListProps<T extends KeyValue> {
  list: T[];
  header: string;
  loading?: boolean;
}
export const SearchList = <T extends KeyValue>(props: SearchListProps<T>) => {
  const { selectItem, setOpen, setSearch, setSelectedItem, setHasMatch } =
    useContext(SearchContext);
  const { list, header, loading = false } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setHasMatch(list.length > 0);
  }, [list, setHasMatch]);

  const onSelectItem = (item: T) => {
    setSelectedItem(item);
    selectItem?.(item);
    setSearch(item.value);
    setOpen(false);
  };

  let content = <></>;

  if (loading) {
    content = <ListItem>{t('general.loading')}</ListItem>;
  }
  if (!list.length) {
    content = (
      <ListItem>{t('device.search.message_no_results_found')}</ListItem>
    );
  } else {
    content = (
      <>
        {list.map((item) => (
          <ListItem
            key={item.key}
            onClick={() => onSelectItem(item)}
            sx={{
              cursor: 'pointer',
              ':hover': { backgroundColor: 'rgba(0,0,0,0.1)' },
            }}
          >
            {item.value}
          </ListItem>
        ))}
      </>
    );
  }

  return (
    <List>
      <ListSubheader>{t(header)}</ListSubheader>
      {content}
    </List>
  );
};
