import { ComposableDevicesManagerStoreInfoResponse } from '../../device/hooks/useDevicesManager';
import { ComposableDevicesStoreInfoResponse } from './useDevicesOperator';

export enum ZONE {
  UPPER_ZONE = '1',
  LOWER_ZONE = '2',
}
function getZone(
  device:
    | ComposableDevicesStoreInfoResponse
    | ComposableDevicesManagerStoreInfoResponse
) {
  return device.composableDevice?.labels['node.nodeType'];
}
export function isAuxiliaryDevice(
  device:
    | ComposableDevicesStoreInfoResponse
    | ComposableDevicesManagerStoreInfoResponse
) {
  return getZone(device) === ZONE.LOWER_ZONE;
}
export function isCommonDevice(
  device:
    | ComposableDevicesStoreInfoResponse
    | ComposableDevicesManagerStoreInfoResponse
) {
  return getZone(device) === ZONE.UPPER_ZONE;
}

export default getZone;
