import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../../theme/ui/Atoms/Button';
import RadioGroup from '../../../../theme/ui/Atoms/RadioGroup';
import { CommanderBoxIcon } from '../../../../assets';
import BackButton from '../BackButton';

export default function SelectTypeInstallation() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const options = [
    { value: 'store', label: t('initial_setup.installation_type_store') },
    {
      value: 'individual',
      label: t('initial_setup.installation_type_individual'),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [option, setOption] = useState('store');

  const onClickBack = () => {
    navigate('/settings');
  };
  const onClickNext = () => {
    navigate(`/install-facilities/${option}/search-store`);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100%"
    >
      <Container maxWidth="xs">
        <Paper elevation={5} sx={{ padding: 3 }}>
          <Typography variant="h1">
            {t('initial_setup.title_setup_air_connect')}
          </Typography>

          <Typography sx={{ m: 4, fontSize: '6em' }} textAlign="center">
            <CommanderBoxIcon height={'2em'} />
          </Typography>

          <Box textAlign={'center'} mt={3}>
            <RadioGroup
              label={t('initial_setup.select_type_installation')}
              // TODO: we not know what means individual commander
              // onChange={(e, v) => setOption(v)}
              value={option}
              options={options}
            />
          </Box>

          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} sm={6}>
              <BackButton onClick={onClickBack}>{t('button.back')}</BackButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                rounded
                fullWidth
                onClick={onClickNext}
              >
                {t('button.next')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}
