import { ComposableDevicesManagerStoreInfoResponse } from '../../../service/device/hooks/useDevicesManager';
import { ComposableDevicesStoreInfoResponse } from '../../../service/store/hooks/useDevicesOperator';
import { ZoneSettingsResponse } from '../../../service/store/modules/zone/ZoneService.model';
import { useGetZoneOptions } from '../../../service/store/modules/zone/util';

const SUPPORTED_TEST_OPTIONS = [
  'MANUAL',
  'LIGHTS_ONLY',
  'OFF',
  '170°F',
  '175°F',
  '180°F',
];
/**
 * from zoneSettings from device get a list of options for select
 * just for CircleK because is difficult get them from the storeId
 * So we put burned on code on while is implemented correctly
 */
export function useGetZoneTestOptions(
  zoneSettings: ZoneSettingsResponse['configured'] | undefined,
  zoneIndex: number,
  device:
    | ComposableDevicesStoreInfoResponse
    | ComposableDevicesManagerStoreInfoResponse
) {
  const options = useGetZoneOptions(zoneSettings, zoneIndex, device);
  return options?.filter((option: any) =>
    SUPPORTED_TEST_OPTIONS.includes(option.value)
  );
}
