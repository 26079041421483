import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, AppStore, RootState } from '../../store.model';
import { nameReducer } from '../CUTranslation.model';
import { ZipDeviceControlUnit } from '../../controlUnit/controlUnit.model';
import {
  getLoadingCUTranslationFromDevice,
  getNeedFetchCUTranslationFromDevices,
  getTranslationState,
} from '../selectors';
import CUTranslationCalls from '../../../service/CUTranslation/CUTranslationCalls';
import { toBase64 } from '../../../shared/util/base64';

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

export const fetchControlUnitTranslations = createAsyncThunk(
  `${nameReducer}/fetchControlUnitTranslations`,
  async (props: { deviceId: string; controlUnitId: number | string }) => {
    const { controlUnitId, deviceId } = props;
    const translationCalls = new CUTranslationCalls();

    return await translationCalls.getTranslationByControlUnit(
      deviceId,
      controlUnitId
    );
  }
);

export const warmUpControlUnitsTranslations =
  (props: { deviceId: string; controlUnitIds: (string | number)[] }) =>
  (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const { deviceId } = props;
    const loading = getLoadingCUTranslationFromDevice(getState(), { deviceId });
    if (loading === undefined)
      return dispatch(fetchControlUnitsTranslations(props));
  };

export const fetchControlUnitsTranslations = createAsyncThunk(
  `${nameReducer}/fetchControlUnitsTranslations`,
  async (
    props: { deviceId: string; controlUnitIds: (string | number)[] },
    thunkAPI
  ) => {
    const { controlUnitIds, deviceId } = props;
    return Promise.all(
      controlUnitIds.map((controlUnitId) =>
        thunkAPI.dispatch(
          fetchControlUnitTranslations({ deviceId, controlUnitId })
        )
      )
    );
  }
);

export const warmUpDevicesControlUnitsTranslations =
  (props: { zipDeviceControlUnits: ZipDeviceControlUnit[] }) =>
  (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const { zipDeviceControlUnits } = props;
    const needFetch = getNeedFetchCUTranslationFromDevices(getState(), {
      zipDeviceControlUnits,
    });
    if (needFetch) return dispatch(fetchDevicesControlUnitsTranslations(props));
  };

export const fetchDevicesControlUnitsTranslations = createAsyncThunk(
  `${nameReducer}/fetchDevicesControlUnitsTranslations`,
  async (
    props: { zipDeviceControlUnits: ZipDeviceControlUnit[] },
    thunkAPI
  ) => {
    const { zipDeviceControlUnits } = props;
    const loadingDeviceCUTranslationState = getTranslationState(
      thunkAPI.getState() as RootState
    );
    const translationCalls = new CUTranslationCalls();
    const listPromise = zipDeviceControlUnits.flatMap((zipDeviceControlUnit) =>
      zipDeviceControlUnit.controlUnits?.map((item) => {
        const encodedCommanderId = toBase64(zipDeviceControlUnit.commanderId);
        const encodedDeviceId = toBase64(zipDeviceControlUnit.deviceId);
        if (
          loadingDeviceCUTranslationState[encodedDeviceId]?.[item.id] ===
          undefined
        ) {
          return translationCalls
            .getTranslationByControlUnit(encodedCommanderId, item.id)
            .then((data) => ({
              data,
              deviceId: encodedDeviceId,
              controlUnitId: item.id,
            }));
        }
        return undefined;
      })
    );
    return Promise.all(listPromise.filter((promise) => !!promise));
  }
);
