import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import MarketLogoImg from '../../../../../assets/Market_SQ_Logo.png';

function HeaderLogo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component={'img'}
      src={MarketLogoImg}
      alt="market logo"
      sx={{
        fontSize: isMobile ? '0.6rem' : undefined,
        height: '3em',
        color: (theme) => theme.palette.text.primary,
      }}
    />
  );
}

export default HeaderLogo;
