import React from 'react';
import { NormalizedGroup } from '../../../../../../../redux/structure/GroupsAdapter';
import { Button, Stack } from '@mui/material';
import { WheelContainedIcon } from '../../../../../../../assets';
import { BusinessLayerGroupType } from '../../../../../../../redux/structure/util/getTypeFromGroup';
import LocationAPI from '../../../../../../../service/locations/LocationAPI';
import { LoadingButton } from '@mui/lab';
import { DEFAULT_TEMPERATURE_UNIT } from '../../../../../../../service/temperaturePreference';
import { useTranslation } from 'react-i18next';
import { EntityAlarmsAction } from '../../EntityActions/EntityAlarmsAction';
import { EntityCommanderStatusAction } from '../../EntityActions/EntityCommanderStatusAction';
import { useSearchParams } from 'react-router-dom';

interface SubHeaderEntityActionsProps {
  entity?: NormalizedGroup;
  type: string;
}
function SubHeaderEntityActions(props: SubHeaderEntityActionsProps) {
  const { entity, type } = props;
  const [searchBarParams] = useSearchParams();
  const isTemperatureActive = searchBarParams.get('alarmsActive') === 'true';
  const isCommanderStatusFalling =
    searchBarParams.get('deviceStatusFailing') === 'true';

  if (!entity) return null;
  return (
    <Stack
      direction={'row'}
      spacing={2}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <EntityCommanderStatusAction
        {...props}
        active={isCommanderStatusFalling}
      />
      <EntityAlarmsAction {...props} active={isTemperatureActive} />

      {type === BusinessLayerGroupType.BusinessUnit && (
        <>
          <EntityTemperatureAction {...props} />
          <EntitySettingsAction {...props} />
        </>
      )}
    </Stack>
  );
}

function EntitySettingsAction(props: SubHeaderEntityActionsProps) {
  const { entity } = props;

  return (
    <Button
      variant="outlined"
      color="inherit"
      href={`/manager/businessUnit/${entity?.uuid}/actions`}
      sx={{ fontSize: '1.8rem', width: '6rem', padding: '0.5rem' }}
    >
      <WheelContainedIcon />
    </Button>
  );
}

function EntityTemperatureAction(props: SubHeaderEntityActionsProps) {
  const { entity } = props;
  const { t } = useTranslation();
  const {
    data: temperaturePreferenceByRegion,
    isLoading: isLoadingTemperaturePreferenceByRegion,
  } = LocationAPI.useGetTemperaturePreferenceByGroupQuery(entity?.uuid ?? '');

  const initialTemperaturePreference =
    temperaturePreferenceByRegion ?? DEFAULT_TEMPERATURE_UNIT;

  const temperaturePreferenceLabel = t(
    `settings.temperature_preference.${initialTemperaturePreference}`
  );

  return (
    <LoadingButton
      variant="outlined"
      color="inherit"
      loading={isLoadingTemperaturePreferenceByRegion}
      href={`/manager/businessUnit/${entity?.uuid}/temperature-preference`}
      sx={{ fontSize: '1.2rem', width: '6rem' }}
    >
      {temperaturePreferenceLabel}
    </LoadingButton>
  );
}
export default SubHeaderEntityActions;
