import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store.model';
import { NormalizedGroup } from '../GroupsAdapter';
import StructureAPI from '../StructureAPI';
import { fromBase64, toBase64 } from '../../../shared/util/base64';
import getPathOfGroups from '../util/getPathOfGroups';
import _ from 'lodash';

export const predicateFromGroupAndChildrenDictionaryRecursive = (
  groupDictionary: Record<string, NormalizedGroup>,
  encodedGroupId: string,
  predicate: (group: NormalizedGroup) => unknown
): void => {
  const group = groupDictionary[encodedGroupId];

  if (group) {
    predicate(group);
  }
  if (group?.childGroups) {
    group.childGroups?.forEach((childGroupId) => {
      predicateFromGroupAndChildrenDictionaryRecursive(
        groupDictionary,
        childGroupId,
        predicate
      );
    });
  }
};

export const getGroupsChildIdsFromGroupSelector = createSelector(
  [
    StructureAPI.endpoints.getStructureV2.select(),
    (_state: RootState, props: { groupId: string }) => toBase64(props.groupId),
  ],
  (structure, encodedGroupId) => {
    return (
      structure.data?.entities &&
      getGroupsIdsFromGroupDictionary(
        structure.data?.entities.groups,
        encodedGroupId
      )
    );
  }
);

const getGroupsIdsFromGroupDictionary = (
  groupDictionary: Record<string, NormalizedGroup>,
  encodedGroupId: string
): string[] => {
  const encodedGroupsIds: string[] = [];
  predicateFromGroupAndChildrenDictionaryRecursive(
    groupDictionary,
    encodedGroupId,
    (group) => {
      encodedGroupsIds.push(group.id);
    }
  );
  // Remove the first element because it is the group itself
  encodedGroupsIds.shift();
  return encodedGroupsIds;
};

export const getDevicesIdsFromGroupSelector = createSelector(
  [
    StructureAPI.endpoints.getStructureV2.select(),
    (_state: RootState, props: { groupId: string }) => toBase64(props.groupId),
  ],
  (structure, encodedGroupId) => {
    return (
      structure.data?.entities &&
      getDevicesIdsFromGroupDictionary(
        structure.data?.entities.groups,
        encodedGroupId
      )
    );
  }
);

export const getDevicesIdsFromGroupDictionary = (
  groupDictionary: Record<string, NormalizedGroup>,
  encodedGroupId: string
): string[] => {
  const devicesIds: string[] = [];
  predicateFromGroupAndChildrenDictionaryRecursive(
    groupDictionary,
    encodedGroupId,
    (group) => {
      if (group.devices) {
        devicesIds.push(...group.devices);
      }
    }
  );
  return _(devicesIds).uniq().map(fromBase64).value();
};

export const getGroupFromGroupIdSelector = createSelector(
  [
    StructureAPI.endpoints.getStructureV2.select(),
    (_state: RootState, props: { groupId: string }) => toBase64(props.groupId),
  ],
  (structure, encodedGroupId) => {
    return (
      structure.data?.entities &&
      structure.data?.entities.groups[encodedGroupId]
    );
  }
);

export const getHighestParentGroupFromGroupIdSelector = createSelector(
  [
    StructureAPI.endpoints.getStructureV2.select(),
    (_state: RootState, props: { groupId: string }) => toBase64(props.groupId),
  ],
  (structure, encodedGroupId) => {
    return (
      structure.data?.entities &&
      _.first(getPathOfGroups(structure.data?.entities.groups, encodedGroupId))
        ?.uuid
    );
  }
);
