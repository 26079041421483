import React, { createContext, PropsWithChildren, useState } from 'react';
import { TextFieldProps, useMediaQuery, useTheme } from '@mui/material';
import { SearchBarMobile } from './SearchbarMobile/SearchBarMobile';
import { SearchBarDesktop } from './SearchBarDesktop/SearchBarDesktop';
import { BUSearch } from './BUSearch/BUSearch';
import { StoreSearch } from './StoreSearch/StoreSearch';
import { RegionSearch } from './RegionSearch/RegionSearch';

interface SearchContextType {
  search: string;
  setSearch: (search: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectItem: (item: any) => void;
  selectedItem: any;
  setSelectedItem: (item: any) => void;
  hasMatch: boolean;
  setHasMatch: (hasMatch: boolean) => void;
}
type SearchInputProps = Omit<TextFieldProps, 'variant'>;
export interface SearchBarProps extends PropsWithChildren, SearchInputProps {}
export const SearchContext = createContext({
  search: '',
  setSearch: (search: string) => {},
  open: false,
  setOpen: (open: boolean) => {},
  selectItem: (item: any) => {},
  selectedItem: null,
  hasMatch: false,
  setHasMatch: (hasMatch: boolean) => {},
} as SearchContextType);

export const SearchBar = (
  props: {
    selectItem: (item: any) => void;
  } & SearchBarProps
) => {
  const { selectItem, children, ...searchBarProps } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [hasMatch, setHasMatch] = useState<boolean>(false);

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch,
        open,
        setOpen,
        selectItem,
        selectedItem,
        setSelectedItem,
        hasMatch,
        setHasMatch,
      }}
    >
      {isMobile ? (
        <SearchBarMobile {...searchBarProps}>{children}</SearchBarMobile>
      ) : (
        <SearchBarDesktop {...searchBarProps}>{children}</SearchBarDesktop>
      )}
    </SearchContext.Provider>
  );
};

SearchBar.BUSearch = BUSearch;
SearchBar.RegionSearch = RegionSearch;
SearchBar.StoreSearch = StoreSearch;
