import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PaperButton from '../../../../../theme/ui/Atoms/PaperButton';
import StatusOverviewByDevice from '../StatusOverviewByDevice';
import InputSelect from '../../../../../theme/ui/Atoms/InputSelect/InputSelect';
import ScheduleAPI from '../../../../../service/schedule/ScheduleAPI';
import useWatchError from '../../../../../shared/hooks/useWatchError';
import { CircularProgress } from '@mui/material';
import Table from '../../../../../theme/ui/Atoms/Table/Table';
import { useAppSelector } from '../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../shared/featureToggle';
import { DevicesStoreInfoResponse } from '../../../../../service/store/StoreService.model';
import DeviceType from '../../../InstallFacilities/DevicesManagerOverviewSetUp/DeviceType';
import { getHighestParentGroupFromGroupIdSelector } from '../../../../../redux/structure/selectors/Group.selector';

interface ManagerViewRegionProps {
  devices?: DevicesStoreInfoResponse[];
}
export default function ManagerViewStore(props: ManagerViewRegionProps) {
  const { devices } = props;
  const { groupId: storeId } = useParams<{ groupId: string }>();
  const visibleDevices = devices?.filter((device) => device.visible);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClickDevice = (device: DevicesStoreInfoResponse) => {
    navigate(`/manager/store/${device.storeId}/device/${device.uuid}/actions`);
  };
  const higherGroupId =
    useAppSelector((state) =>
      getHighestParentGroupFromGroupIdSelector(state, {
        groupId: storeId ?? '',
      })
    ) ?? '';
  const {
    error: errorSchedules,
    isLoading: isLoadingSchedules,
    isFetching: isFetchingSchedules,
    data: schedules,
  } = ScheduleAPI.useGetSchedulesByGroupQuery(higherGroupId ?? '');

  const optionsSchedule = useMemo(
    () =>
      schedules?.map((schedule) => ({
        text: schedule.scheduleName,
        value: schedule.scheduleId,
      })) ?? [],
    [schedules]
  );

  useWatchError(errorSchedules);

  const { Schedule } = useAppSelector(getFeatureToggle);

  return (
    <Table
      sx={{
        '& th.header_store': {
          minWidth: 400,
        },
      }}
    >
      <thead style={{ border: 'none' }}>
        <tr>
          <Typography
            component={'th'}
            className="header_store"
            color="primary"
            variant="subtitle2"
            sx={{ textAlign: 'left!important' }}
          >
            {t('view_locations.header_device_name')}
          </Typography>
          <Typography component={'th'} color="primary" variant="subtitle2">
            {t('view_locations.header_model')}
          </Typography>
          {!!Schedule && (
            <Typography component={'th'} color="primary" variant="subtitle2">
              {t('schedule.settings.view_locations.schedule_header')}
            </Typography>
          )}
          <Typography component={'th'} color="primary" variant="subtitle2">
            {t('view_locations.header_status')}
          </Typography>
        </tr>
      </thead>
      <tbody>
        {visibleDevices?.map((device) => {
          return (
            <PaperButton
              component={'tr'}
              key={device.uuid}
              elevation={undefined}
              onClick={() => onClickDevice(device)}
            >
              <td>
                <Typography variant="body1" textAlign={'left'}>
                  {device.unitName}
                </Typography>
              </td>
              <td>
                <Typography variant="body1" textAlign={'center'}>
                  <DeviceType device={device} />
                </Typography>
              </td>
              {!!Schedule && (
                <td>
                  <Typography variant="body1" textAlign={'center'}>
                    {isLoadingSchedules || isFetchingSchedules ? (
                      <CircularProgress size={'1em'} />
                    ) : (
                      <InputSelect
                        id={`${device.uuid}-schedule`}
                        name={`${device.uuid}-schedule`}
                        options={optionsSchedule}
                        value={device.schedule}
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                          e.stopPropagation();
                          navigate(
                            `/manager/store/${device.storeId}/device/${device.uuid}/schedule`
                          );
                        }}
                        fullWidth
                      />
                    )}
                  </Typography>
                </td>
              )}
              <td>
                <StatusOverviewByDevice device={device} />
              </td>
            </PaperButton>
          );
        })}
      </tbody>
    </Table>
  );
}
