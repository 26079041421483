import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ActionButton from '../../../../../theme/ui/Atoms/ActionButton/ActionButton';
import {
  ArrowBackIcon,
  ChangePasswordIcon,
  ChartsIcon,
  DayPartScheduleIcon,
  LabelIcon,
  TemperatureIcon,
} from '../../../../../assets';
import { useAppSelector } from '../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../shared/featureToggle';
import Button from '../../../../../theme/ui/Atoms/Button';

export default function ManagerDeviceActions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Schedule, StyledComponentsVariants } =
    useAppSelector(getFeatureToggle);
  const { storeId = '' } = useParams();
  const onClickBack = () => {
    navigate(`/manager/view-locations/${storeId}`);
  };
  return (
    <Box display={'grid'} alignItems={'center'} height={'100%'}>
      <Stack
        direction={'column'}
        flexWrap={'wrap'}
        justifyContent={'center'}
        width={'100%'}
      >
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          width={'100%'}
        >
          <ActionButton
            text={t('performance.title')}
            IconComponent={ChartsIcon}
            onClick={() => navigate(`../performances`)}
          />
          <ActionButton
            text={t('change_password.title')}
            IconComponent={ChangePasswordIcon}
            onClick={() => navigate(`../change-password`)}
          />
          <ActionButton
            text={t('initial_setup.title_update')}
            IconComponent={LabelIcon}
            onClick={() => navigate(`../update-setup`)}
          />
          {!!Schedule && (
            <ActionButton
              text={t('schedule.settings.view_locations.assign_schedule')}
              IconComponent={DayPartScheduleIcon}
              onClick={() => navigate(`../schedule`)}
            />
          )}
          {StyledComponentsVariants !== 'circle-k' && (
            <ActionButton
              text={t('settings.temperature_preference.title')}
              IconComponent={TemperatureIcon}
              onClick={() => navigate(`../temperature-preference`)}
            />
          )}
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginY: 2,
            paddingX: '8rem',
          }}
        >
          <Button
            variant="contained-gray"
            sx={{ height: '3em' }}
            onClick={onClickBack}
          >
            <ArrowBackIcon height={'1em'} />
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
