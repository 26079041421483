import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../shared/axiosBaseQuery';
import { REHYDRATE } from 'redux-persist';
import { toBase64 } from '../../shared/util/base64';
import { ZoneSettingResponse } from '../store/modules/zone/ZoneService.model';
import recordToArray from '../../shared/util/recordToArray';
import { ZoneSettingWitIdResponse } from './ZoneSettingsAPI.model';

const ZoneSettingsAPI = createApi({
  reducerPath: 'zoneSettings',
  baseQuery: axiosBaseQuery({ baseUrl: 'flexeserve/' }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints(build) {
    return {
      getZoneSettings: build.query<ZoneSettingWitIdResponse[], string>({
        query: (deviceId: string) => {
          const EncodedDeviceID = toBase64(deviceId);
          return {
            url: `device/${EncodedDeviceID}/settings`,
            method: 'get',
          };
        },
        transformResponse: (response: Record<string, ZoneSettingResponse>) => {
          const array = recordToArray<
            ZoneSettingResponse,
            ZoneSettingWitIdResponse
          >(response, 'id');
          return array;
        },
      }),
      getZoneSettingsByGroup: build.query<ZoneSettingWitIdResponse[], string>({
        query: (groupId: string) => {
          const encodedGroupId = toBase64(groupId);
          return {
            url: `group/${encodedGroupId}/settings`,
            method: 'get',
          };
        },
        transformResponse: (response: Record<string, ZoneSettingResponse>) => {
          const array = recordToArray<
            ZoneSettingResponse,
            ZoneSettingWitIdResponse
          >(response, 'id');
          return array;
        },
      }),

      setZoneSettingsByGroup: build.mutation<
        any,
        {
          zoneSetting: ZoneSettingWitIdResponse[];
          groupId: string;
        }
      >({
        query: ({ zoneSetting, groupId }) => {
          const encodedGroupId = toBase64(groupId);
          const data = zoneSetting.reduce((acc, { id, ...zoneSetting }) => {
            acc[id] = zoneSetting;
            return acc;
          }, {} as Record<string, ZoneSettingResponse>);

          return {
            url: `group/${encodedGroupId}/settings/`,
            method: 'put',
            data,
          };
        },
      }),
    };
  },
});

export default ZoneSettingsAPI;

export const {
  useGetZoneSettingsByGroupQuery,
  useSetZoneSettingsByGroupMutation,
} = ZoneSettingsAPI;
