import {
  LanguagesSported,
  TranslationCommanderObject,
} from '../../service/CUTranslation/CUTranslation.model';

/**
 * IncidentAPIResponse
 * extracted from
 * http://gitlab.st.local/stoerk/cloud/incident-manager/-/blob/master/incident-manager/src/main/java/com/stoerk/incident/management/IncidentBaseData.java
 * 
 * ```json
{
    "id": "12345678-1234-1234-1234-123456789012",
    "device": "12345678-1234-1234-1234-210987654321",
    "cu":1004,
    "priority": "MEDIUM",
    "category": "ALARM",
    "status": "INPROGRESS",
    "assignedUser": "12345678-1234-1234-1234-123456789012",
    "policy":"12345678-1234-1234-1234-000000000000",
    "reportTimestamp": 1667804296452,
    "summary": "F90",
    "description": "F90: Network error",
    "comment": "I think i accidentally unplugged something...",
    "history": [],
    "localizedDescriptions": {
        "en": "F90: Network error",
        "de": "F90: Netzwerkfehler"
    },
    "localizedSummaries": {
        "en": "F90",
        "de": "F90"
    },
    "comments":[
        {
            "timestamp":1667804896452,
            "user":"12345678-1234-1234-1234-123456789012",
            "text":"update: the cable is damaged"
        }
    ]
}
 * ```
 */
export interface AlarmAPIResponse {
  /** id UUID */
  id: string;
  summary: string;
  description: string;
  /** id UUID */
  device: string;
  cu?: number;
  /** UUID of policy that generate this incident */
  policy: string;
  priority: INCIDENT_PRIORITY_TYPES;
  category: INCIDENT_CATEGORIES_TYPES;
  status: INCIDENT_STATUS_TYPES;
  /** id UUID */
  assignedUser: string;

  /** timestamp */
  reportTimestamp: number;

  /** other languages are unknown */
  localizedDescriptions: Record<LanguagesSported, string | undefined>;
  /** other languages are unknown */
  localizedSummaries: Record<LanguagesSported, string | undefined>;
  history: unknown[];
  comments: CommentIncidentAPIResponse[];
  /** I'm not sure if it is supported */
  comment: string;
}
/**
 * IncidentHistoryAPIResponse
 * a comment is a history entry made for an user
 * it can have a attachment but is not supported at the moment
 * @example
 * ```json
{
    "timestamp": 1667804896452,
    "user": "12345678-1234-1234-1234-123456789012",
    "text": "update: the cable is damaged",
}
 * ```
 */

export interface CommentIncidentAPIResponse {
  timestamp: number;
  /** UUID of user */
  user: string;
  text: string;
}

export enum INCIDENT_STATUS_TYPES {
  NEW = 'NEW',
  INPROGRESS = 'INPROGRESS',
  RESOLVED = 'RESOLVED',
}

export enum INCIDENT_PRIORITY_TYPES {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}
/** at the moment I don't know any more types of them */
export enum INCIDENT_CATEGORIES_TYPES {
  MAINTENANCE = 'MAINTENANCE',
  ALARM = 'ALARM',
}

/**
 * IncidentAPIResponseMVP
 * extracted from
 * http://gitlab.st.local/stoerk/cloud/incident-manager/-/blob/master/incident-manager/src/main/java/com/stoerk/incident/management/IncidentBaseData.java
 * 
 * ```json
{
    "uuid": "12345678-1234-1234-1234-123456789012",
    "deviceId": "12345678-1234-1234-1234-210987654321",
    "cu":1004,
    "assignedUserId": "12345678-1234-1234-1234-123456789012",
    "policy":"12345678-1234-1234-1234-000000000000",
    "reportTimestamp": 1667804296452,
    "description": "F90: Network error",
    "workspaceId": "12345678-1234-1234-1234-123456789012",
}
 * ```
 */
export interface AlarmAPIResponseV2 {
  /** id UUID */
  uuid: string;
  summary: string;
  description: string;
  /** id UUID */
  deviceId: string;
  cu?: number;
  /** UUID of policy that generate this incident */
  policyId: string;
  /** id UUID */
  assignedUserId: string;
  /** timestamp */
  timestamp: number;
  /** workspace UUID */
  workspaceId: string;
  value?: string;
  event?: string;
  resolutionTimestamp?: number;
  eventTranslation?: TranslationCommanderObject;
}

export interface AlarmsQueryAPIResponse {
  data: AlarmAPIResponseV2[];
  count?: number;
}

export enum AlarmState {
  CLOSED = 'CLOSED',
  NEW = 'NEW',
}
export interface AlarmsQueryParamsAPIRequest {
  page_size?: number;
  offset?: number;
  after_id?: string;
  policy?: string;
  state?: AlarmState;
  group?: string;
  device?: string;
  created_before?: number;
  created_after?: number;
  resolved_before?: number;
  resolved_after?: number;
  count?: boolean;
  translate?: boolean;
}
export const ALARMS_POLLING_INTERVAL = 120000;
