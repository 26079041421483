import { toBase64 } from '../../../shared/util/base64';
import { ComposableDevicesStoreInfoResponse } from '../../store/hooks/useDevicesOperator';
import { DevicesStoreInfoResponse } from '../../store/StoreService.model';
import { DeviceAPI } from '../DeviceAPI';

// 10 seconds
export const M_SECONDS_POOLING_SETUP = 1000 * 10;
const FlexeserveDeviceAPI = DeviceAPI.injectEndpoints({
  endpoints: (build) => ({
    getDeviceByFlexeserveDeviceId: build.query<
      DevicesStoreInfoResponse | undefined,
      string
    >({
      query: (flexeserveDeviceId) => {
        const encodedFlexeserveDeviceId = toBase64(flexeserveDeviceId);
        return {
          url: `/flexeserve/device/${encodedFlexeserveDeviceId}`,
          method: 'get',
        };
      },
      providesTags: ['device-actions', 'zone-actions'],
    }),
    getStoreDevices: build.query<DevicesStoreInfoResponse[], string>({
      query: (storeId) => {
        return {
          url: `flexeserve/group/${toBase64(storeId)}/deviceinfo`,
          method: 'get',
        };
      },
      providesTags: ['locations-actions', 'device-actions', 'zone-actions'],
    }),

    updateSetupDevice: build.mutation<
      void,
      Partial<ComposableDevicesStoreInfoResponse>
    >({
      query: (device) => {
        const encodedDeviceId = toBase64(device?.uuid ?? '');
        return {
          url: `flexeserve/device/${encodedDeviceId}`,
          method: 'put',
          data: device,
        };
      },
      invalidatesTags: ['locations-actions', 'device-actions'],
    }),
  }),
});

export default FlexeserveDeviceAPI;
