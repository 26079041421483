import React, { useMemo } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';
import Input from '../../../../../../../theme/ui/Atoms/Input/Input';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../../../../theme/ui/Atoms/Button/Button';
import { ArrowBackIcon, TrashIcon } from '../../../../../../../assets';
import {
  CONFIRMATION_TYPE,
  ScheduleDayPartAssignmentAPIResponse,
  ScheduleEditAPIResponse,
  WeekDaysISO,
} from '../../../../../../../service/schedule/ScheduleAPI.model';
import ScheduleAPI from '../../../../../../../service/schedule/ScheduleAPI';
import { ScheduleAPIResponse } from '../../../../../../../service/schedule/ScheduleAPI.model';
import useErrorMessage from '../../../../../../../shared/hooks/useErrorMessage';
import useWatchError from '../../../../../../../shared/hooks/useWatchError';
import ZoneSettingsAPI from '../../../../../../../service/zoneSettings/ZoneSettingsAPI';
import TimerPicker from '../../../../../../../theme/ui/Atoms/TimerPicker/TimerPicker';
import {
  momentToTime,
  timeToMoment,
} from '../../../../../../../shared/util/moment/parseTime';
import SelectDisplayControl from '../DayPart/SelectDisplayControl';
import { ZoneSettingWitIdResponse } from '../../../../../../../service/zoneSettings/ZoneSettingsAPI.model';
import { useAppSelector } from '../../../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../../../shared/featureToggle';
import { optionsSelectZones } from '../DayPartNewPage/DayPartNewPage';
import _ from 'lodash';
import useMessage from '../../../../../../../shared/hooks/useMessage';

const WEEK_DAYS_KEYS = ['1', '2', '3', '4', '5', '6', '7'] as WeekDaysISO[];
const ScheduleValidationSchema = yup.array(
  yup.object({
    partId: yup
      .string()
      .nullable()
      .required('schedule.settings.schedule.dayPart_required'),
    startTime: yup
      .string()
      .nullable()
      .required('schedule.settings.schedule.startTime_required'),
  })
);

const validationSchema = yup.object({
  scheduleName: yup
    .string()
    .required('schedule.settings.schedule.scheduleName_required'),
  zoneCount: yup
    .number()
    .required('schedule.settings.day_part.numberZones_required'),
  schedules: yup.array(
    yup.object({
      selectedDays: yup
        .array(yup.string())
        .min(1)
        .required('schedule.settings.schedule.dayPart_required'),
      dayParts: ScheduleValidationSchema,
    })
  ),
});
const clearInitialValues = {
  scheduleName: '',
  zoneCount: null as number | null | undefined,
  schedules: [
    {
      selectedDays: [] as WeekDaysISO[],
      dayParts: [
        {
          partId: '',
          startTime: '',
          confirmationType: CONFIRMATION_TYPE.NO_CONFIRMATION_REQUIRED,
        },
      ],
    },
  ],
};

function parseFormikValuesToScheduleAPI(
  values: typeof clearInitialValues
): ScheduleEditAPIResponse {
  const daysArray = WEEK_DAYS_KEYS.reduce((acc, day) => {
    const dayParts = values.schedules
      .filter((schedule) => schedule.selectedDays.includes(day))
      .map((schedule) => schedule.dayParts)
      .flat();
    acc[day] = [...(acc[day] ?? []), ...(dayParts || [])];
    return acc;
  }, {} as Record<WeekDaysISO, ScheduleDayPartAssignmentAPIResponse[]>);

  return {
    ...values,
    scheduleName: values.scheduleName,
    zoneCount: values.zoneCount ?? 0,
    schedule: daysArray,
  };
}

function parseScheduleAPIToFormikValues(
  scheduleItem: ScheduleAPIResponse
): typeof clearInitialValues.schedules {
  const newSchedules: typeof clearInitialValues.schedules = [];

  // Map day parts from the schedule and filter out empty ones
  const dayPartsArray = Object.keys(scheduleItem.schedule)
    ?.map((dayPart) => ({
      day: dayPart,
      dayParts: scheduleItem.schedule[dayPart as WeekDaysISO] || [],
    }))
    .filter((item) => item.dayParts.length > 0);

  dayPartsArray.forEach(({ day, dayParts }) => {
    dayParts.forEach((dayPart) => {
      // Check if the schedule already exists, if not, create a new one
      const existScheduleIndex = newSchedules.findIndex((schedule) =>
        schedule.dayParts.some((part) => _.isEqual(part, dayPart))
      );
      if (existScheduleIndex === -1) {
        newSchedules.push({
          selectedDays: [day as WeekDaysISO],
          dayParts: [dayPart],
        });
      } else {
        const existSchedule = newSchedules[existScheduleIndex];
        existSchedule.selectedDays.push(day as WeekDaysISO);
        existSchedule.selectedDays = _.uniq(existSchedule.selectedDays);
      }
    });
  });

  const schedules: typeof clearInitialValues.schedules = [];

  newSchedules.forEach((schedule) => {
    // Check if a schedule with the same selected days already exists
    const existSameDaysIndex = schedules.findIndex((sc) =>
      _.isEqual(sc.selectedDays, schedule.selectedDays)
    );
    if (existSameDaysIndex === -1) {
      schedules.push(schedule);
    } else {
      const existingSchedule = schedules[existSameDaysIndex];
      existingSchedule.dayParts = _.uniq([
        ...existingSchedule.dayParts,
        ...schedule.dayParts,
      ]);
    }
  });

  return schedules;
}

function ScheduleNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showMessage = useMessage();
  const [setSchedule] = ScheduleAPI.useSetSchedulesByRegionMutation();
  const errorMessage = useErrorMessage();
  const { scheduleId = '', businessUnitId = '' } = useParams<{
    scheduleId: string;
    businessUnitId: string;
  }>();

  // Edit schedule
  const {
    error: errorSchedules,
    isLoading: isLoadingSchedules,
    isFetching: isFetchingSchedules,
    data: schedules,
  } = ScheduleAPI.useGetSchedulesByGroupQuery(businessUnitId || '');

  const schedule = useMemo(() => {
    const schedule = schedules?.find((sc) => sc.scheduleId === scheduleId);
    if (schedule) {
      return {
        ...schedule,
        schedules: parseScheduleAPIToFormikValues(schedule),
        zoneCount: schedule.zoneCount,
      };
    }
  }, [scheduleId, schedules]);

  const formik = useFormik({
    initialValues: (schedule ??
      clearInitialValues) as typeof clearInitialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      return await setSchedule({
        schedule: parseFormikValuesToScheduleAPI(values),
        groupId: businessUnitId ?? '',
      })
        .unwrap()
        .then(() => {
          showMessage(t('schedule.settings.schedule.save_success'));
          onClickBack();
        })
        .catch(errorMessage);
    },
  });

  // options
  const {
    error: errorDayParts,
    isLoading: isLoadingDayParts,
    isFetching: isFetchingDayParts,
  } = ScheduleAPI.useGetDayPartByGroupQuery(businessUnitId);
  const {
    isFetching: isFetchingZoneSettings,
    isLoading: isLoadingZoneSettings,
    error: errorZoneSettings,
    data: zoneSettings = [],
  } = ZoneSettingsAPI.useGetZoneSettingsByGroupQuery(businessUnitId);

  const dayPartsLoading =
    isLoadingDayParts ||
    isFetchingDayParts ||
    isLoadingZoneSettings ||
    isFetchingZoneSettings;

  const error = errorDayParts || errorZoneSettings || errorSchedules;
  useWatchError(error);

  const onClickBack = () => {
    const dayPartListLink = `/manager/businessUnit/${businessUnitId}/schedule-settings/schedule`;
    navigate(dayPartListLink);
  };

  const onClearForm = async () => {
    formik.resetForm();
    await formik.setValues({ ...schedule, ...clearInitialValues });
  };

  const handleChangeZoneCount = (
    e: React.ChangeEvent<HTMLInputElement>,
    zoneCount: string
  ) => {
    formik.setFieldValue('zoneCount', Number(zoneCount));
    formik.setFieldValue('schedule.1', []);
  };
  if (isLoadingSchedules || isFetchingSchedules)
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );

  return (
    <Container maxWidth="md">
      <form onSubmit={formik.handleSubmit}>
        <Container maxWidth="sm">
          <Typography variant="h5" mt={1} fontWeight={700}>
            {t('schedule.settings.schedule.new_schedule')}
          </Typography>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Input
              id={`scheduleName`}
              name={`scheduleName`}
              label={t(`schedule.settings.schedule.scheduleName`)}
              placeholder={t(
                `schedule.settings.schedule.scheduleName_placeholder`
              )}
              value={formik.values?.scheduleName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(
                formik.touched.scheduleName &&
                  formik.errors.scheduleName &&
                  t(formik.errors.scheduleName)
              )}
              helperText={
                formik.touched.scheduleName &&
                formik.errors.scheduleName &&
                t(formik.errors.scheduleName)
              }
              required
              aria-required
              fullWidth
              sx={{ label: { fontSize: 16, mb: 2, display: 'inline-block' } }}
            />
            <FormControl>
              <FormLabel
                id={`zoneCount`}
                sx={{ fontSize: 16, mb: 2, display: 'inline-block' }}
              >
                {t('schedule.settings.day_part.numberZones')}*
              </FormLabel>
              <RadioGroup
                aria-labelledby="zoneCount"
                name={`zoneCount`}
                onChange={handleChangeZoneCount}
                value={formik.values.zoneCount}
                aria-required
                onBlur={formik.handleBlur}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                {optionsSelectZones.map((option) => (
                  <FormControlLabel
                    value={option.value}
                    sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    control={<Radio sx={{ pt: 0.5 }} />}
                    label={option.text}
                    key={option.value}
                  />
                ))}
              </RadioGroup>
              <FormHelperText
                error={Boolean(
                  formik.touched.zoneCount && formik.errors.zoneCount
                )}
                sx={{ ml: 0 }}
              >
                {formik.touched.zoneCount && formik.errors.zoneCount
                  ? t(formik.errors.zoneCount) + formik.values?.zoneCount
                  : t('schedule.settings.day_part.numberZones_help_text')}
              </FormHelperText>
            </FormControl>

            {/* make accordion with the weekdays */}

            {!!formik.values.zoneCount && (
              <div>
                <FormLabel
                  id={`schedule_parts`}
                  sx={{ fontSize: 16, mb: 2, display: 'inline-block' }}
                >
                  {t('schedule.settings.schedule.schedule_parts')}
                </FormLabel>
                <CreateSchedule
                  formik={formik}
                  businessUnitId={businessUnitId}
                  scheduleId={scheduleId}
                  zoneSettings={zoneSettings}
                  loading={dayPartsLoading}
                />
              </div>
            )}
            {/* debug form */}
            {/* <p style={{ width: 280, wordWrap: 'break-word' }}>
            {JSON.stringify({
              c: formik.submitCount,
              v: formik.values,
              e: formik.errors,
              t: formik.touched,
            })}
          </p> */}
          </Stack>
        </Container>
        <Box
          className="timer-settings-footer"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginY: 4,
          }}
        >
          <Box>
            <StyledMaxWidthButton
              variant="contained-gray"
              sx={{ height: '3em' }}
              size="large"
              rounded
              onClick={onClickBack}
              disabled={formik.isSubmitting}
            >
              <ArrowBackIcon height={'1em'} />
            </StyledMaxWidthButton>
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <StyledMaxWidthButton
              onClick={onClearForm}
              size="large"
              rounded
              variant="contained-gray"
            >
              {t('button.clear_all')}
            </StyledMaxWidthButton>
            <StyledMaxWidthButton
              variant="contained"
              type="submit"
              size="large"
              rounded
              disabled={formik.isSubmitting}
            >
              {t('button.save')}
            </StyledMaxWidthButton>
          </Box>
        </Box>
      </form>
    </Container>
  );
}

interface ScheduleDaySelectProps {
  formik: ReturnType<typeof useFormik<typeof clearInitialValues>>;
  businessUnitId: string;
  scheduleId: string;
  weekDay?: WeekDaysISO;
  zoneSettings: ZoneSettingWitIdResponse[];
  loading: boolean;
}

const CreateSchedule = (props: ScheduleDaySelectProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { formik, loading, businessUnitId, scheduleId, zoneSettings } = props;

  const daypart = {
    partId: '',
    startTime: '',
    confirmationType: CONFIRMATION_TYPE.NO_CONFIRMATION_REQUIRED,
  };

  const scheduleObj = {
    selectedDays: [],
    dayParts: [daypart],
  };

  const confirmableFeatureToggle =
    useAppSelector(getFeatureToggle).ScheduleNonConfirmable;

  const onChangeConfirmable =
    (dayPartIndex: number, scheduleIndex: number) => () => {
      const dayPart =
        formik.values.schedules[scheduleIndex].dayParts[dayPartIndex];
      const confirmationType =
        dayPart.confirmationType ===
        CONFIRMATION_TYPE.CLOUD_CONFIRMATION_REQUIRED
          ? CONFIRMATION_TYPE.NO_CONFIRMATION_REQUIRED
          : CONFIRMATION_TYPE.CLOUD_CONFIRMATION_REQUIRED;
      formik.setFieldValue(
        `schedules.${scheduleIndex}.dayParts.${dayPartIndex}.confirmationType`,
        confirmationType
      );
    };

  const onClickAddDayPart = (scheduleIndex: number) => {
    const newDayPart = { ...daypart };
    const newDayParts = [
      ...formik.values.schedules[scheduleIndex].dayParts,
      newDayPart,
    ];

    formik.setFieldValue(`schedules.${scheduleIndex}.dayParts`, newDayParts);
  };

  const onClickDeleteDayPart = (
    scheduleIndex: number,
    dayPartIndex: number
  ) => {
    formik.setFieldValue(
      `schedules.${scheduleIndex}.dayParts`,
      formik.values.schedules[scheduleIndex].dayParts.filter(
        (_, i) => i !== dayPartIndex
      )
    );
  };

  const onClickAddNewSchedule = () => {
    const newSchedule = { ...scheduleObj };
    formik.setValues({
      ...formik.values,
      schedules: [...formik.values.schedules, newSchedule],
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        my: 2,
      }}
    >
      <Box
        sx={{
          '& .MuiFormControl-root': { width: '100%' },
          border: `1px solid ${theme.palette.grey[800]}`,
          borderRadius: '1rem',
          padding: '1rem 2rem',
        }}
      >
        {formik.values.schedules.map((schedule, scheduleIndex) => {
          return (
            <Box key={'schedule' + scheduleIndex}>
              <Typography variant="h6" mt={'2rem'} fontSize={16}>
                {t('schedule.settings.schedule.schedule_part')}{' '}
                {scheduleIndex + 1}
              </Typography>
              <FormControl sx={{ mt: '2rem' }}>
                <FormLabel
                  id={`weekdays`}
                  sx={{ fontSize: 14, mb: '1rem', display: 'inline-block' }}
                >
                  {t('schedule.settings.schedule.apply_on')}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="weekdays_select"
                  name={`weekdays_select`}
                  aria-required
                  onBlur={formik.handleBlur}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  {WEEK_DAYS_KEYS.map((option, i) => (
                    <FormControlLabel
                      value={option}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                      }}
                      aria-required
                      control={
                        <Radio
                          sx={{ pt: 0.5 }}
                          checked={
                            !!schedule.selectedDays.find(
                              (item) => item === option
                            )
                          }
                          onClick={() => {
                            const selectedDays = schedule.selectedDays;
                            const day = selectedDays.find((i) => i === option);
                            if (day && option === day) {
                              formik.setFieldValue(
                                `schedules.${scheduleIndex}.selectedDays`,
                                selectedDays.filter((i) => i !== option)
                              );
                            } else {
                              formik.setFieldValue(
                                `schedules.${scheduleIndex}.selectedDays`,
                                [...selectedDays, option]
                              );
                            }
                          }}
                        />
                      }
                      label={t(
                        `schedule.settings.schedule.weekdays.short.${option}`
                      )}
                      key={option}
                    />
                  ))}
                </RadioGroup>
                {(formik.errors?.schedules?.[scheduleIndex] as any)
                  ?.selectedDays && (
                  <FormHelperText error={true} sx={{ ml: 0, fontSize: '1rem' }}>
                    {t('schedule.settings.schedule.weekdays_empty')}
                  </FormHelperText>
                )}
              </FormControl>

              {schedule.dayParts.map((dayPart, dayPartIndex) => {
                const startTimeString =
                  schedule.dayParts[dayPartIndex].startTime;
                const startTimeMoment = timeToMoment(startTimeString);
                const errorFormDic = formik.errors?.schedules || [];
                const scheduleError = errorFormDic[
                  scheduleIndex
                ] as FormikErrors<{
                  selectedDays: WeekDaysISO[];
                  dayParts: {
                    partId: string;
                    startTime: string;
                    confirmationType: CONFIRMATION_TYPE;
                  }[];
                }>;
                const dayPartError = scheduleError?.dayParts?.[
                  dayPartIndex
                ] as FormikErrors<{
                  partId: string;
                  startTime: string;
                  confirmationType: CONFIRMATION_TYPE;
                }>;
                const errorForm = dayPartError && Boolean(dayPartError);
                const errorValue: string | undefined =
                  dayPartError?.startTime || dayPartError?.partId;

                return (
                  <Box
                    display="flex"
                    key={`${dayPart.partId}__${dayPartIndex}`}
                    sx={{ mt: '1rem' }}
                  >
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                      <SelectDisplayControl
                        businessUnitId={businessUnitId}
                        scheduleId={scheduleId}
                        numberZones={formik.values.zoneCount}
                        zoneSettings={zoneSettings}
                        loading={loading}
                        InputProps={{
                          label: t(`schedule.settings.schedule.dayPart`),
                          placeholder:
                            t(
                              `schedule.settings.schedule.dayPart_placeholder`
                            ) ?? '',
                          error: errorForm,
                          helperText: (errorValue && t(errorValue)) ?? '',
                          required: true,
                          fullWidth: true,
                          sx: {
                            label: {
                              fontSize: 14,
                              mb: '0.8rem',
                              display: 'inline-block',
                            },
                          },
                        }}
                        value={schedule.dayParts[dayPartIndex].partId}
                        onChange={(e: any, v: string) => {
                          formik.setFieldValue(
                            `schedules.${scheduleIndex}.dayParts.${dayPartIndex}.partId`,
                            v
                          );
                        }}
                        onBlur={() => {
                          formik.setFieldTouched(
                            `schedules.${scheduleIndex}.dayParts.${dayPartIndex}.partId`,
                            true
                          );
                        }}
                      />
                    </Box>
                    <Box>
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          required
                          sx={{ fontSize: 14, mb: '1rem' }}
                        >
                          {t('schedule.settings.schedule.startTime')}
                        </FormLabel>
                        <TimerPicker
                          value={startTimeMoment}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `schedules.${scheduleIndex}.dayParts.${dayPartIndex}.startTime`,
                              momentToTime(value)
                            )
                          }
                          InputProps={{
                            required: true,
                            error: !startTimeMoment?.isValid(),
                            label: t('schedule.settings.schedule.startTime'),
                            placeholder: 'hh:mm',
                            sx: {
                              '.MuiInput-input': { width: '80px' },
                              label: { fontSize: 14, mb: '0.8rem' },
                            },
                          }}
                        />
                      </FormControl>
                    </Box>
                    {!!confirmableFeatureToggle && (
                      <Box ml={1}>
                        <FormControl component="fieldset">
                          <FormLabel
                            component="legend"
                            sx={{ fontSize: 14, mb: '0.8rem' }}
                          >
                            {t('schedule.settings.schedule.confirmable')}
                          </FormLabel>
                          {
                            <Checkbox
                              checked={
                                schedule.dayParts[dayPartIndex]
                                  .confirmationType ===
                                CONFIRMATION_TYPE.CLOUD_CONFIRMATION_REQUIRED
                              }
                              indeterminate={
                                schedule.dayParts[dayPartIndex]
                                  .confirmationType ===
                                CONFIRMATION_TYPE.NOT_SPECIFIED
                              }
                              onChange={onChangeConfirmable(
                                dayPartIndex,
                                scheduleIndex
                              )}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  `schedules.${scheduleIndex}.dayParts.${dayPartIndex}.confirmationType`,
                                  true
                                );
                              }}
                              sx={{ borderRadius: '3em' }}
                            />
                          }
                        </FormControl>
                      </Box>
                    )}
                    {schedule.dayParts.length > 1 && (
                      <Box sx={{ mt: '2.3rem' }}>
                        <IconButton
                          onClick={() =>
                            onClickDeleteDayPart(scheduleIndex, dayPartIndex)
                          }
                          sx={{ height: 'fit-content', ml: 1 }}
                        >
                          <TrashIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                );
              })}
              <Button
                variant="text"
                sx={{ alignSelf: 'flex-start' }}
                onClick={() => onClickAddDayPart(scheduleIndex)}
              >
                + {t('schedule.settings.schedule.add_new_day_part')}
              </Button>
            </Box>
          );
        })}
        <Divider sx={{ mb: 2, mt: 2 }} />

        <Button variant="text" onClick={() => onClickAddNewSchedule()}>
          + {t('schedule.settings.schedule.add_new_schedule')}
        </Button>
      </Box>
    </Box>
  );
};

const StyledMaxWidthButton = styled(Button)({
  minWidth: '12rem',
});

export default ScheduleNewPage;
