import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Outlet } from 'react-router-dom';
import useDevicesOperator from '../../../service/store/hooks/useDevicesOperator';

export default function OperatorDeviceOverviewLayout() {
  const { isLoading, isFetching } = useDevicesOperator();

  return (
    <Box
      sx={{
        height: '100%',
        flexGrow: 1,
        overflow: 'auto',
      }}
    >
      <Stack
        direction={'column'}
        minHeight={'100%'}
        padding={'2rem 8rem'}
        spacing={1}
      >
        <Typography
          variant="subtitle2"
          color={'primary'}
          textTransform={'uppercase'}
          textAlign={'center'}
        >
          {isLoading || isFetching ? <CircularProgress size={26} /> : <br />}
        </Typography>

        <Outlet />
      </Stack>
    </Box>
  );
}
