import _ from 'lodash';

/**
 * Cleans an object by:
 * - Removing keys that are empty strings (`""`).
 * - Replacing empty string (`""`) values with `undefined`.
 * - Removing `undefined` values from objects and arrays.
 *
 * @template T
 * @param {T} obj - The input object or array.
 * @returns {T} The cleaned object or array.
 * @example
 * cleanObject({ a: '', b: 'b', c: { d: '', e: 'e' } });
 ```ts
 // Example usage:
const input = {
    "": "should be removed",
    "name": "",
    "details": {
        "": "remove this",
        "age": 25,
        "address": {
            "street": "",
            "city": "Paris"
        }
    },
    "hobbies": ["reading", ""]
};
==>
{
    "details": {
        "age": 25,
        "address": {
            "city": "Paris"
        }
    },
    "hobbies": ["reading"]
}
 ```
 */
export function cleanObject<T>(obj: T): T {
  if (_.isArray(obj)) {
    return _.chain(obj)
      .map(cleanObject)
      .filter((value) => value !== undefined) // Remove undefined values from arrays
      .value() as T;
  } else if (_.isObject(obj) && obj !== null) {
    return _.chain(obj)
      .omitBy((_, key) => key === '') // Remove empty keys
      .mapValues((value) => (value === '' ? undefined : cleanObject(value))) // Replace empty values
      .omitBy(_.isUndefined) // Remove undefined values from objects
      .value() as T;
  }
  return obj;
}
export default cleanObject;
