import WorkspaceAPI from '../WorkspaceAPI';
import _ from 'lodash';

type IFun = typeof WorkspaceAPI.useGetMyWorkspacesQuery;
function useMyWorkspace(
  arg1?: Parameters<IFun>[0],
  arg2?: Parameters<IFun>[1]
) {
  return WorkspaceAPI.useGetMyWorkspacesQuery(arg1, {
    ...arg2,
    selectFromResult: (response) => ({
      ...response,
      data: _.first(response.data),
    }),
  });
}

export default useMyWorkspace;
