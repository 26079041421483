export enum AirConnectNodeTypes {
  // DIXEL
  STAirConnectNode = 1,
  // Shelly
  Another = 2,
}

export interface AirConnectNodeScanned {
  macAddress: string;
  numberOfControllers: number;
  nodeType: AirConnectNodeTypes;
}

export interface AirConnectNode extends AirConnectNodeScanned {
  nodeNumber: number;
  nodeAddress: number;
  nodeReachable: boolean;
  numberOfControllersInstalled: number;
  bitmask: number;
  status?: number;
  nodeHaveRSSI?: boolean;
  rssi?: -57;
}

export interface ControlUnitInstallation {
  /** number address on string, f.e. ["8","16","24","25"] */
  addresses: string[];
  uninstall?: boolean;
  reinstallOnly?: boolean;
  fullSearch?: boolean;
}

export interface CommanderAirConnectCreateComposableDeviceAPI {
  commanderSerialNum: string;
  storeId: string;
  devices: CommanderAirConnectCreateComposableDeviceItemAPI[];
}
export interface CommanderAirConnectCreateComposableDeviceItemAPI {
  mac: string;
  name: string | null;
  serialnumber: string | null;
  unitId?: string | null;
  model: string;
  composableDevice: {
    machineTypeId: string;
    controlUnits: ComposableDeviceControlUnitMap[];
    labels: {
      /** Extra information like
       * zone.1.description: "Zone 1 description"
       */
      [key: string]: string;
    };
  };

  zoneMapping: {
    /** Zone # vs Address, f.e. "1":"8", "2":"9" */
    [key: string]: string;
  };
}

export interface ComposableDeviceControlUnitMap {
  deviceId: string;
  controlUnitId: string;
}
