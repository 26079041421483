import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import TextValue from '../TextValue';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../theme/ui/Atoms/Button';
import Input from '../../../../theme/ui/Atoms/Input';
import useBack from '../../../../shared/util/route-dom/hooks/useBack';
import BackButton from '../BackButton';
import ContainerBox from '../ContainerBox';
import { GroupAPIResponse } from '../../../../service/group/group.model';
import { ScanAirConnectIcon } from '../../../../assets';
import useErrorMessage from '../../../../shared/hooks/useErrorMessage';
import StoreStructureAPI from '../../../../redux/structure/store/StoreStructureAPI';
import useMyWorkspace from '../../../../redux/workspace/hooks/useMyWorkspace';
import AlertMessage from '../AlertMessage';
import GroupAPI from '../../../../service/group/GroupAPI';
import { fromBase64 } from '../../../../shared/util/base64';

const validationSchema = yup.object({
  externalStoreId: yup
    .string()
    .required('initial_setup.search_store.store_id_required'),
});
function SearchStoreSetUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: myWorkspace,
    isLoading: isLoadingMyWorkspace,
    error: errorMyWorkspace,
  } = useMyWorkspace(undefined, { refetchOnMountOrArgChange: true });

  const [triggerGetStore, resultStore] =
    StoreStructureAPI.useLazyGetStoreMetadataByExternalIdQuery();

  const [createGroup, { error: errorCreatingGroup }] =
    GroupAPI.useCreateGroupMutation();
  const [linkStoreToGroup, { error: errorLinkingStoreToGroup }] =
    StoreStructureAPI.useLinkStoreMetadataToAGroupMutation();
  const {
    error: errorStore,
    data: storeMetadata,
    isFetching: isFetchingStore,
    isSuccess: isSuccessStore,
  } = resultStore;

  const address = [
    storeMetadata?.street,
    storeMetadata?.customData?.City,
    storeMetadata?.customData?.State,
    storeMetadata?.zipcode,
    storeMetadata?.country,
  ]
    .filter(Boolean)
    .join(', ');

  const catchEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const errorMitigated =
    // eslint-disable-next-line eqeqeq
    (errorStore as AxiosResponse)?.status == 404 ||
    (isSuccessStore && !storeMetadata)
      ? new Error('initial_setup.search_store.store_not_found')
      : errorStore;

  const error =
    errorMitigated ||
    errorMyWorkspace ||
    errorCreatingGroup ||
    errorLinkingStoreToGroup;

  const formik = useFormik<{
    store: GroupAPIResponse | null;
    storeId: string;
    externalStoreId: string;
  }>({
    initialValues: {
      store: null,
      storeId: '',
      externalStoreId: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await triggerGetStore({
        externalId: values.externalStoreId,
        workspaceId: myWorkspace?.id ?? '',
      })
        .unwrap()

        .then((storeMetadata) => {
          // create group if not found
          const storeId = storeMetadata?.uuid;
          if (!storeId) {
            return createGroup({
              name: storeMetadata?.storeName ?? '',
              parentGroupID: storeMetadata?.regionId,
              attributes: {
                FS_Store_ID: values.externalStoreId,
              },
            })
              .unwrap()
              .then((group) =>
                linkStoreToGroup({
                  externalId: values.externalStoreId,
                  groupId: fromBase64(group.newID),
                  workspaceId: myWorkspace?.id ?? '',
                }).unwrap()
              )
              .then(() => {
                return triggerGetStore({
                  externalId: values.externalStoreId,
                  workspaceId: myWorkspace?.id ?? '',
                }).unwrap();
              })
              .then((storeMetadata) => {
                return storeMetadata;
              });
          }
          return storeMetadata;
        })

        .then((storeMetadata) => {
          formik.setFieldValue('storeId', storeMetadata?.uuid ?? '');
        })
        .catch((error) => showError(error));
    },
  });
  const onClickBack = useBack('/');
  const showError = useErrorMessage();
  const onClickNext = () => {
    navigate(`../store/${storeMetadata?.uuid}/search-commander`);
  };

  return (
    <ContainerBox maxWidth="sm">
      <Typography variant="h1">
        {t('initial_setup.connect_commander.title')}
      </Typography>
      <Grid container spacing={2} my={4}>
        <Grid item xs>
          <Input
            label={t('initial_setup.search_store.store_id_label')}
            fullWidth
            required
            aria-required
            name="externalStoreId"
            value={formik.values.externalStoreId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={catchEnter}
            error={
              (formik.touched.storeId && Boolean(formik.errors.storeId)) ||
              Boolean(errorMitigated)
            }
            helperText={
              (formik.touched.storeId &&
                formik.errors.storeId &&
                t(formik.errors.storeId)) ||
              ''
            }
          />
        </Grid>

        <Grid item width={'150px'} mt={3}>
          <Button
            variant="contained"
            color="primary"
            rounded
            fullWidth
            onClick={() => formik.handleSubmit()}
            loading={isFetchingStore || formik.isSubmitting}
          >
            <ScanAirConnectIcon height={'2em'} />
          </Button>
        </Grid>
      </Grid>

      <Divider />
      <Box mt={2} minHeight={'200px'} my={4}>
        {!!error && <AlertMessage error={error} />}
        {!!storeMetadata && (
          <>
            <Grid container spacing={2} mt={1}>
              <TextValue
                label={t('initial_setup.store.name') + ':'}
                value={storeMetadata?.storeName}
              />

              <TextValue
                label={t('initial_setup.store.bu') + ':'}
                value={storeMetadata?.customData?.['Business Unit']}
              />
              <TextValue
                label={t('initial_setup.store.address') + ':'}
                value={address}
              />
              <TextValue
                label={t('initial_setup.store.email') + ':'}
                value={storeMetadata?.customData?.['Store e-mail']}
              />

              <TextValue
                label={t('initial_setup.store.connect_email') + ':'}
                value={storeMetadata?.customData?.['Operator log-in name']}
              />
            </Grid>
          </>
        )}
      </Box>

      <Grid container spacing={2} justifyContent={'space-between'}>
        <Grid item xs={12} sm={4}>
          <BackButton onClick={onClickBack} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            rounded
            fullWidth
            loading={
              isLoadingMyWorkspace || isFetchingStore || formik.isSubmitting
            }
            disabled={!!errorStore || !storeMetadata}
            onClick={onClickNext}
          >
            {t('button.next')}
          </Button>
        </Grid>
      </Grid>
    </ContainerBox>
  );
}

export default SearchStoreSetUp;
