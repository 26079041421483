import { UseQuerySubscription } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import FlexeserveDeviceAPI from '../FlexeserveDevice/FlexeserveDeviceAPI';

type Options = Parameters<UseQuerySubscription<any>>[1];
interface Params {
  storeId: string;
  deviceId: string;
  options?: Options;
}
function useGetDeviceFromStoreByParam(params: Params) {
  const { storeId, deviceId, options } = params;
  const responseDevicesAPI = FlexeserveDeviceAPI.useGetStoreDevicesQuery(
    storeId,
    options
  );

  const { data: devicesAPI, ...rest } = responseDevicesAPI;

  return {
    ...rest,
    data: devicesAPI?.find((device) => device.uuid === deviceId),
  };
}

export default useGetDeviceFromStoreByParam;
