import { UseQuerySubscription } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import StructureAPI from '../../../../redux/structure/StructureAPI';
import { CommanderAPI } from '../CommanderAPI';
import _ from 'lodash';
import orComparator from '../../../../shared/util/lodash/merge/orComparator';
import { useAppSelector } from '../../../../redux/store.model';
import { useMemo } from 'react';
import { getCommanderIdsFlexeserveDevicesSelector } from '../../../../redux/structure/selectors/ComposableDevice.selector';
import { isNotNullOrUndefined } from '../../../../shared/util/collection/isNotNullOrUndefined';

type Options = Parameters<UseQuerySubscription<any>>[1];

function useDeviceStatusFromGroup(groupId: string, options: Options = {}) {
  const { data: dataStructure, ...restStructure } =
    StructureAPI.useGetStructureV2Query(undefined, options);

  const groupIdParam = useMemo(() => ({ groupId }), [groupId]);
  const commandersIds = dataStructure?.commanderIds;
  const commanderIdsDevices = useAppSelector((state) =>
    getCommanderIdsFlexeserveDevicesSelector(state, groupIdParam)
  );

  const skip = !commandersIds?.length || options.skip;
  const { data: devicesStatus, ...restDeviceStatus } =
    CommanderAPI.useGetDevicesStatusQuery(commandersIds ?? [], {
      ...options,
      skip,
    });

  const fallingCommandersIds = useMemo(
    () =>
      devicesStatus &&
      commanderIdsDevices &&
      commanderIdsDevices
        .map((encodedGroupId) =>
          devicesStatus.entities[encodedGroupId]?.mqttConnected === false
            ? encodedGroupId
            : undefined
        )
        .filter(isNotNullOrUndefined),

    [devicesStatus, commanderIdsDevices]
  );

  return {
    ..._.mergeWith(restStructure, restDeviceStatus, orComparator),
    data: devicesStatus,
    restDeviceStatus: restDeviceStatus,
    numberFailingMqttConnection: fallingCommandersIds?.length,
    commanderIdsDevices,
  };
}

export default useDeviceStatusFromGroup;
