import { NormalizedGroup } from '../GroupsAdapter';

function getPathOfGroups(
  groups:
    | {
        [key: string]: NormalizedGroup;
      }
    | undefined,
  groupId: string
) {
  const path = [];
  let currentGroup = groups?.[groupId];
  while (currentGroup) {
    path.unshift(currentGroup);
    currentGroup = groups?.[currentGroup.parentGroupId ?? ''];
  }
  return path;
}

export default getPathOfGroups;
