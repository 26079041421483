import React, { useMemo } from 'react';
import { ComposableDevicesManagerStoreInfoResponse } from '../../../../service/device/hooks/useDevicesManager';
import useMachineTypes from '../../../../redux/workspace/hooks/useMachineTypes';
import useWatchError from '../../../../shared/hooks/useWatchError';
import { Skeleton } from '@mui/material';
import { useAppSelector } from '../../../../redux/store.model';
import { getGroupFromGroupIdSelector } from '../../../../redux/structure/selectors/Group.selector';
import { toBase64 } from '../../../../shared/util/base64';
interface DeviceTypeProps {
  device: ComposableDevicesManagerStoreInfoResponse;
}
function DeviceType(props: DeviceTypeProps) {
  const { device } = props;
  const {
    data: machineTypes,
    isLoading: isLoadingMachineTypes,
    error: errorMachineTypes,
  } = useMachineTypes();
  useWatchError(errorMachineTypes);

  const group = useAppSelector((state) =>
    getGroupFromGroupIdSelector(state, {
      groupId: toBase64(device.composableDeviceId ?? ''),
    })
  );

  const machineTypeId =
    device.composableDevice?.machineTypeId ??
    group?.composableDevice.machineTypeId;

  const machineTypeName = useMemo(
    () =>
      machineTypes?.find((machineType) => machineType.id === machineTypeId)
        ?.name,
    [machineTypes, machineTypeId]
  );

  const value = machineTypeName || device.model;
  return (
    <span>
      {isLoadingMachineTypes ? <Skeleton variant="text" width={50} /> : value}
    </span>
  );
}

export default DeviceType;
