import { fromBase64 } from '../../../shared/util/base64';
import { GroupAPIResponse } from '../../group/group.model';

export default function getRegionsFromGroups(groups: GroupAPIResponse[]) {
  return groups.map((group) => {
    return {
      groupId: fromBase64(group.id),
      name: group.name,
    };
  });
}
