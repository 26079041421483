import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ZoneSelect, {
  ZoneSelectProps,
} from '../../../theme/ui/Atoms/ZoneSelect/ZoneSelect';
import { ZoneResponse } from '../../../service/store/modules/zone/ZoneService.model';
import { ComposableDevicesStoreInfoResponse } from '../../../service/store/hooks/useDevicesOperator';
import useErrorMessage from '../../../shared/hooks/useErrorMessage';
import useMessage from '../../../shared/hooks/useMessage';
import { DevicesStoreInfoResponse } from '../../../service/store/StoreService.model';
import ZoneSettingsAPI from '../../../service/zoneSettings/ZoneSettingsAPI';
import useWatchError from '../../../shared/hooks/useWatchError';
import { arrayToRecord } from '../../../shared/ArrayUtil';
import { ZoneSettingWitIdResponse } from '../../../service/zoneSettings/ZoneSettingsAPI.model';
import produce from 'immer';
import getZone from '../../../service/store/hooks/useZoneDevice';
import ZoneManagerOperationAPI from '../../../service/zoneSettings/ZoneManagerOperation/ZoneManagerOperationAPI';
import { TEMPERATURE_UNIT_TYPES } from '../../../service/temperaturePreference';
import { ComposableDevicesManagerStoreInfoResponse } from '../../../service/device/hooks/useDevicesManager';
import { useGetZoneTestOptions } from './useGetZoneTestOptions';

export const defaultOptions: ZoneSettingWitIdResponse[] = [
  {
    id: 'OFF',
    canDelete: false,
    canEditName: false,
    canEditSetpoint: false,
    displayName: 'Off',
    setpoint: 0,
    visible: true,
    temperatureUnit: TEMPERATURE_UNIT_TYPES.CELSIUS,
  },
  {
    id: 'LIGHTS_ONLY',
    canDelete: false,
    canEditName: false,
    canEditSetpoint: false,
    displayName: 'Lights only',
    setpoint: 0,
    temperatureUnit: TEMPERATURE_UNIT_TYPES.CELSIUS,
    visible: true,
  },
];
interface ZoneManagerConnectedProps
  extends Omit<ZoneSelectProps, 'zone' | 'options'> {
  item: {
    zone: ZoneResponse | undefined;
    device:
      | ComposableDevicesStoreInfoResponse
      | ComposableDevicesManagerStoreInfoResponse;
  };
  zoneIndex: number;
  loadingState: boolean;
}
function ZoneManagerConnected(props: ZoneManagerConnectedProps) {
  const {
    item: { zone, device },
    zoneIndex,
    loadingState,
    ...otherProps
  } = props;

  const errorMessage = useErrorMessage();
  const showMessage = useMessage();
  const { t } = useTranslation();

  const [setZoneAPI, { isLoading: setZoneLoading, originalArgs }] =
    ZoneManagerOperationAPI.useSetZoneSettingsMutation();

  const onChangeZone = (newSetting: string) => {
    setZoneAPI({
      deviceId: device.composableDevice
        ? device.commanderId
        : device.uuid ?? '',
      zoneIndex: `${zoneIndex}` as keyof DevicesStoreInfoResponse['zones'],
      newSetting,
    })
      .unwrap()
      .then(() => showMessage(t('timers.zone_set_success')))
      .catch((error) =>
        errorMessage(
          `${t('timers.zone_set_error')} ${t(
            typeof error === 'string' ? error : error.message
          )}`
        )
      );
  };
  const {
    data: zoneSettings,
    error: zoneSettingsError,
    isFetching: isFetchingZoneSettings,
  } = ZoneSettingsAPI.useGetZoneSettingsQuery(device.commanderId || '', {
    refetchOnMountOrArgChange: true,
    selectFromResult: (result) => ({
      ...result,
      data:
        result.data &&
        (arrayToRecord([...defaultOptions, ...result.data], 'id') as Record<
          string,
          ZoneSettingWitIdResponse
        >),
    }),
  });

  // implement optimist updates
  const zonePatch = useMemo(() => {
    if (!originalArgs || !(setZoneLoading || loadingState) || !zone)
      return zone;
    return produce(zone, (draft) => {
      const { newSetting } = originalArgs;
      const newSettingObj = zoneSettings?.[newSetting];
      draft.activeSetting = newSetting;
      if (newSettingObj) {
        draft.shelfStatus.temperatureSetpoint = newSettingObj.setpoint;
        if (newSetting !== 'OFF') draft.shelfStatus.deviceOn = true;
      }
    });
  }, [originalArgs, setZoneLoading, zone, zoneSettings, loadingState]);

  const options = useGetZoneTestOptions(zoneSettings, zoneIndex, device);
  const position = getZone(device);

  const loading = isFetchingZoneSettings || setZoneLoading;
  const error = zoneSettingsError;

  useWatchError(error);
  return (
    <ZoneSelect
      {...otherProps}
      zone={zonePatch}
      position={position}
      key={`${device.uuid}-${zoneIndex}`}
      disconnected={!device?.deviceState?.mqttConnected}
      alert={false}
      loading={loading}
      options={options}
      onChange={(event: any, newSetting: string) => {
        onChangeZone(newSetting);
      }}
    />
  );
}

export default ZoneManagerConnected;
