import { Store } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store.model';
import { fetchDeviceControlUnits } from './asyncThunks';
import { getDeviceControlUnitsLoadingById } from '../selectors';
import { toBase64 } from '../../../shared/util/base64';

/**
 * If not are loading fetch the control units of the device
 * @param deviceId
 * @returns
 */
export const fetchDeviceControlUnitsThunk =
  (deviceId: string, commanderId: string) =>
  async (dispatch: AppDispatch, getState: Store['getState']) => {
    const loading = getDeviceControlUnitsLoadingById(getState(), { deviceId });
    if (!loading)
      return dispatch(
        fetchDeviceControlUnits({
          deviceId,
          commanderId: toBase64(commanderId),
        })
      ).unwrap();
  };
