import { LoadingButton } from '@mui/lab';
import useAlarmsByGroup from '../../../../../../redux/alarm/hooks/useAlarmsByGroup';
import { EntityActionsProps } from './EntityActions';
import { Stack, Typography } from '@mui/material';
import { ErrorIcon } from '../../../../../../assets';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { fromBase64 } from '../../../../../../shared/util/base64';

export function EntityAlarmsAction(props: EntityActionsProps) {
  const { entity, active } = props;
  const groupId = fromBase64(entity?.id ?? '');

  const { data: alarmsData, isLoading: isLoadingAlarmsData } = useAlarmsByGroup(
    { groupId }
  );
  const [searchBarParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchBarParams.entries());
  if (alarmsData?.length === 0 && !isLoadingAlarmsData) {
    return null;
  }
  return (
    <LoadingButton
      variant={active ? 'contained' : 'outlined'}
      color={active ? 'primary' : 'inherit'}
      loading={isLoadingAlarmsData}
      href={`/manager/view-locations/${entity?.uuid}?${createSearchParams({
        ...searchParamsObject,
        alarmsActive: !active + '',
      }).toString()}`}
      sx={{
        fontSize: '1rem',
        width: '6rem',
        height: '3rem',
        color: active ? 'primary' : 'text.primary',
      }}
    >
      <Stack direction={'row'} spacing={0.5}>
        <Typography
          component="span"
          variant={'inherit'}
          color={!active ? 'error' : 'inherit'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <ErrorIcon height={'1.5em'} />
        </Typography>
        <Typography
          component="span"
          variant={'inherit'}
          fontSize={'1rem'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          |
        </Typography>
        <Typography
          component="span"
          variant={'inherit'}
          display={'flex'}
          alignItems={'center'}
          fontSize={'1rem'}
          justifyContent={'center'}
        >
          {alarmsData?.length}
        </Typography>
      </Stack>
    </LoadingButton>
  );
}
