import { UseQuerySubscription } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useGetGroupByIdQuery } from '../../group/GroupAPI';
import { toBase64 } from '../../../shared/util/base64';
import { ComposableDevice } from '../../group/group.model';
import _ from 'lodash';
import { DevicesStoreInfoResponse } from '../../store/StoreService.model';
import orComparator from '../../../shared/util/lodash/merge/orComparator';
import { useMemo } from 'react';
import FlexeserveDeviceAPI from '../FlexeserveDevice/FlexeserveDeviceAPI';

type Options = Parameters<UseQuerySubscription<any>>[1];
export type ComposableDevicesManagerStoreInfoResponse = {
  composableDevice?: ComposableDevice;
} & DevicesStoreInfoResponse;
function useDevicesManager(storeId: string, options: Options = {}) {
  const responseDevicesAPI = FlexeserveDeviceAPI.useGetStoreDevicesQuery(
    storeId,
    options
  );

  const { data: devicesAPI, ...restDevicesAPI } = responseDevicesAPI;

  const responseGroupAPI = useGetGroupByIdQuery(storeId, options);
  const { data: storeGroup, ...restGroupAPI } = responseGroupAPI;

  const groupChildren = useMemo(
    () => storeGroup?.childGroups ?? [],
    [storeGroup]
  );
  const data: ComposableDevicesManagerStoreInfoResponse[] = useMemo(
    () =>
      devicesAPI
        ?.map((device) => {
          const composableDeviceGroup = groupChildren.find(
            (group) => group.id === toBase64(device.composableDeviceId || '')
          );
          return {
            ...device,
            composableDevice: composableDeviceGroup?.composableDevice,
          };
        })
        .sort((a, b) =>
          a.unitName.localeCompare(b.unitName, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        ) ?? [],
    [devicesAPI, groupChildren]
  );

  return {
    ..._.mergeWith(restDevicesAPI, restGroupAPI, orComparator),
    data,
  };
}

export default useDevicesManager;
