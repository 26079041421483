import { createApi } from '@reduxjs/toolkit/query/react';
import { GroupAPIResponse } from '../group/group.model';
import {
  DevicesStoreInfoResponse,
  StoreGroupResponse,
  StoreInfoInRegionResponse,
} from '../store/StoreService.model';
import axiosBaseQuery from '../shared/axiosBaseQuery';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { Region, SetScheduleToADeviceResponseAPI } from './Location.model';
import { REHYDRATE } from 'redux-persist';
import { toBase64 } from '../../shared/util/base64';
import getRegionsFromGroups from './serialize/getRegionsFromGroups';
import { TEMPERATURE_UNIT_TYPES } from '../temperaturePreference';
import { M_SECONDS_KEEP_UNUSED_DATA_FOR_MAX_CACHE } from '../store/Store.constants';
import { ComposableDevicesStoreInfoResponse } from '../store/hooks/useDevicesOperator';

const LocationAPI = createApi({
  keepUnusedDataFor: M_SECONDS_KEEP_UNUSED_DATA_FOR_MAX_CACHE,
  reducerPath: 'location',
  tagTypes: [
    'locations-actions',
    'group-actions',
    'device-actions',
    'zone-actions',
    'commander-nodes-actions',
    'commander-update-actions',
  ],
  baseQuery: axiosBaseQuery(),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints(build) {
    return {
      getLocations: build.query<
        {
          devices: ComposableDevicesStoreInfoResponse[];
          regions: Region[];
        },
        void
      >({
        async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
          // get group three
          const {
            error: errorGroup,
            data: dataGroups,
            meta: metaGroups,
          } = await fetchWithBQ({ url: 'groups/' });
          const dataGroupsT = dataGroups as GroupAPIResponse[];
          if (!dataGroups) return { error: errorGroup, meta: metaGroups };

          // Get devices from stores
          const stores = dataGroupsT
            .map((group) => group.childGroups ?? [])
            .flat();

          const storesIds = stores.map((store) => store.id);
          const devicesResponse: {
            device: DevicesStoreInfoResponse[];
            error: unknown;
          }[] = await Promise.all(
            storesIds.map((id) =>
              Promise.all([
                fetchWithBQ({
                  url: `flexeserve/group/${id}/deviceinfo`,
                }) as QueryReturnValue<DevicesStoreInfoResponse[]>,
              ]).then((responses) => {
                return {
                  device: responses[0].data ?? [],
                  error: responses[0].error,
                };
              })
            )
          );
          const deviceInformation = devicesResponse
            .map((res) => res.device)
            .flat();
          const deviceError = devicesResponse.map((res) => res.error);

          const devices = deviceInformation;
          const regions = getRegionsFromGroups(dataGroupsT);
          const regionsWithDevices = regions.map((region) => ({
            ...region,
            devices: devices.map((device) => device.uuid),
          }));

          if (!devices.length) {
            const devicesError = deviceError.find((res) => res);
            if (devicesError) return { error: devicesError };
          }
          return {
            data: { devices, regions: regionsWithDevices },
            meta: metaGroups,
          };
        },
        providesTags: ['locations-actions'],
      }),
      setScheduleToADevice: build.mutation<
        SetScheduleToADeviceResponseAPI,
        {
          deviceId: string;
          scheduleId: string;
        }
      >({
        query: ({ deviceId, scheduleId }) => {
          const encodedDeviceId = toBase64(deviceId);
          return {
            url: `flexeserve/device/${encodedDeviceId}/schedule`,
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            data: scheduleId,
            // Need a string plain, not a json
            transformRequest: (data) => data,
          };
        },
        invalidatesTags: ['locations-actions'],
      }),
      initialSetup: build.mutation<
        any,
        {
          storeEncodedId: string;
          cloudId: string;
          unitName: string;
          serialnumber: string;
          model: string;
          unitId: string;
        }
      >({
        query: ({ storeEncodedId, cloudId, ...otherData }) => {
          const encodedCloudId = toBase64(cloudId);

          return {
            url: `flexeserve/register/${storeEncodedId}/cloudConnectID/${encodedCloudId}`,
            method: 'post',
            data: otherData,
          };
        },
        invalidatesTags: [
          'locations-actions',
          'group-actions',
          'device-actions',
        ],
      }),
      registerCommanderStore: build.mutation<
        any,
        {
          storeId: string;
          serialNumber: string;
          data: {
            unitName?: string;
            model?: string;
            serialnumber?: string;
            unitId?: string;
            mac?: string;
          };
        }
      >({
        query: ({ storeId, serialNumber }) => {
          return {
            url: `flexeserve/register/store/${toBase64(
              storeId
            )}/serialnumber/${toBase64(serialNumber)}`,
            method: 'post',
            data: {},
          };
        },
        invalidatesTags: [
          'locations-actions',
          'group-actions',
          'device-actions',
        ],
      }),
      getTemperaturePreferenceByGroup: build.query<
        TEMPERATURE_UNIT_TYPES | undefined,
        string
      >({
        query: (groupId) => {
          const encodedGroupId = toBase64(groupId);
          return {
            url: `/flexeserve/group/${encodedGroupId}`,
            method: 'get',
          };
        },
        transformResponse: (res: StoreInfoInRegionResponse) =>
          res.preferredUnit,
        providesTags: ['group-actions'],
      }),

      getStoreById: build.query<StoreGroupResponse | undefined, string>({
        query: (storeId) => {
          const encodedStoreId = toBase64(storeId);
          return {
            url: `/flexeserve/group/${encodedStoreId}`,
            method: 'get',
          };
        },
        providesTags: ['group-actions'],
      }),

      setTemperaturePreferenceByGroup: build.mutation<
        void,
        {
          groupId: string;
          temperaturePreference: TEMPERATURE_UNIT_TYPES;
          recursive?: boolean;
        }
      >({
        query: ({ groupId, temperaturePreference, recursive }) => {
          const encodedGroupId = toBase64(groupId);
          return {
            url: `flexeserve/group/${encodedGroupId}/unit?recursive=${recursive}`,
            method: 'put',
            data: temperaturePreference,
            headers: {
              'Content-Type': 'application/json',
            },
            transformRequest: (res) => {
              // if you send a simple string doesn't works because is parsed as json string with "
              // so it change the default transformer
              return res;
            },
          };
        },
        invalidatesTags: ['group-actions'],
      }),
    };
  },
});

export default LocationAPI;

export const { useGetLocationsQuery } = LocationAPI;
