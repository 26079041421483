import React, { Suspense } from 'react';
import { NormalizedGroup } from '../../../../../../redux/structure/GroupsAdapter';
import { Box, Stack } from '@mui/material';
import { EntityAlarmsAction } from './EntityAlarmsAction';
import EntityCommanderStatusAction from './EntityCommanderStatusAction';
import { EntitySchedulesAction } from './EntitySchedulesAction';
import {
  BusinessLayerGroupType,
  getTypeFromGroup,
} from '../../../../../../redux/structure/util/getTypeFromGroup';

export interface EntityActionsProps {
  entity?: NormalizedGroup;
  // usually on header, but on items is not needed
  active?: boolean;
}

function EntityActions(props: EntityActionsProps) {
  const { entity } = props;
  const type = entity && getTypeFromGroup(entity);

  return (
    <Stack direction={'row'} spacing={1}>
      <Suspense fallback={null}>
        <EntityCommanderStatusAction {...props} />
      </Suspense>
      <Box
        sx={{
          width: '6rem',
          height: '3rem',
        }}
      >
        <EntityAlarmsAction {...props} />
      </Box>
      {type === BusinessLayerGroupType.Store && (
        <EntitySchedulesAction {...props} />
      )}
    </Stack>
  );
}

export default EntityActions;
