import { ZoneSettingsResponse } from '../ZoneService.model';
import { deviceAcceptsTemperature } from '../../../../schedule/selectors/filterValidSchedulesSelector';
import { ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isAuxiliaryDevice } from '../../../hooks/useZoneDevice';
import { ComposableDevicesStoreInfoResponse } from '../../../hooks/useDevicesOperator';
import { auxiliaryOptions } from '../../../../zoneSettings/serialize/auxiliaryOptions';
import { ComposableDevicesManagerStoreInfoResponse } from '../../../../device/hooks/useDevicesManager';

/**
 * from zoneSettings from device get a list of options for select
 * @param device
 * @return zoneOptions
 */
export function useGetZoneOptions(
  zoneSettings: ZoneSettingsResponse['configured'] | undefined,
  zoneIndex: number,
  device:
    | ComposableDevicesStoreInfoResponse
    | ComposableDevicesManagerStoreInfoResponse
): {
  value: string;
  text: string | JSX.Element;
  setpoint?: number;
  disabled?: boolean;
  label?: string;
}[] {
  const { t } = useTranslation();

  if (isAuxiliaryDevice(device)) {
    return auxiliaryOptions;
  }

  if (zoneSettings) {
    return Object.keys(zoneSettings)
      .filter((key) => zoneSettings[key]?.visible)
      .map((key) => {
        const zoneSetting = zoneSettings[key];
        const setpoint = zoneSetting?.setpoint;
        const disabled = !deviceAcceptsTemperature(
          device,
          zoneIndex.toString(),
          setpoint
        );

        const primary = zoneSetting?.displayName || key;
        const text = disabled ? (
          <ListItemText
            primary={primary}
            secondary={t('schedule.temperatureSetpointLimits.error_short')}
            secondaryTypographyProps={{
              color: 'inherit',
            }}
          />
        ) : (
          primary
        );

        return {
          value: key,
          text,
          disabled,
          label: primary,
        };
      });
  }
  return [];
}
