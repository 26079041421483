import {
  alpha,
  Box,
  Breadcrumbs,
  Link,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../../InstallFacilities/BackButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import _ from 'lodash';
import { NormalizedGroup } from '../../../../../../redux/structure/GroupsAdapter';
import SubHeaderEntityActions from './SubHeaderEntityActions/SubHeaderEntityActions';
interface SubHeaderLocationsProps {
  entity?: NormalizedGroup;
  pathGroups: NormalizedGroup[];
  loading?: boolean;
  type: string;
}
export function SubHeaderLocations(props: SubHeaderLocationsProps) {
  const { entity, type, pathGroups, loading } = props;
  const { t } = useTranslation();
  const lastGroup = _.nth(pathGroups, -2);
  const urlGoBack = lastGroup
    ? `/manager/view-locations/${lastGroup?.uuid}`
    : '/manager/view-locations';

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/manager/view-locations"
    >
      {t('device.menu.overview')}
    </Link>,
    pathGroups.map((group, i) =>
      i === pathGroups.length - 1 ? (
        <Typography key={group.id} sx={{ color: 'text.primary' }}>
          {group.name}
        </Typography>
      ) : (
        <Link
          underline="hover"
          key={group.id}
          color="inherit"
          href={`/manager/view-locations/${group.uuid}`}
        >
          {group.name}
        </Link>
      )
    ),
  ];

  return (
    <StyledSubHeader>
      <Stack
        px={2}
        py={1}
        alignItems={'center'}
        spacing={4}
        direction={'row'}
        flex={'1 1 auto'}
      >
        <BackButton sx={{ width: '6rem' }} loading={loading} href={urlGoBack} />
        {loading ? (
          <Skeleton variant="text" width={200} />
        ) : (
          <Typography variant="body1">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Typography>
        )}
      </Stack>
      <Box>
        <SubHeaderEntityActions entity={entity} type={type} />
      </Box>
    </StyledSubHeader>
  );
}

const StyledSubHeader = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.4),
  width: '100%',
  padding: theme.spacing(1),
  color: theme.palette.primary.contrastText,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
