/**
 * Use Storage to avoid refreshToken invalid and token expiration when the app is used in different tabs
 */
import { IAuthInfo } from '../../../service/auth/AuthService.model';
import Environment from '../../../shared/constants/Environment';
import { getFeatureToggle } from '../../../shared/featureToggle';
import Storage from '../../../shared/Storage';
import { getManagerAuthTokenSelector } from '../../auth-manager/selectors';
import { RootState } from '../../store.model';

export const isAuthenticatedSelector = () =>
  !!getManagerAuthTokenSelector() || !!getAuthTokenSelector();

export const getTypeUserSelector = (
  state: RootState
): 'operator' | 'manager' | 'guest' => {
  if (getAuthTokenSelector()) return 'operator';
  if (getManagerAuthTokenSelector()) return 'manager';
  return 'guest';
};
export const getAuthRefreshTokenSelector = () =>
  Storage.getItem<IAuthInfo>(Environment.authInfoKey)?.refreshToken;

export const isInstallFacilitiesSelector = (store: RootState) =>
  getFeatureToggle(store).MainApplication === 'LoginInstallFacilities';

export const getAuthTokenSelector = () =>
  Storage.getItem<IAuthInfo>(Environment.authInfoKey)?.token;
