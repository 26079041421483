import { Box, CircularProgress, Container } from '@mui/material';
import React from 'react';
import TitleIcon from '../../../../../../theme/ui/Atoms/TilteIcon/TitleIcon';
import { ViewLocationIcon } from '../../../../../../assets';
import useTypeEntity from '../useTypeEntity';
import { useTranslation } from 'react-i18next';
import { SubHeaderLocations } from './SubHeaderLocations';
import { SearchBar } from '../../../../../../theme/ui/Atoms/SearchBar/SearchBar';
import { NormalizedGroup } from '../../../../../../redux/structure/GroupsAdapter';
import { useNavigate } from 'react-router-dom';

interface LocationsLayoutProps {
  children: React.ReactNode;
  innerContainerMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  role: string;
}
function LocationsLayout(props: LocationsLayoutProps) {
  const {
    children,
    innerContainerMaxWidth = 'lg',
    role = 'businessUnits',
  } = props;
  const { entity, type, isLoading, groupId, pathGroups } = useTypeEntity();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onSearch = (item: NormalizedGroup) => {
    navigate(`/manager/view-locations/${item.uuid}`);
  };
  return (
    <Box>
      {groupId && (
        <SubHeaderLocations
          entity={entity}
          type={type}
          loading={isLoading}
          pathGroups={pathGroups}
        />
      )}
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            my: 4,
          }}
        >
          <Box flex={'2 1 auto'}>
            {!groupId && (
              <TitleIcon
                icon={
                  isLoading ? (
                    <CircularProgress size={'2em'} />
                  ) : (
                    <ViewLocationIcon height={'2em'} />
                  )
                }
              >
                {t('view_locations.view_all')} {t(`view_locations.${role}`)}
              </TitleIcon>
            )}
          </Box>
          <Box flex={'1 1 auto'}>
            <SearchBar selectItem={onSearch}>
              <SearchBar.BUSearch />
              <SearchBar.RegionSearch />
              <SearchBar.StoreSearch />
            </SearchBar>
          </Box>
        </Box>

        <Container
          maxWidth={innerContainerMaxWidth}
          sx={{ mt: 4, overflow: 'auto' }}
        >
          {children}
        </Container>
      </Container>
    </Box>
  );
}

export default LocationsLayout;
