import {
  alpha,
  lighten,
  LinkProps,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
} from '@mui/material';
import React from 'react';
import { NormalizedGroup } from '../../../../../redux/structure/GroupsAdapter';
import EntityActions from './EntityActions/EntityActions';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAllStoreChildIdsFromGroupSelector } from '../../../../../redux/structure/selectors/Stores.selector';
import { useTranslation } from 'react-i18next';
import { BusinessLayerGroupType } from '../../../../../redux/structure/util/getTypeFromGroup';

interface StructureListItemProps {
  entity?: NormalizedGroup;
}
function StructureListItem(props: StructureListItemProps) {
  const { t } = useTranslation();
  const { entity } = props;
  const storesIds = useAppSelector((state) =>
    getAllStoreChildIdsFromGroupSelector(state, { groupId: entity?.uuid ?? '' })
  );
  const secondaryText =
    entity?.businessLayerType !== BusinessLayerGroupType.Store
      ? t('view_locations.store_inside', {
          count: storesIds?.length,
        })
      : undefined;
  return (
    <>
      <ListItem disablePadding secondaryAction={<EntityActions {...props} />}>
        <StyledListItemButton href={`../view-locations/${entity?.uuid}`}>
          <ListItemText sx={{ ml: 2 }} secondary={secondaryText}>
            {entity?.name}
          </ListItemText>
        </StyledListItemButton>
      </ListItem>
    </>
  );
}

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'error',
})<LinkProps & { error?: boolean }>(({ theme, error }) => ({
  backgroundColor: error
    ? alpha(theme.palette.primary.main, 0.4)
    : lighten(theme.palette.background.paper, 0.1),
  borderLeft: `4px solid ${
    error ? theme.palette.primary.main : theme.palette.text.primary
  }`,

  color: 'white',
  my: 0.5,
  display: 'flex',
  justifyContent: 'space-between',
}));

export default StructureListItem;
