import { createSelector } from '@reduxjs/toolkit';
import ZoneSettingsAPI from '../../zoneSettings/ZoneSettingsAPI';
import ScheduleAPI from '../ScheduleAPI';
import { getDevicesByRegionSelector } from '../../locations/selectors/getDevicesByRegionSelector';
import getOptionsFromZoneSettingsWithDefaults from '../../zoneSettings/serialize/getOptionsFromZoneSettingsWithDefaults';
import { getReduceRestrictionFromDevices } from '../../locations/selectors/getDeviceTemperatureRestrictionSelector';
import { RootState } from '../../../redux/store.model';
import { auxiliaryOptions } from '../../zoneSettings/serialize/auxiliaryOptions';
import { acceptsTemperatureLimits } from './filterValidSchedulesSelector';

const getDevicesRestrictionMapOnZoneIdByDayPartSelector = createSelector(
  [
    (state, { groupId }: { groupId: string }) =>
      ScheduleAPI.endpoints.getSchedulesByGroup.select(groupId)(state),

    getDevicesByRegionSelector,
    (state, { dayPartId }: { dayPartId?: string }) => dayPartId,
  ],
  (schedulesData, devices, dayPartId) => {
    if (!dayPartId) return {};

    // get devices that has a schedule, this schedule also has the day part with dayPartId
    const devicesFiltered = devices.filter((device) => {
      const schedule = schedulesData.data?.find(
        (schedule) => schedule.scheduleId === device.schedule
      );
      return (
        schedule &&
        Object.values(schedule.schedule).find((dayParts) =>
          dayParts?.find((dayPart) => dayPart?.partId === dayPartId)
        )
      );
    });

    // reduce temperature restrictions by zoneId
    // { [zoneId]: { min: number, max: number } }
    const zonesRestrictionReduced =
      getReduceRestrictionFromDevices(devicesFiltered);

    return zonesRestrictionReduced;
  }
);

const getValidZoneSettingsOptionsByZoneIdSelector = createSelector(
  [
    (state, { groupId }: { groupId: string }) =>
      ZoneSettingsAPI.endpoints.getZoneSettingsByGroup.select(groupId)(state),
    getDevicesRestrictionMapOnZoneIdByDayPartSelector,
    (state: RootState, { zoneId }: { zoneId: string }) => zoneId,
  ],
  (zoneSettingsData, restrictionByZone, zoneId) => {
    const zoneSettings = zoneSettingsData.data ?? [];
    const options = getOptionsFromZoneSettingsWithDefaults(zoneSettings);

    const optionsMapped = [...options, ...auxiliaryOptions].map((option, i) => {
      const restriction = restrictionByZone[zoneId];
      const disabled = !acceptsTemperatureLimits(restriction, option.setpoint);
      return {
        value: option.value,
        text: option.text,
        label: option.text,
        disabled,
      };
    });
    return optionsMapped;
  }
);

export default getValidZoneSettingsOptionsByZoneIdSelector;
