import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocationAPI from '../../../../../../service/locations/LocationAPI';
import useWatchError from '../../../../../../shared/hooks/useWatchError';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../../redux/store.model';
import ScheduleAPI from '../../../../../../service/schedule/ScheduleAPI';
import { ScheduleAPIResponse } from '../../../../../../service/schedule/ScheduleAPI.model';
import ZoneSettingsAPI from '../../../../../../service/zoneSettings/ZoneSettingsAPI';
import useErrorMessage from '../../../../../../shared/hooks/useErrorMessage';
import { toBase64 } from '../../../../../../shared/util/base64';
import LocationService from '../../../../../../service/locations/LocationService';
import DayPartsDisplay, {
  DayPartDisplayProps,
} from '../../Region/ManagerScheduleSettings/DayPart/DayPartDiplay';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { ZoneSettingWitIdResponse } from '../../../../../../service/zoneSettings/ZoneSettingsAPI.model';
import Button from '../../../../../../theme/ui/Atoms/Button/Button';
import getWeekDaysNotEmpty from '../../../../../../service/schedule/util/getWeekDaysNotEmpty';
import { getFeatureToggle } from '../../../../../../shared/featureToggle';
import _ from 'lodash';
import filterValidSchedulesSelector from '../../../../../../service/schedule/selectors/filterValidSchedulesSelector';
import useGetDeviceFromStoreByParam from '../../../../../../service/device/hooks/useGetDeviceFromStoreParam';
import { getHighestParentGroupFromGroupIdSelector } from '../../../../../../redux/structure/selectors/Group.selector';
import useMessage from '../../../../../../shared/hooks/useMessage';

function ManagerAssignSchedulePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showMessage = useMessage();
  const params = useParams<{
    storeId: string;
    deviceId: string;
  }>();
  const higherGroupId =
    useAppSelector((state) =>
      getHighestParentGroupFromGroupIdSelector(state, {
        groupId: params.storeId ?? '',
      })
    ) ?? '';
  const { deviceId = '', storeId = '' } = params;
  // get device
  const {
    isFetching: isFetchingLocations,
    isLoading: isLoadingLocations,
    error: errorLocations,
    // data: locations,
  } = LocationAPI.useGetLocationsQuery(undefined);
  const {
    data: device,
    isLoading: isLoadingDevice,
    error: gettingDeviceError,
  } = useGetDeviceFromStoreByParam({
    storeId,
    deviceId,
  });
  const nZones = useMemo(() => {
    const mapping = device?.composableDeviceId
      ? Object.keys(device?.composableZoneMapping ?? {}).map((key) => ({
          id: Number(key),
        }))
      : device?.shelfMappings;
    return _.max(mapping?.map((zone) => (zone ? zone.id : null))) ?? 0;
  }, [device]);

  // get schedules
  const {
    error: errorSchedules,
    isLoading: isLoadingSchedules,
    isFetching: isFetchingSchedules,
    data: schedules,
  } = ScheduleAPI.useGetSchedulesByGroupQuery(higherGroupId ?? '', {
    refetchOnMountOrArgChange: true,
    skip: !higherGroupId,
  });

  // schedule details
  const {
    isFetching: isFetchingZoneSettings,
    isLoading: isLoadingZoneSettings,
    error: errorZoneSettings,
    data: zoneSettings = [],
  } = ZoneSettingsAPI.useGetZoneSettingsByGroupQuery(higherGroupId ?? '', {
    refetchOnMountOrArgChange: true,
    skip: !higherGroupId,
  });
  const {
    error: errorDayParts,
    isLoading: isLoadingDayParts,
    isFetching: isFetchingDayParts,
    data: dayParts = [],
  } = ScheduleAPI.useGetDayPartByGroupQuery(higherGroupId ?? '', {
    refetchOnMountOrArgChange: true,
    skip: !higherGroupId,
  });

  useWatchError(
    errorLocations ??
      errorSchedules ??
      errorZoneSettings ??
      errorDayParts ??
      gettingDeviceError
  );
  const errorMessage = useErrorMessage();

  const scheduleAssigned =
    device?.schedule &&
    schedules?.find((schedule) => schedule.scheduleId === device?.schedule);

  const filterValidSchedules = useAppSelector((state) =>
    filterValidSchedulesSelector(state, {
      groupId: higherGroupId,
      nZones,
      device,
    })
  );

  const [setScheduleToADevice] = LocationAPI.useSetScheduleToADeviceMutation();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const onClick = (schedule: ScheduleAPIResponse) => () => {
    // console.log('ManagerAssignSchedulePage: onClick: schedule: ', schedule);
    Promise.resolve(setIsLoadingUpdate(true))
      .then(() =>
        setScheduleToADevice({
          deviceId: deviceId,
          scheduleId: schedule.scheduleId,
        }).unwrap()
      )
      .then((response) =>
        LocationService.confirmCorrelationIdRecursive(
          toBase64(device?.commanderId ?? ''),
          response.correlationID
        )
      )
      .then(() => {
        showMessage(t('schedule.settings.view_locations.schedule_assigned'));
        navigate(`/manager/view-locations/${storeId}`);
      })
      .catch(errorMessage)
      .finally(() => setIsLoadingUpdate(false));
  };

  const isLoadingScheduleGeneral =
    isLoadingUpdate ||
    isLoadingSchedules ||
    isLoadingDayParts ||
    isLoadingZoneSettings ||
    isFetchingSchedules ||
    isFetchingDayParts ||
    isFetchingZoneSettings ||
    isLoadingDevice;

  const { Schedule } = useAppSelector(getFeatureToggle);
  if (!Schedule) {
    navigate(`/manager/view-locations`, { replace: true });
  }

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <Typography variant="h1">
        {t('schedule.settings.view_locations.assign_schedule')}
      </Typography>
      <Divider />
      <Box my={2}>
        <Typography variant="subtitle2" color="primary">
          {t('view_locations.header_device_name')}
        </Typography>
        {isLoadingLocations || isFetchingLocations ? (
          <CircularProgress />
        ) : (
          device && (
            <Typography gutterBottom>{`${device?.storeName} - ${
              device?.unitName
            } - ${device?.model ?? ''} - ${t(
              'schedule.settings.schedule.n_zones',
              {
                count: nZones,
              }
            )} `}</Typography>
          )
        )}

        <Typography variant="subtitle2" color="primary">
          {t('schedule.settings.view_locations.schedule_assigned')}
        </Typography>
        {isLoadingScheduleGeneral ? (
          <Box p={2}>
            <CircularProgress />
          </Box>
        ) : device?.schedule && scheduleAssigned ? (
          <ListItemSchedule
            {...scheduleAssigned}
            dayParts={dayParts}
            zoneSettings={zoneSettings}
          />
        ) : (
          <Typography>
            {t('schedule.settings.view_locations.schedule_not_assigned')}
          </Typography>
        )}

        <Typography variant="subtitle2" color="primary">
          {t('schedule.settings.view_locations.schedule_placeholder')}
        </Typography>

        {/* <Paper> */}
        {isLoadingScheduleGeneral ? (
          <Box p={2}>
            <CircularProgress />
          </Box>
        ) : filterValidSchedules.length > 0 ? (
          filterValidSchedules.map(({ schedule, error }, i) => (
            <ListItemSchedule
              key={schedule.scheduleId}
              {...schedule}
              error={error}
              dayParts={dayParts}
              zoneSettings={zoneSettings}
              onClick={!error ? onClick(schedule) : undefined}
            />
          ))
        ) : (
          <Typography sx={{ m: 2, py: 4 }}>
            {`${t('schedule.settings.view_locations.no_schedules', {
              text: nZones,
            })}`}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export interface ListItemScheduleProps extends DayPartDisplayProps {
  scheduleName: ScheduleAPIResponse['scheduleName'];
  zoneCount: ScheduleAPIResponse['zoneCount'];
  zoneSettings: ZoneSettingWitIdResponse[];
  error?: string;
  onClick?: () => void;
}
const ListItemSchedule = (props: ListItemScheduleProps) => {
  const { scheduleName, zoneCount, onClick, error, ...DayPartStringProps } =
    props;
  const { t } = useTranslation();
  return (
    <>
      <Grid container item sx={{ marginY: 2, alignItems: 'center' }}>
        <Accordion sx={{ flexGrow: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ListItemText
              primary={scheduleName}
              secondary={`${t('schedule.settings.schedule.n_zones', {
                count: zoneCount,
              })}, ${t('schedule.settings.schedule.n_weekdays', {
                count: getWeekDaysNotEmpty(DayPartStringProps.schedule).length,
              })} ${error ? ` (${t(error)})` : ''}`}
            />
          </AccordionSummary>
          <AccordionDetails>
            <DayPartsDisplay {...DayPartStringProps} />
          </AccordionDetails>
        </Accordion>

        {onClick && (
          <Grid item width={80} sx={{ margin: 2 }}>
            <Button variant="contained" rounded onClick={onClick}>
              {t('schedule.settings.schedule.select_schedule')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ManagerAssignSchedulePage;
