import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { toBase64 } from '../../../shared/util/base64';
import newSettingToSetZone from '../../store/modules/zone/serialize/newSettingToSetZone';
import { DevicesStoreInfoResponse } from '../../store/StoreService.model';
import ZoneSettingsAPI from '../ZoneSettingsAPI';
import { ZoneSetResponse } from '../../store/modules/zone/ZoneService.model';
import { AxiosError } from 'axios';
import LocationService from '../../locations/LocationService';
import FlexeserveDeviceAPI from '../../device/FlexeserveDevice/FlexeserveDeviceAPI';
import { DeviceAPI } from '../../device/DeviceAPI';

const ZoneManagerOperationAPI = ZoneSettingsAPI.injectEndpoints({
  endpoints: (build) => ({
    /**
     * set Zone with respective setting
     * @param encodedDeviceID device.uuid encoded in base64
     * @param data Object with the timer configuration
     * @param zoneIndex Index of the zone 1-5, 1 is the first zone
     */
    setZoneSettings: build.mutation<
      unknown,
      {
        deviceId: string;
        newSetting: string;
        zoneIndex: keyof DevicesStoreInfoResponse['zones'];
      }
    >({
      queryFn: async (
        { deviceId, newSetting, zoneIndex },
        api,
        extraOptions,
        baseQuery
      ) => {
        const encodedDeviceID = toBase64(deviceId);
        const serializedZone = newSettingToSetZone(newSetting, zoneIndex);

        const response = (await baseQuery({
          url: `/operator-action/${encodedDeviceID}/zonesettings`,
          method: 'post',
          data: serializedZone,
        })) as QueryReturnValue<ZoneSetResponse, AxiosError, {}>;
        if (response.error) {
          return { data: undefined, error: response.error };
        }
        const response2 = await LocationService.confirmCorrelationIdRecursive(
          encodedDeviceID,
          response?.data?.correlationID ?? ''
        )
          .then(() => response)
          .catch((error) => ({ ...response, error }));
        await api.dispatch(
          FlexeserveDeviceAPI.util.invalidateTags(['zone-actions'])
        );
        await api.dispatch(DeviceAPI.util.invalidateTags(['zone-actions']));
        return response2;
      },
    }),
  }),
});

export default ZoneManagerOperationAPI;
