import { styled } from '@mui/material/styles';
import React from 'react';
import { POSSIBLE_SETTINGS } from '../../../../service/store/modules/zone/ZoneService.model';
import { shouldForwardProp } from '../../util/shouldForwardProps';
import ButtonSelect from '../ButtonSelect';
import { ButtonSelectProps } from '../ButtonSelect/ButtonSelect';
import ZoneButton, { ZoneButtonProps } from '../ZoneButton';

const StyledButton = styled(ZoneButton, {
  shouldForwardProp,
  name: 'FSZoneButton',
  slot: 'Root',
})``;
export type ZoneSelectProps = ButtonSelectProps<POSSIBLE_SETTINGS> &
  ZoneButtonProps & {
    options: { text: string; value: POSSIBLE_SETTINGS };
  };

export default React.forwardRef(function ZoneSelect(
  props: ZoneSelectProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const slots = {
    root: StyledButton,
    ...props.slots,
  };

  const value =
    props.defaultValue ||
    props.options.find(
      (itemOption) => itemOption.value === props.zone?.activeSetting
    )?.value;

  return <ButtonSelect {...props} ref={ref} slots={slots} value={value} />;
});
