import { LoadingButton } from '@mui/lab';
import { EntityActionsProps } from './EntityActions';
import { Box, Stack, Typography } from '@mui/material';
import { CloudOffIcon, CloudOnIcon } from '../../../../../../assets';
import useDeviceStatusFromGroup from '../../../../../../service/device/CommanderAPI/hooks/useDeviceStatusFromGroup';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { M_SECONDS_POOLING_INTERVAL_DATA } from '../../../../../../service/store/Store.constants';
import { fromBase64 } from '../../../../../../shared/util/base64';
import { memo } from 'react';

export function EntityCommanderStatusAction(props: EntityActionsProps) {
  const { entity, active } = props;
  const groupId = fromBase64(entity?.id ?? '');
  const {
    numberFailingMqttConnection,
    isLoading: isLoadingDeviceStatus,
    commanderIdsDevices,
  } = useDeviceStatusFromGroup(groupId, {
    pollingInterval: M_SECONDS_POOLING_INTERVAL_DATA,
  });

  const [searchBarParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchBarParams.entries());

  const loading = isLoadingDeviceStatus;
  if (!loading && !commanderIdsDevices.length) return null;
  if (
    loading ||
    (numberFailingMqttConnection && numberFailingMqttConnection > 0)
  ) {
    return (
      <LoadingButton
        variant={active ? 'contained' : 'outlined'}
        color={active ? 'primary' : 'inherit'}
        loading={loading}
        href={`/manager/view-locations/${entity?.uuid}?${createSearchParams({
          ...searchParamsObject,
          deviceStatusFailing: !active + '',
        }).toString()}`}
        sx={{ fontSize: '1rem', width: '6rem', height: '3rem' }}
      >
        <Stack direction={'row'} spacing={0.5}>
          <Typography
            component="span"
            variant={'inherit'}
            color={!active ? 'error' : 'inherit'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <CloudOffIcon height={'1.5em'} />
          </Typography>
          <Typography
            component="span"
            variant={'inherit'}
            fontSize={'1rem'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            |
          </Typography>
          <Typography
            component="span"
            variant={'inherit'}
            display={'flex'}
            alignItems={'center'}
            fontSize={'1rem'}
            justifyContent={'center'}
          >
            {numberFailingMqttConnection ?? 0}
          </Typography>
        </Stack>
      </LoadingButton>
    );
  }

  return (
    <Box
      sx={{
        fontSize: '1rem',
        width: '6rem',
        height: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        component="span"
        variant={'inherit'}
        color={'success.main'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <CloudOnIcon height={'1.5em'} />
      </Typography>
    </Box>
  );
}

export default memo(EntityCommanderStatusAction);
