import { UseQuerySubscription } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import useDevicesManager from './useDevicesManager';
import { useMemo } from 'react';

type Options = Parameters<UseQuerySubscription<any>>[1];

function useDeviceManager(
  args: { storeId: string; deviceId?: string },
  options: Options = {}
) {
  const { storeId, deviceId } = args;
  const responseDevicesAPI = useDevicesManager(storeId, options);
  const { data: devices } = responseDevicesAPI;
  const data = useMemo(
    () => devices?.find((device) => device.uuid === deviceId),
    [deviceId, devices]
  );

  return {
    ...responseDevicesAPI,
    data,
  };
}

export default useDeviceManager;
