import Stack from '@mui/material/Stack';
import { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeFrameSelection, LAST_6_HOURS } from 'stoerk-ui-components';
import { useDevicesControlUnits } from '../../../../../redux/controlUnit/hooks/useDevicesControlUnits';
import { CommanderMonitoringFilterLogicalObject } from '../util/filter.model';
import DigitalChannelFilters from './DigitalChannelFilters';

export type ChartsFiltersProps = {
  onChangeDatePicker(...args: Moment[]): void;
  deviceId?: string;
  commanderId?: string;
  controlUnitId?: string;
  groupId?: string;
  analogControls: [
    CommanderMonitoringFilterLogicalObject,
    React.Dispatch<React.SetStateAction<CommanderMonitoringFilterLogicalObject>>
  ];
  digitalControls: [
    CommanderMonitoringFilterLogicalObject,
    React.Dispatch<React.SetStateAction<CommanderMonitoringFilterLogicalObject>>
  ];
};

export const ChartsFilters = (props: ChartsFiltersProps) => {
  const { devicesControlUnits } = useDevicesControlUnits({
    deviceId: props.deviceId ?? '',
    commanderId: props.commanderId ?? '',
  });
  const {
    analogControls: [analogFilterValue, setAnalogFilterValue],
    digitalControls: [digitalFilterValue, setDigitalFilterValue],
  } = props;
  const i18n = useTranslation();
  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ marginY: 2 }}
    >
      <DigitalChannelFilters
        devicesControlUnits={devicesControlUnits}
        type="channels"
        value={analogFilterValue}
        onSave={setAnalogFilterValue}
      />
      <DigitalChannelFilters
        devicesControlUnits={devicesControlUnits}
        type="events"
        value={digitalFilterValue}
        onSave={setDigitalFilterValue}
      />

      <div className="TimeFrame">
        <TimeFrameSelection
          timeFrame={LAST_6_HOURS}
          onChange={props.onChangeDatePicker}
          nameButtonSend={i18n.t('button.go') ?? 'button.go'}
          language={i18n.i18n.language}
        />
      </div>
    </Stack>
  );
};
