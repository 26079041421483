import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  getTypeUserSelector,
  isInstallFacilitiesSelector,
} from '../../redux/auth/selectors';
import { RootState } from '../../redux/store.model';

interface HomeProps extends ConnectedComponentProps {}
/**
 * No home, just redirection to login or timers depending if is authenticated
 * */
function Home(props: HomeProps) {
  let url = '/login';

  switch (props.typeUser) {
    case 'operator':
      url = '/operation';
      break;
    case 'manager':
      if (props.isInstallFacilitiesSelector) {
        url = '/install-facilities';
      } else url = '/manager/view-locations';
      break;
    default:
      if (props.isInstallFacilitiesSelector) {
        url = '/login-install-facilities';
      }
      break;
  }

  // add query params
  url += window.location.href.split('?')[1]
    ? `?${window.location.href.split('?')[1]}`
    : '';

  return <Navigate to={url} replace />;
}

const mapStateToProps = (state: RootState) => ({
  typeUser: getTypeUserSelector(state),
  isInstallFacilitiesSelector: isInstallFacilitiesSelector(state),
});
const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(Home);
