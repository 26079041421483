import { useControlUnitsByIds } from './useControlUnitsByIds';
import { useMemo } from 'react';

interface Props {
  deviceId?: string;
  commanderId?: string;
  groupId?: string;
  controlUnitId?: string;
}
export function useDevicesControlUnits(props: Props) {
  const {
    // groupId,
    deviceId,
    controlUnitId,
  } = props;
  // const [, groupsLoading, groupError] = useGroupById(groupId, (error) => {
  //   console.error(error);
  // });
  const [
    devicesControlUnitsSource,
    devicesControlUnitsLoading,
    devicesControlUnitsError,
    refetch,
  ] = useControlUnitsByIds(props);
  // filter by QueryParam
  const devicesControlUnits = useMemo(() => {
    let devicesControlUnitsTemp = devicesControlUnitsSource;
    // deviceId
    if (deviceId)
      devicesControlUnitsTemp = devicesControlUnitsSource.filter(
        (devicesControlUnit) => devicesControlUnit.deviceId === deviceId
      );
    // controlUnitId
    if (controlUnitId)
      devicesControlUnitsTemp = devicesControlUnitsSource.map(
        (devicesControlUnit) => ({
          ...devicesControlUnit,
          controlUnits: devicesControlUnit.controlUnits?.filter(
            // eslint-disable-next-line eqeqeq
            (controlUnit) => controlUnit.id == controlUnitId
          ),
        })
      );
    return devicesControlUnitsTemp;
  }, [devicesControlUnitsSource, deviceId, controlUnitId]);

  // const loading = groupsLoading || devicesControlUnitsLoading;
  // const error = groupError || devicesControlUnitsError;
  const loading = devicesControlUnitsLoading;
  const error = devicesControlUnitsError;
  return { devicesControlUnits, loading, error, refetch };
}
