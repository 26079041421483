import React from 'react';
import Button from '../../../../theme/ui/Atoms/Button';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { DeviceOverviewIcon } from '../../../../assets';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../BackButton';
import useWatchError from '../../../../shared/hooks/useWatchError';
import useDevicesByCommanderManager from '../../../../service/device/hooks/useDevicesByCommanderManager';
import StatusOverviewByDevice from '../../Manager/ManagerViewLocations/StatusOverviewByDevice';
import AlarmNumber from './AlarmNumber';
import DeviceType from './DeviceType';
import Table from '../../../../theme/ui/Atoms/Table/Table';

function DevicesManagerOverviewSetUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickBack = () => {
    navigate('/');
  };
  const onClickNext = () => {
    navigate('../devices-overview-operator');
  };
  const { commanderId = '', storeId = '' } = useParams<{
    commanderId: string;
    serialNumber: string;
    storeId: string;
  }>();
  const {
    data: devices,
    isFetching: loadingDevices,
    error: errorDevices,
  } = useDevicesByCommanderManager(
    { storeId, commanderId },
    {
      refetchOnMountOrArgChange: true,
      skip: !storeId,
    }
  );
  const loading = loadingDevices;
  const error = errorDevices;
  useWatchError(error);
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: 2,
          fill: (theme) => theme.palette.text.primary,
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          {loading ? (
            <CircularProgress />
          ) : (
            <DeviceOverviewIcon height={'2em'} />
          )}
          <Typography
            variant="subtitle2"
            marginY={2}
            sx={{
              display: 'flex',
              marginLeft: 3,
              alignItems: 'center',
            }}
          >
            {t('initial_setup.overview.manager_overview')}
          </Typography>
        </Stack>
      </Box>

      <Container maxWidth="xl" sx={{ mt: 4, overflow: 'auto' }}>
        <Stack>
          <Table
            sx={{
              '& th.header_store': {
                minWidth: 400,
              },
            }}
          >
            <thead style={{ border: 'none' }}>
              <tr>
                <Typography
                  component={'th'}
                  className="header_store"
                  color="primary"
                  variant="subtitle2"
                  sx={{ textAlign: 'left!important' }}
                >
                  {t('view_locations.header_device_name')}
                </Typography>
                <Typography
                  component={'th'}
                  color="primary"
                  variant="subtitle2"
                >
                  {t('view_locations.header_model')}
                </Typography>
                <Typography
                  component={'th'}
                  color="primary"
                  variant="subtitle2"
                >
                  {t('view_locations.header_status')}
                </Typography>
                <Typography
                  component={'th'}
                  color="primary"
                  variant="subtitle2"
                >
                  {t('view_locations.header_alarms')}
                </Typography>
              </tr>
            </thead>
            <tbody>
              {devices?.map((device) => {
                return (
                  <Paper
                    component={'tr'}
                    key={device.uuid}
                    elevation={undefined}
                  >
                    <td>
                      <Typography variant="body1" textAlign={'left'}>
                        {device.unitName}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1" textAlign={'center'}>
                        <DeviceType device={device} />
                      </Typography>
                    </td>
                    <td>
                      <StatusOverviewByDevice device={device} />
                    </td>
                    <td>
                      <Typography variant="body1" textAlign={'center'}>
                        <AlarmNumber device={device} />
                      </Typography>
                    </td>
                  </Paper>
                );
              })}
            </tbody>
          </Table>
        </Stack>
      </Container>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={2} justifyContent={'space-between'} mt={2}>
          <Grid item xs={12} sm={2}>
            <BackButton onClick={onClickBack} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              rounded
              fullWidth
              onClick={onClickNext}
            >
              {t('button.next')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default DevicesManagerOverviewSetUp;
