import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import Button from '../../Button';
import { SearchIcon } from '../../../../../assets';

type SearchInputProps = Omit<TextFieldProps, 'variant'>;
export function SearchInput(props: SearchInputProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onClickButton = () => {
    inputRef.current?.focus();
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <TextField
        fullWidth
        placeholder="Write to start search"
        variant="outlined"
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#222', // Dark background
            color: '#fff', // White text
            borderRadius: '8px 0 0 8px', // Rounded left corners only
            fieldset: { borderColor: '#555' }, // Gray border
          },
          '& .MuiOutlinedInput-input': {
            height: '1.5rem',
            padding: 2,
          },
        }}
        inputRef={inputRef}
        {...props}
      />
      <Button
        variant="contained"
        sx={{
          borderRadius: '0 8px 8px 0', // Rounded right corners
          padding: 2,
          width: '7rem',
          fontSize: '1.5rem',
          height: '1.5rem',
          boxSizing: 'content-box',
        }}
        onClick={onClickButton}
      >
        <SearchIcon />
      </Button>
    </div>
  );
}

export default SearchInput;
