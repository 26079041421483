import { AxiosInstance } from 'axios';
import AxiosFlexeServeInstance from '../../shared/AxiosFlexeServe';
import confirmCorrelationIdRecursive from '../../shared/confirmCorrelationIdRecursive';
import { CommanderTriggerUpdate } from './CommanderUpdate.model';

export class CommanderUpdateAPIService {
  public constructor(private axios: AxiosInstance = AxiosFlexeServeInstance) {}

  commanderUpdate = (
    encodedCommanderId: string,
    data: CommanderTriggerUpdate
  ) => {
    return this.axios({
      url: `/update/device/${encodedCommanderId}/update`,
      method: 'post',
      data,
    })
      .then((response) => response.data)
      .then(({ correlationID }) =>
        confirmCorrelationIdRecursive(
          encodedCommanderId,
          correlationID,
          this.getConfirmationUpdate
        )
      )
      .then((data) => data?.response?.result);
  };

  getConfirmationUpdate = (
    encodedCommanderId: string,
    correlationId: string
  ) => {
    return this.axios({
      url: `/update/device/${encodedCommanderId}/response/${correlationId}`,
      method: 'get',
    }).then((response) => response?.data);
  };
}

export default new CommanderUpdateAPIService();
