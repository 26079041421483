import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormHelperText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { ArrowBackIcon, TemperatureIcon } from '../../../../../assets';
import { useTranslation } from 'react-i18next';
import Switch from '../../../../../theme/ui/Atoms/Switch';
import {
  DEFAULT_TEMPERATURE_UNIT,
  TEMPERATURE_UNIT_TYPES,
} from '../../../../../service/temperaturePreference';
import Button from '../../../../../theme/ui/Atoms/Button';
import LocationAPI from '../../../../../service/locations/LocationAPI';
import { useNavigate } from 'react-router-dom';
import useWatchError from '../../../../../shared/hooks/useWatchError';
import useErrorMessage from '../../../../../shared/hooks/useErrorMessage';
import { useFormik } from 'formik';

interface ManagerTemperaturePreferenceProps {
  /** could be storeId or regionId */
  groupId?: string;
  /** key of translation title */
  title: string;
  /** key of translation */
  hintText: string;
  /** show checkbox with recursive */
  recursive?: boolean;
}
function ManagerTemperaturePreference(
  props: ManagerTemperaturePreferenceProps
) {
  const { t } = useTranslation();
  const { groupId = '', hintText, title, recursive } = props;
  const {
    error: errorTemperaturePreferenceByRegion,
    data: temperaturePreferenceByRegion,
    isLoading: isLoadingTemperaturePreferenceByRegion,
  } = LocationAPI.useGetTemperaturePreferenceByGroupQuery(groupId);
  useWatchError(errorTemperaturePreferenceByRegion);

  const initialTemperaturePreference =
    temperaturePreferenceByRegion ?? DEFAULT_TEMPERATURE_UNIT;
  const formik = useFormik({
    initialValues: {
      recursive: false,
      temperaturePreference: initialTemperaturePreference,
    },
    onSubmit: (values) =>
      setTemperaturePreferenceCall({
        groupId,
        ...values,
      })
        .unwrap()
        .then(onBack)
        .catch(showErrorMessage),
  });

  // race condition
  useEffect(() => {
    formik.setFieldValue('temperaturePreference', initialTemperaturePreference);
    // formik changes every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTemperaturePreference]);

  const toggleTemperature = () => {
    const newTemperature =
      formik.values.temperaturePreference === TEMPERATURE_UNIT_TYPES.CELSIUS
        ? TEMPERATURE_UNIT_TYPES.FAHRENHEIT
        : TEMPERATURE_UNIT_TYPES.CELSIUS;
    formik.setFieldValue('temperaturePreference', newTemperature);
  };
  const temperaturePreferenceLabel = t(
    `settings.temperature_preference.${formik.values.temperaturePreference}`
  );

  const [setTemperaturePreferenceCall, { isLoading }] =
    LocationAPI.useSetTemperaturePreferenceByGroupMutation({});
  const showErrorMessage = useErrorMessage();

  const navigate = useNavigate();
  const onBack = () => {
    navigate(`../actions`);
  };

  return (
    <Box
      component="form"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      onSubmit={formik.handleSubmit}
    >
      <Container maxWidth="sm">
        <Paper
          sx={{
            padding: 2,
            marginY: 2,
          }}
        >
          <Typography variant="h1">{t(title)}</Typography>
          <Box sx={{ padding: 2, marginY: 2 }}>
            {isLoadingTemperaturePreferenceByRegion ? (
              <CircularProgress />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TemperatureIcon height={'2em'} />
                  <Typography sx={{ marginLeft: 2 }}>
                    {t('settings.temperature_preference.title')}
                  </Typography>
                </Box>
                <Box sx={{ width: '180px' }}>
                  <Switch
                    checked={
                      formik.values.temperaturePreference ===
                      TEMPERATURE_UNIT_TYPES.CELSIUS
                    }
                    id="temperaturePreference"
                    slotProps={{ input: { name: 'temperaturePreference' } }}
                    onChange={toggleTemperature}
                    onBlur={formik.handleBlur}
                    label={temperaturePreferenceLabel}
                    sx={{ textAlign: 'center' }}
                  />
                </Box>
              </Box>
            )}
            {recursive && (
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={formik.values.recursive}
                      id="recursive"
                      name="recursive"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label={t(
                    'settings.temperature_preference.manager_region_recursive'
                  )}
                />
                <FormHelperText>
                  {t(
                    'settings.temperature_preference.manager_region_recursive_hint'
                  )}
                </FormHelperText>
              </Box>
            )}
          </Box>

          <Typography>{t(hintText)}</Typography>

          <Stack direction={'row'} spacing={1} mt={3}>
            <Button
              variant="contained-gray"
              color="primary"
              size="large"
              rounded
              sx={{ width: '100%' }}
              onClick={onBack}
              disabled={isLoading}
            >
              <ArrowBackIcon />
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              rounded
              sx={{ width: '100%' }}
              type="submit"
              disabled={isLoadingTemperaturePreferenceByRegion}
              loading={isLoading}
            >
              {t('button.save')}
            </Button>
          </Stack>
        </Paper>
      </Container>
    </Box>
  );
}

export default ManagerTemperaturePreference;
