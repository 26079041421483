import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

function convertPromiseToQueryReturnValue<T>(
  promise: Promise<T>
): Promise<QueryReturnValue<T, unknown, unknown>> {
  return promise
    .then(
      (data) =>
        ({ data, meta: {}, error: undefined } as QueryReturnValue<
          T,
          unknown,
          unknown
        >)
    )
    .catch(
      (error) =>
        ({
          error: error?.message,
          data: undefined,
          meta: {} as unknown,
        } as QueryReturnValue<T, unknown, unknown>)
    );
}

export default convertPromiseToQueryReturnValue;
