import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AlarmsQueryAPIResponse,
  AlarmsQueryParamsAPIRequest,
  AlarmAPIResponseV2,
  ALARMS_POLLING_INTERVAL,
} from './Alarm.model';
import axiosBaseQuery from '../../service/shared/axiosBaseQuery';
import { toBase64 } from '../../shared/util/base64';

const AlarmAPI = createApi({
  reducerPath: 'incident',
  baseQuery: axiosBaseQuery({ baseUrl: '/' }),
  keepUnusedDataFor: ALARMS_POLLING_INTERVAL,
  tagTypes: [
    'policy-actions',
    'subscription-actions',
    'group-subscription-actions',
    'policy-subscription-actions',
    'incident-actions',
    'device-subscription-actions',
  ],
  endpoints(build) {
    return {
      getIncidentsByPolicyId: build.query<
        AlarmAPIResponseV2[],
        string | undefined
      >({
        query: (policyId = '') => ({
          url: `alarm/incident?policyId=${policyId}`,
        }),
        providesTags: ['incident-actions'],
      }),
      getIncidentById: build.query<AlarmAPIResponseV2, string | undefined>({
        query: (incidentId = '') => ({
          url: `alarm/incident/${toBase64(incidentId)}`,
        }),
        providesTags: ['incident-actions'],
      }),
      getIncidentByWorkspaceId: build.query<
        AlarmAPIResponseV2[],
        string | undefined
      >({
        query: (workspaceId = '') => ({
          url: `alarm/workspace/${toBase64(workspaceId)}/incident`,
        }),
        providesTags: ['incident-actions'],
      }),
      getMyIncidentByWorkspaceId: build.query<
        AlarmAPIResponseV2[],
        { workspaceId?: string; filterByActive?: boolean }
      >({
        query: ({ workspaceId = '', filterByActive = true }) => ({
          url: filterByActive
            ? `alarm/workspace/${toBase64(workspaceId)}/incident/self/active`
            : `alarm/workspace/${toBase64(workspaceId)}/incident/self`,
        }),
        providesTags: ['incident-actions'],
      }),
      queryMyIncidentByWorkspaceId: build.query<
        AlarmsQueryAPIResponse,
        { workspaceId?: string; params?: AlarmsQueryParamsAPIRequest }
      >({
        query: ({ workspaceId = '', params = {} }) => ({
          url: `alarm/workspace/${toBase64(workspaceId)}/incident/self/query`,
          params,
        }),
        providesTags: ['incident-actions'],
      }),
    };
  },
});

// Incident/Policy/api/PolicyAPI.ts inject more endpoints
export default AlarmAPI;
