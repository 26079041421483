import React, { useContext } from 'react';
import { SearchList } from '../SearchList/SearchList';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAllStores } from '../../../../../redux/structure/selectors/Stores.selector';
import { isLoadingStructure } from '../../../../../redux/structure/selectors/Structure.selector';
import { SearchContext } from '../SearchBar';

export const StoreSearch = () => {
  const { search } = useContext(SearchContext);
  const searchQuery = search.toLowerCase();

  const stores = useAppSelector(getAllStores);
  const isLoading = useAppSelector(isLoadingStructure);

  const filteredStores =
    stores
      ?.filter((store) => store.name?.toLowerCase().includes(searchQuery))
      .map((item) => {
        return { ...item, key: item.id ?? '', value: item.name ?? '' };
      }) ?? [];

  return (
    <SearchList
      header="initial_setup.store_label"
      list={filteredStores}
      loading={isLoading}
    />
  );
};
