import React, { useContext } from 'react';
import { SearchList } from '../SearchList/SearchList';
import { useAppSelector } from '../../../../../redux/store.model';
import { isLoadingStructure } from '../../../../../redux/structure/selectors/Structure.selector';
import { SearchContext } from '../SearchBar';
import { getAllRegions } from '../../../../../redux/structure/selectors/Region.selector';

export const RegionSearch = () => {
  const { search } = useContext(SearchContext);
  const searchQuery = search.toLowerCase();

  const regions = useAppSelector(getAllRegions);
  const isLoading = useAppSelector(isLoadingStructure);

  const filteredRegions =
    regions
      ?.filter((region) => region.name?.toLowerCase().includes(searchQuery))
      .map((item) => {
        return { ...item, key: item.id ?? '', value: item.name ?? '' };
      }) ?? [];

  return (
    <SearchList
      header="initial_setup.region_label"
      list={filteredRegions}
      loading={isLoading}
    />
  );
};
