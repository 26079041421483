import React from 'react';
import LocationsLayout from './LocationsLayout/LocationsLayout';
import { Skeleton, Stack } from '@mui/material';
import StructureListItem from './StructureListItem';
import useTypeEntity from './useTypeEntity';
import useWatchError from '../../../../../shared/hooks/useWatchError';
import ManagerViewStore from '../ManagerViewStore/ManagerViewStore';
import { M_SECONDS_POOLING_INTERVAL_DATA } from '../../../../../service/store/Store.constants';
import useDevicesManager from '../../../../../service/device/hooks/useDevicesManager';

function EntityBusinessLayerList() {
  const {
    type,
    filteredChildGroups,
    groupId: storeId,
    error: errorEntity,
    role,
  } = useTypeEntity({
    refetchOnMountOrArgChange: true,
    pollingInterval: M_SECONDS_POOLING_INTERVAL_DATA,
  });

  const {
    data: devices,
    error: devicesError,
    isLoading: devicesLoading,
  } = useDevicesManager(storeId, {
    skip: !storeId || type !== 'store',
    refetchOnMountOrArgChange: true,
    pollingInterval: M_SECONDS_POOLING_INTERVAL_DATA,
  });

  const error = devicesError || errorEntity;
  useWatchError(error);
  const content =
    type !== 'store' ? (
      <Stack spacing={1}>
        {filteredChildGroups?.map((entity: any) => (
          <StructureListItem key={entity?.id} entity={entity} />
        ))}
      </Stack>
    ) : (
      <>
        <ManagerViewStore devices={devices} />
        {devicesLoading && !devices.length && (
          <Skeleton variant="rectangular" width={'100%'} height={200} />
        )}
      </>
    );

  return (
    <LocationsLayout
      innerContainerMaxWidth={type === 'store' ? 'xl' : 'lg'}
      role={role}
    >
      {content}
    </LocationsLayout>
  );
}

export default EntityBusinessLayerList;
