import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { getShelfSettings } from '../../../../service/device/util/getDeviceMappings';
import { ComposableDevicesManagerStoreInfoResponse } from '../../../../service/device/hooks/useDevicesManager';
import { ComposableDevicesStoreInfoResponse } from '../../../../service/store/hooks/useDevicesOperator';

export interface StatusOverviewByDeviceProps {
  device:
    | ComposableDevicesStoreInfoResponse
    | ComposableDevicesManagerStoreInfoResponse;
}
export default function StatusOverviewByDevice({
  device,
}: StatusOverviewByDeviceProps) {
  const shelfSettings = getShelfSettings(device);
  const shelfSettingsToUse = Object.values(shelfSettings ?? {}).filter(
    (item) => item?.shelfStatus !== undefined
  );
  return (
    <Stack flexDirection={'row'} justifyContent={'center'}>
      {shelfSettingsToUse.map(
        (
          zoneSettings = {
            shelfStatus: { deviceOn: false, operatingMode: 'UNUSED' },
          } as any,
          index
        ) => {
          const {
            shelfStatus: { deviceOn, operatingMode },
          } = zoneSettings;
          const unused = operatingMode === 'UNUSED';
          const warning = operatingMode === 'TRANSITIONING';
          const error =
            operatingMode === 'ERROR' || operatingMode === 'ERROR_CU_MISMATCH';
          const disconnected = !device?.deviceState?.mqttConnected;

          return (
            <Circle
              key={index}
              unused={unused}
              disconnected={disconnected}
              off={!deviceOn}
              warning={warning}
              error={error}
            />
          );
        }
      )}
    </Stack>
  );
}

const Circle = ({
  unused,
  disconnected,
  warning,
  error,
  off,
}: {
  unused?: boolean;
  warning?: boolean;
  disconnected?: boolean;
  error?: boolean;
  off?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Box
      className="Zone-status"
      title={`${t('timers.status')} ${
        unused
          ? ''
          : disconnected
          ? t('timers.status_disconnected')
          : error
          ? t('timers.status_error')
          : off
          ? t('timers.status_off')
          : warning
          ? t('timers.status_warning')
          : t('timers.status_ok')
      }`}
      sx={{
        borderRadius: '20px',
        border: (theme) =>
          off && !error && !unused && !disconnected
            ? `2px solid ${theme.palette.mode === 'dark' ? '#FFFA' : '#000A'}`
            : undefined,
        backgroundColor: unused
          ? 'transparent'
          : disconnected
          ? 'black'
          : error
          ? 'white'
          : off
          ? 'transparent'
          : 'white',
        height: (theme) => theme.spacing(3),
        width: (theme) => theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: !unused ? 'rgba(0, 0, 0, 0.4) 0 3px 4px 0;' : undefined,
        marginX: 0.5,
      }}
    >
      <Box
        className="Zone-status"
        sx={{
          borderRadius: '10px',
          backgroundColor: unused
            ? 'transparent'
            : disconnected
            ? 'gray'
            : error
            ? 'error.main'
            : off
            ? 'transparent'
            : warning
            ? 'warning.main'
            : 'success.main',
          height: (theme) => theme.spacing(2),
          width: (theme) => theme.spacing(2),
        }}
      ></Box>
    </Box>
  );
};
