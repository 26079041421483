import { Card, CardContent, CardHeader } from '@mui/material';

export interface ShelfCardContainerProps {
  children: React.ReactNode;
  title: React.ReactNode;
}
export const ShelfCardContainer = (props: ShelfCardContainerProps) => {
  const { children, title } = props;
  return (
    <Card
      sx={{
        backgroundColor: '#333333',
        flex: '1 1 0px',
        height: 'fit-content',
        overflow: 'initial',
        mt: 2,
      }}
    >
      <CardHeader
        sx={{ textAlign: 'center', borderBottom: '1px solid #616161' }}
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent
        sx={{
          display: 'grid',
          gridAutoFlow: 'row',
          alignItems: 'center',
          gap: '1rem',
          justifyContent: 'center',
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};
