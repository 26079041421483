import { useMemo } from 'react';
import { useAppSelector } from '../../../redux/store.model';
import { getFeatureToggle } from '../../../shared/featureToggle';
import {
  DEFAULT_TEMPERATURE_UNIT,
  TEMPERATURE_UNIT_TYPES,
} from '../Temperature.model';

function useTemperatureDefault() {
  const featureToggles = useAppSelector(getFeatureToggle);
  const temperatureDefault: TEMPERATURE_UNIT_TYPES = useMemo(
    () =>
      [
        TEMPERATURE_UNIT_TYPES.FAHRENHEIT,
        TEMPERATURE_UNIT_TYPES.CELSIUS,
        TEMPERATURE_UNIT_TYPES.DONT_CARE,
      ].includes(
        (featureToggles.TemperaturePreferenceDefault as
          | TEMPERATURE_UNIT_TYPES
          | undefined) ?? DEFAULT_TEMPERATURE_UNIT
      )
        ? (featureToggles.TemperaturePreferenceDefault as TEMPERATURE_UNIT_TYPES)
        : DEFAULT_TEMPERATURE_UNIT,
    [featureToggles.TemperaturePreferenceDefault]
  );

  return temperatureDefault;
}

export default useTemperatureDefault;
