import { createEntityAdapter } from '@reduxjs/toolkit';
import { schema } from 'normalizr';
import { GroupWithComposableDevice } from '../../service/group/group.model';
import { fromBase64 } from '../../shared/util/base64';
import {
  BusinessLayerGroupType,
  getTypeFromGroup,
} from './util/getTypeFromGroup';

const groupsSchema = new schema.Entity(
  'groups',
  {},
  {
    processStrategy: (value, parent, key) => {
      const parentGroupId = parent?.id;
      return {
        ...value,
        parentGroupId,
        uuid: fromBase64(value.id),
        businessLayerType: getTypeFromGroup(value),
      };
    },
  }
);

groupsSchema.define({
  childGroups: [groupsSchema],
});

export type NormalizedGroup = Omit<GroupWithComposableDevice, 'childGroups'> & {
  childGroups: string[] | null;
  uuid: string;
  parentGroupId?: string;
  businessLayerType: BusinessLayerGroupType;
};
export interface FlexeserveGroupStructure {
  entities: {
    groups: {
      [key: string]: NormalizedGroup;
    };
  };
  result: string[];
}

export const groupsListSchema = new schema.Array(groupsSchema);

export const groupStructureAdapter =
  createEntityAdapter<FlexeserveGroupStructure>();

export const groupStructureInitialState =
  groupStructureAdapter.getInitialState();
