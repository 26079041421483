import _ from 'lodash';
import orComparator from '../../../shared/util/lodash/merge/orComparator';
import useMyWorkspace from '../../workspace/hooks/useMyWorkspace';
import AlarmAPI from '../AlarmAPI';
import { UseQuerySubscription } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useMemo } from 'react';
import StructureAPI from '../../structure/StructureAPI';
import { getDevicesIdsFromGroupSelector } from '../../structure/selectors/Group.selector';
import { useAppSelector } from '../../store.model';
import { ALARMS_POLLING_INTERVAL } from '../Alarm.model';

type Options = Parameters<UseQuerySubscription<any>>[1];

function useAlarmsByGroup(
  args: {
    groupId?: string;
  },
  options: Options = {}
) {
  const { data: workspaceData, ...restWorkspaceAPI } = useMyWorkspace(
    undefined,
    options
  );
  const { data: alarmsData, ...restAlarmAPI } =
    AlarmAPI.useGetMyIncidentByWorkspaceIdQuery(
      {
        workspaceId: workspaceData?.id ?? '',
      },
      {
        skip: !workspaceData?.id,
        pollingInterval: ALARMS_POLLING_INTERVAL,
        ...options,
      }
    );

  const { data: devicesAPI, ...restDevicesAPI } =
    StructureAPI.useGetStructureV2Query(undefined, options);
  const devicesIdsFromGroup = useAppSelector((state) =>
    getDevicesIdsFromGroupSelector(state, {
      groupId: args?.groupId ?? '',
    })
  );

  const data = useMemo(
    () =>
      alarmsData?.filter?.((alarm) =>
        devicesIdsFromGroup?.includes(alarm.deviceId)
      ),
    [alarmsData, devicesIdsFromGroup]
  );

  const response = _.chain(restAlarmAPI)
    .mergeWith(restDevicesAPI, orComparator)
    .mergeWith(restWorkspaceAPI, orComparator)
    .value();
  return {
    ...response,
    data,
  };
}

export default useAlarmsByGroup;
