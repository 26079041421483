import React from 'react';
import * as yup from 'yup';
import Paper from '@mui/material/Paper';
import Input from '../../../theme/ui/Atoms/Input';
import Button from '../../../theme/ui/Atoms/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useErrorMessage from '../../../shared/hooks/useErrorMessage';
import { useTranslation } from 'react-i18next';
import { loginManager } from '../../../redux/auth-manager/action/thunks';
import LoginFooter from '../Login/LoginFooter';
import LoginHeaderLogo from '../Login/LoginHeaderLogo';
import ContainerFloatCenter from '../../../theme/ui/Layout/ContainerFloatCenter';

interface LoginManagerProps extends ConnectedComponentProps {}
function LoginManager(props: LoginManagerProps) {
  const navigate = useNavigate();
  const errorMessage = useErrorMessage();
  const { t } = useTranslation();

  const validationSchema = yup.object({
    email: yup.string().required(t('login.email_required') ?? ''),
    password: yup.string().required(t('login.password_required') ?? ''),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) =>
      props
        .login(values)
        .then(() => {
          navigate('/manager/view-locations');
        })
        .catch((error) => {
          const message =
            error.code === 'ERR_BAD_REQUEST'
              ? t('error.login_failed')
              : error.message;
          errorMessage(message);
        }),
  });
  return (
    <ContainerFloatCenter>
      <LoginHeaderLogo />
      <Paper elevation={5} sx={{ padding: 3 }}>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h1">{t('login.title_manager')}</Typography>
          <Input
            id="email"
            name="email"
            type={'email'}
            sx={{ mt: 2, mb: 2 }}
            label={t('login.email') ?? ''}
            placeholder={t('login.email_placeholder') ?? ''}
            required
            aria-required
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={(formik.touched.email && formik.errors.email) || ''}
          />
          <Input
            id="password"
            name="password"
            sx={{ mt: 2, mb: 2 }}
            label={t('login.password')}
            placeholder={t('login.password_placeholder') ?? ''}
            type="password"
            required
            aria-required
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              (formik.touched.password && formik.errors.password) || ''
            }
          />

          <Button
            variant="contained"
            size="large"
            rounded
            sx={{ mt: 4, mb: 2 }}
            type="submit"
            loading={formik.isSubmitting}
            fullWidth
          >
            {t('login.button_title')}
          </Button>
        </form>
      </Paper>
      <LoginFooter />
    </ContainerFloatCenter>
  );
}

const connector = connect(null, {
  login: loginManager,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(LoginManager);
