import React from 'react';
import { Box, LinearProgress, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CommanderUpdateLastStateResponseAPI,
  CommanderUpdateStateResponseAPI,
  CommanderUpdateStateType,
} from '../../../../service/device/CommanderUpdate/CommanderUpdate.model';
import getProgressFromStep from '../../../../service/device/CommanderUpdate/util/getProgressFromStep';
interface CommanderUpdateSteeperProps {
  stateInstallation?: CommanderUpdateStateResponseAPI;
  lastStateInstallation?: CommanderUpdateLastStateResponseAPI;
  isFetching?: boolean;
}
function CommanderUpdateSteeper(props: CommanderUpdateSteeperProps) {
  const { stateInstallation, lastStateInstallation, isFetching } = props;
  const { t } = useTranslation();

  const step =
    isFetching &&
    stateInstallation?.step === CommanderUpdateStateType.INSTALLATION_FINISHED
      ? CommanderUpdateStateType.CUSTOM
      : stateInstallation?.step ||
        lastStateInstallation?.step ||
        CommanderUpdateStateType.CUSTOM;
  const progress = getProgressFromStep(step);
  if (!stateInstallation) {
    return null;
  }
  return (
    <Box textAlign={'center'} mb={6}>
      <Typography>
        {t(`initial_setup.update_commander.states.${step}`, {
          defaultValue: step,
        })}
      </Typography>
      <Typography variant="h4" my={2}>
        {progress}%
      </Typography>
      <BiggerLinearProgress variant="determinate" value={progress} />
    </Box>
  );
}

const BiggerLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: 20,
  width: '25rem',
  margin: 'auto',
}));

export default CommanderUpdateSteeper;
