import { Box, Grid, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../theme/ui/Atoms/Button';
import ContainerBox from '../ContainerBox';
import { CheckIcon } from '../../../../assets';
import UserAPI from '../../../../redux/user/UserAPI';
import moment from 'moment';
import useWatchError from '../../../../shared/hooks/useWatchError';

function SetupSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickNext = () => {
    navigate('/');
  };

  const {
    data: myUser,
    error: errorUser,
    isLoading: isLoadingUser,
  } = UserAPI.useGetMyUserQuery();
  // FIXME: use the correct API to fetch the user and date
  const date = moment().format('LL');
  const [dateFinish] = useState(date);

  const name = myUser?.name || myUser?.email;

  useWatchError(errorUser);

  return (
    <ContainerBox>
      <Typography variant="h1" textAlign={'center'}>
        {t('initial_setup.finish_installation.title')}
      </Typography>

      <Box textAlign={'center'} my={4}>
        <CheckIcon height={'4rem'} />
        <Typography mt={2}>
          {isLoadingUser ? (
            <Skeleton width={200} />
          ) : (
            <Trans
              i18nKey="initial_setup.finish_installation.description"
              components={{ strong: <strong /> }}
              values={{ user: name }}
            />
          )}
        </Typography>

        <Typography>
          <Trans
            i18nKey="initial_setup.finish_installation.description_time"
            components={{ strong: <strong /> }}
            values={{ time: dateFinish }}
          />
        </Typography>
      </Box>

      <Grid container spacing={2} mt={2} justifyContent={'center'}>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            rounded
            fullWidth
            onClick={onClickNext}
          >
            {t('initial_setup.finish_installation.go_home')}
          </Button>
        </Grid>
      </Grid>
    </ContainerBox>
  );
}

export default SetupSuccess;
