import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalInfo } from '../../../../../theme/ui/Atoms/Modal';
import Button from '../../../../../theme/ui/Atoms/Button';
import useErrorMessage from '../../../../../shared/hooks/useErrorMessage';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../redux/store.model';
import { asyncTasksActions } from '../redux/slice/AsyncTasksSlice';
import { filterNotEmpty } from '../../../../../shared/ArrayUtil';
import { getZoneSettingsUpdateRequiredState } from '../redux/selectors';
import { useTick } from '../../../../../shared/hooks/useTick';
import moment from 'moment';
import { TIME_REFRESH_NOW } from '../constants';
import { getDevicesWithZoneSettingsSelector } from '../../../../../service/store/modules/zone/selectors';
import ZoneApi from '../../../../../service/store/modules/zone/ZoneApi';
import _ from 'lodash';

export default function DeviceSettingsChangeDetectorModal() {
  const { t } = useTranslation<string>();
  const devices = useAppSelector(getDevicesWithZoneSettingsSelector);
  const zoneSettingsLoading = devices.some(
    ({ zoneSettingsLoading }) => zoneSettingsLoading
  );
  const dispatch = useAppDispatch();
  const errorMessage = useErrorMessage();
  const zoneSettingsUpdateRequiredState = useAppSelector(
    getZoneSettingsUpdateRequiredState
  );
  const now = useTick(() => moment().valueOf(), TIME_REFRESH_NOW);
  const devicesWithUpdateRequired = useMemo(
    () =>
      devices

        // filter devices with update required
        .filter((device) => device.zoneSettings?.updateRequired)
        // filter devices not skipped
        .filter(({ device }) => {
          const taskAsyncState = zoneSettingsUpdateRequiredState[device.uuid];
          const isSkipped =
            taskAsyncState?.skip &&
            taskAsyncState?.dismissUntil &&
            taskAsyncState?.dismissUntil > now;
          return !isSkipped;
        }),
    [devices, now, zoneSettingsUpdateRequiredState]
  );
  const devicesWithUpdateRequiredConnected = useMemo(
    () =>
      devicesWithUpdateRequired.filter(
        (device) => device.device.deviceState?.mqttConnected
      ),
    [devicesWithUpdateRequired]
  );
  const hasSomeDeviceDisconnected = !!(
    devicesWithUpdateRequired.length - devicesWithUpdateRequiredConnected.length
  );

  const [updateRequireDevice, { isLoading: isLoadingUpdate }] =
    ZoneApi.useUpdateRequiredFromSettingsZoneDeviceMutation();

  const onClickConfirm = () => {
    Promise.all(
      _.uniqBy(devicesWithUpdateRequiredConnected, 'commanderId').map(
        ({ device }) => updateRequireDevice(device.commanderId).unwrap()
      )
    ).catch(errorMessage);
  };

  const onClickDismiss = () => {
    dispatch(
      asyncTasksActions.skipZoneSettingsUpdateRequired(
        devicesWithUpdateRequiredConnected
          .map(({ device }) => device.uuid)
          .filter(filterNotEmpty)
      )
    );
  };

  const loading = isLoadingUpdate || zoneSettingsLoading;
  const showModal = !!devicesWithUpdateRequiredConnected.length;
  return (
    <ModalInfo
      open={showModal}
      description={
        <>
          <p>{t('operator.modal_update.description')}</p>
          <ul>
            {devicesWithUpdateRequired.map(({ device }) => (
              <li key={device.uuid}>
                {device.storeName}: {device.unitName}{' '}
                {!device.deviceState?.mqttConnected
                  ? t('operator.modal_update.device_disconnected')
                  : ''}
              </li>
            ))}
          </ul>
          <p>
            {hasSomeDeviceDisconnected
              ? t('operator.modal_update.description_action_disconnected')
              : t('operator.modal_update.description_action')}
          </p>
        </>
      }
      title={t('operator.modal_update.title')}
      buttonActions={
        <>
          <Button
            autoFocus
            variant="text"
            onClick={onClickDismiss}
            disabled={loading}
          >
            {t('operator.modal_update.dismiss_today')}
          </Button>
          <Button
            autoFocus
            variant="contained"
            onClick={onClickConfirm}
            loading={loading}
          >
            {t('button.confirm')}
          </Button>
        </>
      }
    />
  );
}
