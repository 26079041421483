import { createSelector } from '@reduxjs/toolkit';
import { getDeviceByBase64DeviceIdSelector } from '../../location/selectors';
import { RootState } from '../../store.model';
import { ZipDeviceControlUnit } from '../controlUnit.model';
import { ControlUnitState } from '../slice/controlUnitSlice';
import { DeviceControlUnitsAPIResponse } from '../../../service/device/device.model';
import { toBase64 } from '../../../shared/util/base64';
import { DevicesStoreInfoResponse } from '../../../service/store/StoreService.model';

interface OptionsNavProps {
  controlUnitId?: string;
  groupId?: string;
  deviceId?: string;
}

const getDeviceIdFromProps = (state: RootState, props: { deviceId: string }) =>
  props.deviceId;
export const getControlUnitQueryParam = (
  _: RootState,
  props: OptionsNavProps
) => props.controlUnitId;

export const getControlUnitSelectedByQueryParamSelector = createSelector(
  [
    getDeviceIdFromProps,
    getControlUnitQueryParam,
    (state: RootState) => state.controlUnit.dictionaryControlUnit,
  ],
  (deviceId, controlUnitId, dictionaryControlUnit) => {
    return dictionaryControlUnit[deviceId ?? '']?.[controlUnitId ?? ''];
  }
);

export const getDeviceControlUnitsById = (
  state: RootState,
  props: { deviceId: string }
) => {
  const dictionary = state.controlUnit.dictionaryControlUnit[props.deviceId];
  if (dictionary) return Object.values(dictionary);
  return undefined;
};

export const getControlUnitById = createSelector(
  [
    getDeviceControlUnitsById,
    (_state, props: { controlUnitId: string }) => props.controlUnitId,
  ],
  (controlUnits, controlUnitId) =>
    controlUnits?.find(
      (controlUnitCurrent) => controlUnitCurrent.id === controlUnitId
    )
);

export const getDeviceControlUnitsLoadingById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.loadingDeviceControlUnit[props.deviceId];

export const getDeviceControlUnitsErrorById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.errorDeviceControlUnit[props.deviceId];

export const getControlUnitsSensorDataById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.dictionaryCurrentSensorDataControlUnit[props.deviceId];

export const getControlUnitsSensorDataLoadingById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.loadingCurrentSensorDataControlUnit[props.deviceId];

export const getControlUnitsSensorDataErrorById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.errorCurrentSensorDataControlUnit[props.deviceId];

export const getControlUnitsActiveById = createSelector(
  [getDeviceControlUnitsById],
  (controlUnits) => {
    return controlUnits?.filter(
      (controlUnit) =>
        // Normal case
        controlUnit.state === 'ACTIVE' ||
        controlUnit.detailedState === 'INSTALLED' ||
        // Locally disconnected (but not uninstalled)
        controlUnit.detailedState === 'DISCONNECTED'
    );
  }
);

export const getControlUnitComposableDevice = (props: {
  controlUnits: DeviceControlUnitsAPIResponse[];
  device: DevicesStoreInfoResponse;
}) => {
  const { controlUnits, device } = props;
  const composableMapping = Object.values(device.composableZoneMapping ?? {});
  if (!composableMapping.length) return controlUnits;
  const acceptableControlUnits = device.shelfMappings?.filter((mapping) =>
    composableMapping.includes(`${mapping?.id}`)
  );

  return controlUnits.filter((controlUnit) =>
    acceptableControlUnits?.find(
      (mapping) => `${mapping?.cuid}` === controlUnit.id
    )
  );
};

export const getControlUnitsVisibleById = createSelector(
  [getControlUnitsActiveById],
  (controlUnits) => {
    return controlUnits?.filter(
      (controlUnit) =>
        // TODO: Please use another way to identify CommanderCU
        // eslint-disable-next-line eqeqeq
        controlUnit.id != '1002'
    );
  }
);

export const getControlUnitIdsActiveById = createSelector(
  [getControlUnitsActiveById],
  (controlUnits) => controlUnits?.map((controlUnit) => controlUnit.id)
);

export const getZipControlUnitsByDeviceIds = (
  state: RootState,
  props: { devices: DevicesStoreInfoResponse[] }
): ZipDeviceControlUnit[] => {
  return props.devices.map((device) => {
    const { uuid: deviceId = '', commanderId = '' } = device;
    const encodedDeviceId = toBase64(deviceId);
    const allControlUnits =
      getControlUnitsActiveById(state, {
        deviceId: encodedDeviceId,
      }) ?? [];
    const controlUnits = getControlUnitComposableDevice({
      controlUnits: allControlUnits,
      device,
    });
    const loading = getDeviceControlUnitsLoadingById(state, {
      deviceId: encodedDeviceId,
    });
    const error = getDeviceControlUnitsErrorById(state, {
      deviceId: encodedDeviceId,
    });
    const deviceName =
      getDeviceByBase64DeviceIdSelector(state, {
        deviceId: encodedDeviceId,
      })?.unitName ?? deviceId;
    return {
      ...device,
      deviceId: encodedDeviceId,
      controlUnits,
      commanderId,
      loading,
      error,
      deviceName,
    };
  });
};

export const getSensorDataMetadataFromControlUnitState = (
  dictionaryControlUnitState: ControlUnitState['dictionaryControlUnit'],
  deviceId: string,
  controlUnitId: string,
  channelName: string
) => {
  const controlUnit = dictionaryControlUnitState[deviceId]?.[controlUnitId];
  const sensorMetadata = controlUnit?.channels.find(
    (channel) => channel.id === channelName
  );
  return sensorMetadata;
};
