import { GroupAPIResponse } from '../../../service/group/group.model';
import { NormalizedGroup } from '../GroupsAdapter';

export enum BusinessLayerGroupType {
  BusinessUnit = 'businessUnit',
  Region = 'region',
  District = 'district',
  Store = 'store',
  Unknown = 'unknown',
}
export function getTypeFromGroup(group?: GroupAPIResponse | NormalizedGroup) {
  if (group?.attributes?.['FS_BusinessUnit_ID'])
    return BusinessLayerGroupType.BusinessUnit;
  if (group?.attributes?.['FS_Region_ID']) return BusinessLayerGroupType.Region;
  if (group?.attributes?.['FS_Market_ID'])
    return BusinessLayerGroupType.District;
  if (group?.attributes?.['FS_Store_ID']) return BusinessLayerGroupType.Store;
  return BusinessLayerGroupType.Unknown;
}
