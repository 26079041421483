import { toBase64 } from '../../../shared/util/base64';
import StructureAPI from '../StructureAPI';
import { StoreMetadataAPIResponse } from './StoreStructure.model';

const StoreStructureAPI = StructureAPI.injectEndpoints({
  endpoints: (build) => ({
    getStoreMetadataByExternalId: build.query<
      StoreMetadataAPIResponse,
      {
        externalId: string;
        workspaceId: string;
      }
    >({
      query: ({ externalId, workspaceId }) => {
        const encodedExternalID = toBase64(externalId);
        const encodedWorkspaceID = toBase64(workspaceId);
        return {
          url: `flexeserve/workspace/${encodedWorkspaceID}/store/metadata/${encodedExternalID}`,
          method: 'get',
        };
      },
    }),
    linkStoreMetadataToAGroup: build.mutation<
      any,
      {
        externalId: string;
        workspaceId: string;
        groupId: string;
      }
    >({
      query: ({ externalId, workspaceId, groupId }) => {
        const encodedExternalID = toBase64(externalId);
        const encodedWorkspaceID = toBase64(workspaceId);
        const encodedGroupID = toBase64(groupId);
        return {
          url: `flexeserve/workspace/${encodedWorkspaceID}/store/${encodedGroupID}/link/${encodedExternalID}`,
          method: 'post',
        };
      },
    }),
  }),
});

export default StoreStructureAPI;
