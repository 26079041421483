import { createSelector } from '@reduxjs/toolkit';
import ZoneSettingsAPI from '../../zoneSettings/ZoneSettingsAPI';
import ScheduleAPI from '../ScheduleAPI';
import { getDevicesByRegionSelector } from '../../locations/selectors/getDevicesByRegionSelector';
import { getReduceRestrictionFromDevices } from '../../locations/selectors/getDeviceTemperatureRestrictionSelector';
import { RootState } from '../../../redux/store.model';
import { acceptsTemperatureLimits } from './filterValidSchedulesSelector';
import getNumberZonesDayPart from '../util/getNumberZonesDayPart';

const getDevicesRestrictionMapOnZoneIdByScheduleSelector = createSelector(
  [
    getDevicesByRegionSelector,
    (state, { scheduleId }: { scheduleId?: string }) => scheduleId,
  ],
  (devices, scheduleId) => {
    if (!scheduleId) return {};

    // get devices that has a schedule, this schedule also has the day part with dayPartId
    const devicesFiltered = devices.filter((device) => {
      return device.schedule === scheduleId;
    });
    // reduce temperature restrictions by zoneId
    // { [zoneId]: { min: number, max: number } }
    const zonesRestrictionReduced =
      getReduceRestrictionFromDevices(devicesFiltered);

    return zonesRestrictionReduced;
  }
);

const getValidDayPartOptionsByZoneIdSelector = createSelector(
  [
    (state, { groupId }: { groupId: string }) =>
      ZoneSettingsAPI.endpoints.getZoneSettings.select(groupId)(state),
    (state, { groupId }: { groupId: string }) =>
      ScheduleAPI.endpoints.getDayPartByGroup.select(groupId)(state),

    getDevicesRestrictionMapOnZoneIdByScheduleSelector,
    (state: RootState, { numberZones }: { numberZones: number }) => numberZones,
  ],
  (zoneSettingsData, dayPartsData, restrictionByZone, numberZones) => {
    const zoneSettings = zoneSettingsData.data ?? [];
    const dayParts = dayPartsData.data ?? [];

    const dayPartsOptions = dayParts.map((dayPart) => {
      const disabled = dayPart.shelfSettings.some((shelfSetting) => {
        const deviceRestriction = restrictionByZone[shelfSetting.zoneId];
        const setpoint = zoneSettings.find(
          (zoneSetting) => zoneSetting.id === shelfSetting.setting
        )?.setpoint;

        return !acceptsTemperatureLimits(deviceRestriction, setpoint);
      });
      const error = disabled
        ? 'schedule.error.temperatureSetpointLimits'
        : undefined;
      const visible =
        getNumberZonesDayPart(dayPart.shelfSettings) === numberZones;

      return {
        dayPart,
        error,
        disabled,
        visible,
      };
    });

    // remove day parts that are not visible
    return dayPartsOptions.filter((dayPart) => dayPart.visible);
  }
);

export default getValidDayPartOptionsByZoneIdSelector;
