import moment from 'moment';
import { waitPromise } from '../../shared/util/waitPromise';

const NUM_TRIES = 20;
const SECONDS_TIMEOUT = 60 * 2;

/**
 * Take the promise and recursively call it until the confirmComparator returns true
 *
 * @param promiseFactory
 * @param confirmComparator
 * @param
 * @param
 * @param recursiveExponentialBackOff
 */
function recursiveExponentialBackOff<T>(
  promiseFactory: () => Promise<T>,
  confirmComparator: (response: T) => boolean,
  startTime = moment(),
  availableTries = NUM_TRIES
): Promise<T> {
  if (availableTries <= 0) {
    throw new Error('error.available_attempts_exceeded');
  }
  if (moment().diff(startTime, 'seconds') > SECONDS_TIMEOUT) {
    throw new Error('error.timeout_exceeded');
  }
  return promiseFactory().then((response) => {
    if (confirmComparator(response)) {
      return response;
    }
    const exponentialBackOffSeconds = 2 ** (NUM_TRIES - availableTries);
    return waitPromise(1000 * exponentialBackOffSeconds).then(() =>
      recursiveExponentialBackOff(
        promiseFactory,
        confirmComparator,
        startTime,
        availableTries - 1
      )
    );
  });
}

export default recursiveExponentialBackOff;
