import { AxiosInstance } from 'axios';
import { mapControlUnitFilterLogicalObjectToAPIFilter } from './util/mapControlUnitFilterLogicalObjectToAPIFilter';
import {
  DigitalEventsControlUnits,
  Sensordata,
  SensordataNewest,
} from './monitoring.model';
import AxiosFlexeServeInstance from '../shared/AxiosFlexeServe';
import { CommanderControlUnitChannelFilterLogicalObject } from '../../pages/AuthenticatedPages/Manager/ManagerPerformances/util/filter.model';
import { toBase64 } from '../../shared/util/base64';

export class MonitoringCalls {
  readonly sensorDataServiceURL = 'sensordata';
  readonly eventServiceURL = 'event';
  constructor(private axios: AxiosInstance = AxiosFlexeServeInstance) {}

  getSensordataByDeviceID(
    commanderId: string,
    start = 0,
    end = 0,
    commanderFilterObject: CommanderControlUnitChannelFilterLogicalObject
  ) {
    const filter = mapControlUnitFilterLogicalObjectToAPIFilter(
      commanderFilterObject
    );
    if (filter === '{}') return Promise.resolve([]);
    const url = `${this.sensorDataServiceURL}/${toBase64(
      commanderId
    )}?start=${start}&end=${end}&filter=${filter}&onlyValid=true`;
    return this.axios.get<Sensordata[]>(url).then((res) => res.data);
  }

  getEventsByID(
    commanderId: string,
    start = 0,
    end = 0,
    commanderFilterObject: CommanderControlUnitChannelFilterLogicalObject
  ) {
    const filter = mapControlUnitFilterLogicalObjectToAPIFilter(
      commanderFilterObject
    );
    if (filter === '{}') return Promise.resolve([]);
    const url = `${this.eventServiceURL}/${toBase64(
      commanderId
    )}?start=${start}&end=${end}&filter=${filter}&onlyValid=true`;
    return this.axios
      .get<DigitalEventsControlUnits[]>(url)
      .then((res) => res.data);
  }

  getCurrentEventsByID(params: { deviceId: string; commanderId: string }) {
    const { deviceId, commanderId } = params;

    const url = `${this.eventServiceURL}/${toBase64(commanderId)}/newest`;
    return this.axios
      .get<DigitalEventsControlUnits[]>(url)
      .then((res) => ({ deviceId, commanderId, ...res.data }));
  }

  getCurrentSensorDataByID(commanderId: string) {
    const url = `${this.sensorDataServiceURL}/${toBase64(commanderId)}/newest`;
    return this.axios.get<SensordataNewest[]>(url).then((res) => res.data);
  }
}
