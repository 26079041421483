import {
  Box,
  Checkbox,
  Container,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import TitleIcon from '../../../../theme/ui/Atoms/TilteIcon/TitleIcon';
import { CheckListIcon } from '../../../../assets';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '../../../../theme/ui/Atoms/Button';
import useBack from '../../../../shared/util/route-dom/hooks/useBack';
import { useNavigate } from 'react-router-dom';
import PaperButton from '../../../../theme/ui/Atoms/PaperButton';
import BackButton from '../BackButton';

const validationSchema = yup.object({
  '1': yup.boolean().isTrue().required(),
  '2': yup.boolean().isTrue().required(),
  '3': yup.boolean().isTrue().required(),
});

const list_items = [
  { key: '1', label: 'initial_setup.checklist.checklist_item_1' },
  { key: '2', label: 'initial_setup.checklist.checklist_item_2' },
  { key: '3', label: 'initial_setup.checklist.checklist_item_3' },
  { key: '4', label: 'initial_setup.checklist.checklist_item_4' },
] as const;

const initialValues = {
  '1': false,
  '2': false,
  '3': false,
  '4': false,
};

function CheckListSetUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      navigate('../actions/commander-update');
    },
  });
  const onClickBack = useBack('/');

  return (
    <Container maxWidth="xl">
      <TitleIcon
        icon={<CheckListIcon height={'2em'} />}
        subtitle={t('initial_setup.checklist.description')}
      >
        {t('initial_setup.checklist.title')}
      </TitleIcon>

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Box my={6} minHeight={'200px'}>
          <form>
            <Stack spacing={2}>
              {list_items.map((item) => (
                <PaperButton
                  key={item.key}
                  elevation={undefined}
                  onClick={() =>
                    formik.setFieldValue(item.key, !formik.values[item.key])
                  }
                >
                  <ListItem>
                    <ListItemIcon>
                      <Checkbox
                        name={item.key}
                        checked={formik.values[item.key]}
                      />
                    </ListItemIcon>
                    <ListItemText primary={t(item.label)} />
                  </ListItem>
                </PaperButton>
              ))}
              {!formik.isValid && (
                <Typography sx={{ color: 'error.main' }}>
                  {t('initial_setup.checklist.all_items_is_required')}
                </Typography>
              )}
            </Stack>
          </form>
        </Box>

        <Grid container spacing={2} justifyContent={'space-between'} mt={2}>
          <Grid item xs={12} sm={2}>
            <BackButton onClick={onClickBack} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              rounded
              fullWidth
              disabled={!formik.isValid}
              onClick={() => formik.handleSubmit()}
            >
              {t('button.next')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default CheckListSetUp;
