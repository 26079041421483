import { DeviceAPI } from '../DeviceAPI';
import CommanderAirConnectAPIService from './CommanderAirConnectAPIService';
import { toBase64 } from '../../../shared/util/base64';
import {
  AirConnectNode,
  AirConnectNodeScanned,
  CommanderAirConnectCreateComposableDeviceAPI,
} from './CommanderAirConnectAPI.model';

const CommanderAirConnectAPI = DeviceAPI.injectEndpoints({
  endpoints: (build) => ({
    getNodesToInstall: build.query<AirConnectNodeScanned[], string>({
      async queryFn(commanderId, _queryApi, _extraOptions) {
        const encodedCommanderId = toBase64(commanderId);
        const response =
          await CommanderAirConnectAPIService.getNodesAvailableToPair(
            encodedCommanderId
          )
            .then((data) => ({ data, meta: {} }))
            .catch((error) => ({
              error: error.message,
              data: undefined,
              meta: {},
            }));
        return response;
      },
      providesTags: ['commander-nodes-actions'],
    }),

    getNodesInstalled: build.query<AirConnectNode[], string>({
      async queryFn(commanderId, _queryApi, _extraOptions) {
        const encodedCommanderId = toBase64(commanderId);
        const response = await CommanderAirConnectAPIService.getNodesInstalled(
          encodedCommanderId
        )
          .then((data) => ({ data, meta: {} }))
          .catch((error) => ({
            error: error.message,
            data: undefined,
            meta: {},
          }));

        return response;
      },
      providesTags: ['commander-nodes-actions'],
    }),

    installNewNodes: build.mutation<
      string[],
      { commanderId: string; macAddresses: string[] }
    >({
      async queryFn({ commanderId, macAddresses }, _queryApi, _extraOptions) {
        const encodedCommanderId = toBase64(commanderId);
        const response = await CommanderAirConnectAPIService.installNodes(
          encodedCommanderId,
          macAddresses
        )
          .then((data) => ({ data, meta: {} }))
          .catch((error) => ({
            error: error.message,
            data: undefined,
            meta: {},
          }));

        return response;
      },
      invalidatesTags: ['commander-nodes-actions'],
    }),
    registerNewComposableDevice: build.mutation<
      string,
      {
        workspaceId: string;
        storeId: string;
        data: CommanderAirConnectCreateComposableDeviceAPI;
      }
    >({
      query: ({ workspaceId, storeId, data }) => {
        const encodedWorkspaceId = toBase64(workspaceId);
        const encodedStoreId = toBase64(storeId);

        return {
          url: `/flexeserve/register/workspace/${encodedWorkspaceId}/store/${encodedStoreId}/composable`,
          method: 'post',
          data,
        };
      },
      invalidatesTags: ['locations-actions'],
    }),
    reinstallNode: build.mutation<
      AirConnectNode | undefined,
      { commanderId: string; nodeNumber: number; nodeType: string }
    >({
      async queryFn(
        { commanderId, nodeNumber, nodeType },
        _queryApi,
        _extraOptions
      ) {
        const encodedCommanderId = toBase64(commanderId);
        const response =
          await CommanderAirConnectAPIService.reInstallControlUnitsFromNode(
            encodedCommanderId,
            nodeNumber,
            nodeType
          )
            .then((data) => ({ data, meta: {} }))
            .catch((error) => ({
              error: error.message,
              data: undefined,
              meta: {},
            }));

        return response;
      },
      invalidatesTags: ['commander-nodes-actions'],
    }),
  }),
});

export default CommanderAirConnectAPI;
