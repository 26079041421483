import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../../../../assets';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../theme/ui/Atoms/Button';
import ContainerBox from '../ContainerBox';

function CommanderUpdateSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickNext = () => {
    navigate('../reconnect-devices');
  };

  return (
    <ContainerBox>
      <Typography variant="h1" textAlign={'center'}>
        {t('initial_setup.update_commander.title')}
      </Typography>
      <Box textAlign={'center'} my={4}>
        <Typography my={2}>
          {t('initial_setup.update_commander.software_is_up_to_date')}
        </Typography>
        <CheckIcon height={'4rem'} />
      </Box>

      <Grid container spacing={2} justifyContent={'flex-end'}>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            rounded
            fullWidth
            onClick={onClickNext}
          >
            {t('button.next')}
          </Button>
        </Grid>
      </Grid>
    </ContainerBox>
  );
}

export default CommanderUpdateSuccess;
