import { getI18n } from 'react-i18next';
import { waitPromise } from '../../shared/util/waitPromise';
import moment from 'moment';

const NUM_TRIES = 20;
const SECONDS_TIMEOUT = 60 * 2;

type CorrelationResponse = {
  status: string;
  response?: {
    subtype?: string;
    correlationId?: string;
    result?: any;
    error?: string;
    message?: string | null;
  } | null;
};
async function confirmCorrelationIdRecursive(
  encodedDeviceID: string,
  correlationID: string,
  getStatusByCorrelationIdPromise: (
    encodedDeviceID: string,
    correlationID: string
  ) => Promise<CorrelationResponse>,
  startTime = moment(),
  availableTries = NUM_TRIES
): Promise<CorrelationResponse> {
  if (availableTries <= 0) {
    throw new Error('error.available_attempts_exceeded');
  }
  if (moment().diff(startTime, 'seconds') > SECONDS_TIMEOUT) {
    throw new Error('error.timeout_exceeded');
  }
  const statusRequest = await getStatusByCorrelationIdPromise(
    encodedDeviceID,
    correlationID
  );
  switch (statusRequest.status) {
    case 'NOT_FOUND':
      throw new Error('error.correlation_id_not_found');
    case 'PENDING':
      // add some delay with exponential backOff with 2^i to avoid too many requests
      const exponentialBackOffSeconds = 2 ** (NUM_TRIES - availableTries);
      await waitPromise(1000 * exponentialBackOffSeconds);
      return confirmCorrelationIdRecursive(
        encodedDeviceID,
        correlationID,
        getStatusByCorrelationIdPromise,
        startTime,
        availableTries - 1
      );
    case 'DEVICE_REPLIED':
      if (
        (statusRequest?.response?.error &&
          statusRequest?.response.error !== 'NONE') ||
        statusRequest?.response?.result?.status === 'ERROR'
      ) {
        const { t } = getI18n();
        const message =
          statusRequest?.response?.error &&
          statusRequest?.response?.error !== 'NONE'
            ? `MQTT ${statusRequest.response?.error}: ${statusRequest.response?.message}`.substring(
                0,
                500
              )
            : `MQTT ${statusRequest.response.result.errorMessage}`.substring(
                0,
                500
              );
        throw new Error(
          t('error.mqtt_error', {
            replace: {
              message,
            },
          }) ?? 'error.mqtt_error'
        );
      }
      return statusRequest;
    default:
      throw new Error(`error.${statusRequest}`);
  }
}

export default confirmCorrelationIdRecursive;
