/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { ReactComponent as SettingsIcon } from '../../../../../../assets/settings.svg';
import { ReactComponent as EyeIcon } from '../../../../../../assets/eye.svg';
import { ReactComponent as TemperatureIcon } from '../../../../../../assets/temperature.svg';
import { ReactComponent as IdCardIcon } from '../../../../../../assets/id_card.svg';
import { ReactComponent as ArrowBackIcon } from '../../../../../../assets/arrow_back.svg';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Input from '../../../../../../theme/ui/Atoms/Input';
import Switch from '../../../../../../theme/ui/Atoms/Switch';
import ZoneSettingsAPI from '../../../../../../service/zoneSettings/ZoneSettingsAPI';
import useWatchError from '../../../../../../shared/hooks/useWatchError';
import useErrorMessage from '../../../../../../shared/hooks/useErrorMessage';
import Button from '../../../../../../theme/ui/Atoms/Button';
import { useTemperaturePreference } from '../../../../../../service/temperaturePreference';
import {
  DEFAULT_NUMBER_DECIMALS_SAVE,
  TEMPERATURE_UNIT_TYPES,
} from '../../../../../../service/temperaturePreference';
import { Temperature } from '../../../../../../service/temperaturePreference/Temperature';
import { ZoneSettingWitIdResponse } from '../../../../../../service/zoneSettings/ZoneSettingsAPI.model';
import useValidateTemperatureRestriction from '../../../../../../service/schedule/hook/useValidateZoneSettings';
import { temperatureSetpointLimitsModalErrorPromise } from '../../../TemperatureSetpointLimits/TemperatureSetpointLimitsModalError';

const StyledSettingsIcon = styled(SettingsIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
  marginRight: theme.spacing(1),
}));

const StyledWhiteSettingsIcon = styled(SettingsIcon)(({ theme }) => ({
  fill: theme.palette.text.primary,
}));

const StyledEyeIcon = styled(EyeIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));

const StyledTemperatureIcon = styled(TemperatureIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));

const StyledIdCardIcon = styled(IdCardIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

function addfarenhit(data?: ZoneSettingWitIdResponse[]) {
  return data?.map((item) => ({
    ...item,
    setpointFarenhit: Temperature.convertCelsiusToFahrenheitRound(
      item.setpoint,
      DEFAULT_NUMBER_DECIMALS_SAVE
    ),
  }));
}

export default function ManagerZoneSettings() {
  const { t } = useTranslation();
  const { businessUnitId = '' } = useParams();

  const navigate = useNavigate();
  const errorMessage = useErrorMessage();
  const { isFetching, isLoading, error, data } =
    ZoneSettingsAPI.useGetZoneSettingsByGroupQuery(businessUnitId, {
      refetchOnMountOrArgChange: true,
    });

  const dataMaped = useMemo(() => addfarenhit(data), [data]);
  const [updateZone, { isLoading: isLoadingUpdate }] =
    ZoneSettingsAPI.useSetZoneSettingsByGroupMutation();
  const busy = isFetching || isLoading || isLoadingUpdate;

  useWatchError(error);
  useEffect(() => {
    formik.setValues(dataMaped ?? []);
  }, [dataMaped]);

  const validationSchema = useMemo(
    () =>
      yup.array().of(
        yup.object({
          id: yup.string().required('error'),
          displayName: yup.string().required('error'),
          temperatureUnit: yup.string().required('error'),
          setpoint: yup.number().required('error'),
          setpointFarenhit: yup.number().required('error'),
          visible: yup.boolean().required('error'),
        })
      ),
    []
  );
  const validateZoneSettings =
    useValidateTemperatureRestriction(businessUnitId);
  const formik = useFormik({
    initialValues: dataMaped ?? [],
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      return Promise.resolve()
        .then(() => validateZoneSettings({ settings: values }))
        .then((errors) =>
          errors.length
            ? temperatureSetpointLimitsModalErrorPromise({
                errors,
                temperaturePreference,
                description: t(
                  'schedule.temperatureSetpointLimits.zoneSettingsModal.description'
                ),
                followings: t(
                  'schedule.temperatureSetpointLimits.zoneSettingsModal.following'
                ),
              })
            : null
        )
        .then(() =>
          updateZone({ groupId: businessUnitId, zoneSetting: values }).unwrap()
        )
        .then(onClickBack)
        .catch(errorMessage);
    },
  });

  const [numberRows, setNumberRows] = useState(formik.values.length);
  useEffect(() => {
    if (formik.values.length !== numberRows) {
      setNumberRows(formik.values.length);
    }
  }, [formik.values]);
  const array = useMemo(() => {
    return Array.from(Array(numberRows).keys());
  }, [numberRows]);

  // Temperature
  const initialTemperaturePreference = useTemperaturePreference();
  const [temperaturePreference, setTemperaturePreference] = useState(
    initialTemperaturePreference
  );
  const toggleTemperature = () => {
    const newTemperature =
      temperaturePreference === TEMPERATURE_UNIT_TYPES.CELSIUS
        ? TEMPERATURE_UNIT_TYPES.FAHRENHEIT
        : TEMPERATURE_UNIT_TYPES.CELSIUS;
    setTemperaturePreference(newTemperature);
  };

  const temperaturePreferenceLabel = t(
    `settings.temperature_preference.${temperaturePreference}`
  );

  const getValueTemperature = (i: number) =>
    temperaturePreference === TEMPERATURE_UNIT_TYPES.CELSIUS
      ? formik.values?.[i].setpoint
      : formik.values?.[i].setpointFarenhit;

  const getEndAdorment = (i: number) => {
    if (temperaturePreference === TEMPERATURE_UNIT_TYPES.CELSIUS)
      return t('settings.temperature_preference.CELSIUS');

    return (
      <>
        <Typography
          fontSize={'0.8em'}
          maxWidth={'80px'}
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          whiteSpace={'nowrap'}
        >
          {`${t('settings.temperature_preference.FAHRENHEIT')}`}
          {` (${Temperature.round(+formik.values?.[i].setpoint, 1)} ${t(
            'settings.temperature_preference.CELSIUS'
          )})`}
        </Typography>
      </>
    );
  };

  const onChangeTemperature =
    (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (temperaturePreference === TEMPERATURE_UNIT_TYPES.CELSIUS) {
        formik.setFieldValue(`[${i}].setpoint`, value);
        formik.setFieldValue(
          `[${i}].setpointFarenhit`,
          Temperature.convertCelsiusToFahrenheitRound(
            +value,
            DEFAULT_NUMBER_DECIMALS_SAVE
          )
        );
        return;
      }

      // fahrenheit case
      formik.setFieldValue(
        `[${i}].setpoint`,
        Temperature.convertFahrenheitToCelsiusRound(
          +value,
          DEFAULT_NUMBER_DECIMALS_SAVE
        )
      );
      formik.setFieldValue(`[${i}].setpointFarenhit`, value);
    };

  const onClickBack = () => {
    navigate(`/manager/businessUnit/${businessUnitId}/actions`);
  };

  return (
    <Box
      sx={{
        height: '100%',
        maxHeight: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      component={'form'}
      onSubmit={formik.handleSubmit}
    >
      {/* <TemperatureSetpointLimitsModalError
        open
        close={(error) => alert(error)}
        isOpen={true}
        errors={errorsMocks}
        instanceId="sasd"
        onReject={(error) => alert(error)}
        onResolve={(value) => alert(value)}
      /> */}
      <Box
        className="zone-settings-header"
        sx={{
          paddingX: 4,
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          variant="subtitle2"
          marginY={2}
          sx={{
            fill: (theme) => theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '180px', display: 'flex', alignItems: 'center' }}>
            <TemperatureIcon height={'1em'} />
            <Switch
              checked={temperaturePreference === TEMPERATURE_UNIT_TYPES.CELSIUS}
              onChange={toggleTemperature}
              label={temperaturePreferenceLabel}
              sx={{ textAlign: 'center' }}
            />
          </Box>
          <Box component={'span'} marginRight={4}>
            {busy ? (
              <CircularProgress />
            ) : (
              <StyledWhiteSettingsIcon height={'2em'} />
            )}
          </Box>
          {t('zone_settings.title')}
        </Typography>
      </Box>

      <Box
        className="zone-settings-content"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingX: 4,
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {isFetching || isLoading ? null : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography
                color="primary"
                gutterBottom
                className="d-flex-row-center"
              >
                <StyledSettingsIcon height={'2em'} />
                {t('zone_settings.header_settings')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              className="d-flex-row-center"
              sx={{ justifyContent: 'space-between' }}
            >
              <Typography
                color="primary"
                gutterBottom
                className="d-flex-row-center"
              >
                <StyledTemperatureIcon height={'2em'} />
                {t('zone_settings.header_heat')}
              </Typography>
              <Typography
                color="primary"
                gutterBottom
                className="d-flex-row-center"
                textAlign="right"
                sx={{ paddingRight: 2 }}
              >
                {temperaturePreferenceLabel}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              sx={{ flexGrow: { md: 1 }, maxWidth: { md: '100%' } }}
            >
              <Typography
                color="primary"
                gutterBottom
                className="d-flex-row-center"
              >
                <StyledIdCardIcon height={'2em'} />
                {t('zone_settings.header_display_name')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              className="d-flex-row-center"
              sx={{ justifyContent: { md: 'center' } }}
            >
              <Typography
                color="primary"
                gutterBottom
                className="d-flex-row-center"
                sx={{ paddingBottom: { md: 2 } }}
              >
                <StyledEyeIcon height={'1.5em'} />
              </Typography>
            </Grid>

            {/* content */}
            {array?.map((_, index) => {
              if (!formik.values?.[index]) return null;
              return (
                <React.Fragment key={`[${index}]`}>
                  <Grid item xs={12} md={3}>
                    <Input
                      id={`[${index}].id`}
                      name={`[${index}].id`}
                      value={formik.values?.[index].id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched[index]?.id && formik.errors[index]?.id
                      )}
                      disabled={!formik.values?.[index].canDelete}
                      required
                      aria-required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Input
                      id={`[${index}].setpoint`}
                      name={`[${index}].setpoint`}
                      type="number"
                      sxInput={{
                        // extra size for endAdornment
                        width: 'calc( 100% - 5em)',
                      }}
                      value={getValueTemperature(index)}
                      onChange={onChangeTemperature(index)}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched[index]?.setpoint &&
                          formik.errors[index]?.setpoint
                      )}
                      required
                      disabled={!formik.values?.[index].canEditSetpoint}
                      aria-required
                      fullWidth
                      endAdornment={getEndAdorment(index)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{ flexGrow: { md: 1 }, maxWidth: { md: '100%' } }}
                  >
                    <Input
                      id={`[${index}].displayName`}
                      name={`[${index}].displayName`}
                      value={formik.values?.[index].displayName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched[index]?.displayName &&
                          formik.errors[index]?.displayName
                      )}
                      disabled={!formik.values?.[index].canEditName}
                      required
                      aria-required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={1} className="d-flex-center-center">
                    <Switch
                      slotProps={{
                        input: {
                          name: `[${index}].visible`,
                          id: `[${index}].visible`,
                        },
                      }}
                      checked={formik.values?.[index].visible}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched[index]?.visible &&
                          formik.errors[index]?.visible
                      )}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        )}
      </Box>
      <Box
        className="zone-settings-footer"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 2,
          paddingX: 4,
        }}
      >
        <Box>
          <Button
            variant="contained-gray"
            sx={{ height: '3em' }}
            onClick={onClickBack}
            disabled={busy}
          >
            <ArrowBackIcon height={'1em'} />
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          size="large"
          rounded
          disabled={busy}
        >
          {t('button.save')}
        </Button>
      </Box>
    </Box>
  );
}
