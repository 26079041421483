import React, { useMemo } from 'react';
import ZoneSettingsAPI from '../../../../../../../service/zoneSettings/ZoneSettingsAPI';
import { CircularProgress, ListItemText, Stack } from '@mui/material';
import Button from '../../../../../../../theme/ui/Atoms/Button/Button';

import useWatchError from '../../../../../../../shared/hooks/useWatchError';
import { useTranslation } from 'react-i18next';
import InputSelect from '../../../../../../../theme/ui/Atoms/InputSelect/InputSelect';
import {
  POSSIBLE_SHELF_ZONE_ID,
  ShelfSettingDayPartResponse,
} from '../../../../../../../service/schedule/ScheduleAPI.model';
import { useSelector } from 'react-redux';
import getValidZoneSettingsOptionsByZoneIdSelector from '../../../../../../../service/schedule/selectors/getValidZoneSettingsOptionsByZoneIdSelector';

interface ZoneSettingsSmallProps {
  values: ShelfSettingDayPartResponse[];
  onChange: (values: ShelfSettingDayPartResponse[]) => void;
  numberZones?: number;
  groupId?: string;
  dayPartId: string;
}
function ZoneSettingsSmall(props: ZoneSettingsSmallProps) {
  const { groupId, values, dayPartId, onChange, numberZones } = props;
  const { t } = useTranslation();
  const { isFetching, isLoading, error } =
    ZoneSettingsAPI.useGetZoneSettingsByGroupQuery(groupId || '', {
      refetchOnMountOrArgChange: true,
    });

  const zones = useMemo(() => {
    return new Array(numberZones).fill(null).map((v, i) => ({
      zoneId: `${i + 1}` as POSSIBLE_SHELF_ZONE_ID,
      setting: values.find((v) => v.zoneId === `${i + 1}`)?.setting,
    }));
  }, [numberZones, values]);
  useWatchError(error);

  if (isLoading || isFetching) return <CircularProgress />;
  if (error) return <div>{t('error.unknown')}</div>;
  const handleChange = (index: number, value: string | null) => {
    const newValues = [...values];
    newValues[index] = {
      zoneId: `${index + 1}` as POSSIBLE_SHELF_ZONE_ID,
      setting: value,
    };
    onChange(newValues);
  };

  return (
    <div>
      <Stack spacing={2}>
        {zones.map((value, index) => (
          <ZoneSettingsSmallZone
            key={index}
            index={index}
            value={value}
            handleChange={handleChange}
            groupId={groupId || ''}
            dayPartId={dayPartId}
          />
        ))}
      </Stack>
    </div>
  );
}
interface ZoneSettingsSmallZoneProps {
  index: number;
  value: {
    zoneId: POSSIBLE_SHELF_ZONE_ID;
    setting: string | null | undefined;
  };
  handleChange: (index: number, value: string | null) => void;
  groupId: string;
  dayPartId: string;
}
function ZoneSettingsSmallZone(props: ZoneSettingsSmallZoneProps) {
  const { index, value, groupId, dayPartId, handleChange } = props;
  const { t } = useTranslation();
  const zoneId = `${index + 1}` as POSSIBLE_SHELF_ZONE_ID;
  const options = useSelector((state) =>
    getValidZoneSettingsOptionsByZoneIdSelector(state, {
      zoneId,
      groupId,
      dayPartId,
    })
  );
  const styledOptions = options.map((option) => ({
    ...option,
    text: option.disabled ? (
      <ListItemText
        primary={option.text}
        secondary={t('schedule.error.temperatureSetpointLimits')}
        secondaryTypographyProps={{
          color: 'inherit',
        }}
      />
    ) : (
      option.text
    ),
  }));

  return (
    <Stack spacing={2} direction={'row'} alignItems={'flex-end'}>
      <InputSelect
        select
        id={`zone-settings-${zoneId}`}
        name={`zone-settings-${zoneId}`}
        label={`${t(`timers.zone`)} ${zoneId}`}
        options={styledOptions}
        value={value.setting ?? null}
        onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) =>
          handleChange(index, value)
        }
        placeholder={t('schedule.settings.day_part.select_setting_placeholder')}
        fullWidth
        sx={{ label: { fontSize: 16, mb: 2, display: 'inline-block' } }}
      />
      <Button
        variant="contained-gray"
        size="small"
        onClick={() => handleChange(index, null)}
        sx={{ height: '3rem', borderRadius: '2rem', px: 4 }}
      >
        {t('operator.timer.clear_action')}
      </Button>
    </Stack>
  );
}

export default ZoneSettingsSmall;
