export const nameReducer = 'featureToggle';
/**
 * Example:
 * 
 * 
```json
{
  "StyledComponentsVariants": "alan-nuttall-flexeserve-eu",
  "StyledComponentsVariants": "circle-k",
}
```

 */
export interface FeatureToggleState {
  [nameToggle: string]: string | boolean | undefined;
  // know FeatureToggles
  StyledComponentsVariants?: string;
  Schedule?: boolean;
  ScheduleNonConfirmable?: boolean;
  // * Use zone 5 and zone 6 as auxiliary zones
  Zone5Zone6Auxiliary?: boolean;
  DisableOpeningTimes?: boolean;
  HideTemperaturePreference?: boolean;
  /**
   * if MainApplication is LoginInstallFacilities then it should show a different app
   * values accepted: LoginInstallFacilities
   */
  MainApplication?: string;
  /** Should be one of 'CELSIUS', 'FAHRENHEIT', 'DONT_CARE', */
  TemperaturePreferenceDefault?: string;
}
