import { createApi } from '@reduxjs/toolkit/dist/query/react';
import axiosBaseQuery from '../../service/shared/axiosBaseQuery';
import { IUser, UserAPIResponse } from './User.model';
import { fromBase64, toBase64 } from '../../shared/util/base64';

export const UserAPI = createApi({
  reducerPath: 'user',
  baseQuery: axiosBaseQuery({ baseUrl: 'user/' }),
  tagTypes: ['update-rights-user'],
  endpoints(build) {
    return {
      getUser: build.query<IUser | undefined, string>({
        query: (userUUID) => ({ url: `${toBase64(userUUID)}` }),
        transformResponse: (response: UserAPIResponse) => {
          return {
            ...response,
            id: fromBase64(response.id),
            encodedId: response.id,
          };
        },
      }),
      getMyUser: build.query<IUser | undefined, void>({
        query: () => ({ url: 'self' }),
        transformResponse: (response: UserAPIResponse) => {
          return {
            ...response,
            id: fromBase64(response.id),
            encodedId: response.id,
          };
        },
      }),
    };
  },
});

export default UserAPI;
