import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import React from 'react';
import { ReactComponent as ZoneSettingsIcon } from '../../../../../assets/zone_settings.svg';
import { ReactComponent as TimerIcon } from '../../../../../assets/timer.svg';
import {
  ArrowBackIcon,
  DayPartScheduleIcon,
  TemperatureIcon,
} from '../../../../../assets';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ActionButton from '../../../../../theme/ui/Atoms/ActionButton';
import { useAppSelector } from '../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../shared/featureToggle';
import Button from '../../../../../theme/ui/Atoms/Button';

export default function ManagerBusinessActions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessUnitId } = useParams();
  const { Schedule, StyledComponentsVariants } =
    useAppSelector(getFeatureToggle);
  const onClickBack = () => {
    navigate(`/manager/view-locations/${businessUnitId}`);
  };

  return (
    <Box display={'grid'} alignItems={'center'} height={'100%'}>
      <Stack
        direction={'column'}
        flexWrap={'wrap'}
        justifyContent={'center'}
        width={'100%'}
      >
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          width={'100%'}
        >
          <ActionButton
            text={t('zone_settings.title')}
            IconComponent={ZoneSettingsIcon}
            onClick={() =>
              navigate(`/manager/businessUnit/${businessUnitId}/zone-settings`)
            }
          />
          <ActionButton
            text={t('timer_settings.title')}
            IconComponent={TimerIcon}
            onClick={() =>
              navigate(`/manager/businessUnit/${businessUnitId}/timer-settings`)
            }
          />
          {!!Schedule && (
            <ActionButton
              text={t('schedule.settings.title')}
              IconComponent={DayPartScheduleIcon}
              onClick={() =>
                navigate(
                  `/manager/businessUnit/${businessUnitId}/schedule-settings`
                )
              }
            />
          )}
          {StyledComponentsVariants !== 'circle-k' && (
            <ActionButton
              text={t('settings.temperature_preference.title')}
              IconComponent={TemperatureIcon}
              onClick={() =>
                navigate(
                  `/manager/businessUnit/${businessUnitId}/temperature-preference`
                )
              }
            />
          )}
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            padding: '0 0 0 8rem',
          }}
        >
          <Button
            variant="contained-gray"
            sx={{ height: '3em' }}
            onClick={onClickBack}
          >
            <ArrowBackIcon height={'1em'} />
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
