import { AxiosError, AxiosInstance } from 'axios';
import { getManagerAuthRefreshTokenSelector } from '../../../redux/auth-manager/selectors';
import { getAuthRefreshTokenSelector } from '../../../redux/auth/selectors';
import { waitPromise } from '../../../shared/util/waitPromise';
import authMutex from './authMutex';
import { logout } from '../../../redux/auth/action/thunks';

const retryWaitTimeMillis = 100;
const retryMaxTimes = 3;

/**
 * Call
 * @param request
 * @returns
 */
const refreshTokenErrorInterceptor =
  (axiosInstance: AxiosInstance, mode: 'operator' | 'manager' = 'operator') =>
  async (error: any | AxiosError) => {
    const originalRequest = error.config ?? {};
    const store = (await import('./../../../redux/Store')).default;
    let refreshError: Error | undefined | unknown = undefined;

    const refreshTokenAThunk = (
      await import('../../../redux/auth/action/thunksAsync')
    ).refreshTokenAThunk;
    const refreshManagerTokenAThunk = (
      await import('../../../redux/auth-manager/action/thunksAsync')
    ).refreshManagerTokenAThunk;

    // const callType = originalRequest._callType;

    /* by the call authorize client we need to identify if the problem is
     that the customer is Invalid or deactivated */
    if (
      originalRequest?.url?.includes('/token') &&
      error?.response?.status === 401
    ) {
      if (error.response.data.detail && error?.response?.status) {
        error.response.status = `${error.response.data.detail}_401`;
      }
      return Promise.reject(error);
    }

    if (!store) return Promise.reject(new Error('Redux store not initialized'));

    // Case when try to login with bad credentials
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.detail === 'INVALID'
    ) {
      return Promise.reject(new Error('login.error_bad_credentials'));
    }

    /** Refresh token trying 5 times */
    const currentRetries = originalRequest?._auth_retries ?? 1;
    if (currentRetries >= retryMaxTimes) {
      return Promise.reject(refreshError || error);
    }
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      originalRequest._auth_retries = currentRetries + 1;
      if (authMutex.isLocked()) {
        // wait until the mutex is available without locking it
        await authMutex.waitForUnlock();
        // try the call again, because if was locked then the token was refresh
        return axiosInstance(originalRequest);
      }
      const release = await authMutex.acquire();
      // wait 1/10 second before retrying
      await waitPromise(retryWaitTimeMillis * currentRetries - 1);

      // refresh token
      const refreshToken = getAuthRefreshTokenSelector();
      const refreshTokenManager = getManagerAuthRefreshTokenSelector();
      try {
        if (refreshToken)
          await store.dispatch(refreshTokenAThunk({ refreshToken })).unwrap();
        if (refreshTokenManager)
          await store
            .dispatch(
              refreshManagerTokenAThunk({ refreshToken: refreshTokenManager })
            )
            .unwrap();
      } catch (e) {
        console.error(
          'refreshTokenErrorInterceptor: throw an error when refreshToken',
          e
        );
        refreshError = e;
        if (currentRetries >= retryMaxTimes) {
          window.location.href = '/#/?error=error.login_session_expired';
          store.dispatch(logout());
          return Promise.reject(new Error('error.login_session_expired'));
        }
      } finally {
        release();
      }
      // try to call again if has refreshToken
      return axiosInstance(originalRequest);
    }

    return Promise.reject(refreshError || error);
  };

export default refreshTokenErrorInterceptor;
