import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import ActionButton from '../../../../theme/ui/Atoms/ActionButton';
import { ScanAirConnectIcon } from '../../../../assets';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CommanderActions() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box display={'grid'} alignItems={'center'} height={'100%'}>
      <Box textAlign={'center'}>
        <Typography variant={'h4'}>
          {t('initial_setup.scan_air_connect.action')}
        </Typography>
        <Typography>
          {t('initial_setup.scan_air_connect.description')}
        </Typography>
      </Box>

      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'center'}
        width={'100%'}
      >
        <ActionButton
          IconComponent={ScanAirConnectIcon}
          onClick={() => navigate(`./scan-air-connect`)}
        />
      </Stack>
    </Box>
  );
}

export default CommanderActions;
