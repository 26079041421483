import React from 'react';
import Button, { ButtonProps } from '../../../theme/ui/Atoms/Button';
import { ArrowBackIcon } from '../../../assets';
interface BackButtonProps extends Omit<ButtonProps, 'variant'> {
  href?: string;
}
function BackButton(props: BackButtonProps) {
  return (
    <Button rounded fullWidth {...props} variant="contained-gray">
      <ArrowBackIcon height={'1.7em'} />
    </Button>
  );
}

export default BackButton;
