import React from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '../Button';
import Typography from '@mui/material/Typography';

interface ActionButtonProps extends Omit<ButtonProps, 'variant'> {
  IconComponent: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  text?: React.ReactNode;
}
export default function ActionButton(props: ActionButtonProps) {
  const { IconComponent, text, ...rest } = props;
  return (
    <Box sx={{ margin: 3, textAlign: 'center' }}>
      <Button
        size="large"
        sx={{ padding: 5, borderRadius: '2em' }}
        {...rest}
        variant="contained"
      >
        <IconComponent height={'8em'} />
      </Button>
      <Typography fontSize={'1.5em'} marginTop={2} textAlign="center">
        {text}
      </Typography>
    </Box>
  );
}
