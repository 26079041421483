import { createSelector } from '@reduxjs/toolkit';
import { getStructure } from './Structure.selector';
import { NormalizedGroup } from '../GroupsAdapter';
import { toBase64 } from '../../../shared/util/base64';

export const getAllBusinessUnits = createSelector(
  [getStructure],
  (structure) => {
    return structure.data?.businessUnits.map(
      (businessUnit) =>
        structure.data?.entities.groups[businessUnit] as NormalizedGroup
    );
  }
);

export const getBusinessUnitById = createSelector(
  [
    getStructure,
    (_state, props: { businessUnitId: string }) => props.businessUnitId,
  ],
  (structure, businessUnitId) => {
    return structure.data?.entities.groups[toBase64(businessUnitId)];
  }
);
