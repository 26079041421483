import { UseQuerySubscription } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import useDevicesManager from './useDevicesManager';
import { useMemo } from 'react';

type Options = Parameters<UseQuerySubscription<any>>[1];

function useDevicesByCommanderManager(
  args: { storeId: string; commanderId: string },
  options: Options = {}
) {
  const { storeId, commanderId } = args;
  const responseDevicesAPI = useDevicesManager(storeId, options);
  const { data: devices } = responseDevicesAPI;
  const data = useMemo(
    () =>
      devices?.filter(
        (device) =>
          device.commanderId === commanderId && device.composableDevice
      ),
    [commanderId, devices]
  );

  return {
    ...responseDevicesAPI,
    data,
  };
}

export default useDevicesByCommanderManager;
