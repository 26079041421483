import { useParams, useSearchParams } from 'react-router-dom';
import StructureAPI from '../../../../../redux/structure/StructureAPI';
import { getTypeFromGroup } from '../../../../../redux/structure/util/getTypeFromGroup';
import { useMemo } from 'react';
import { NormalizedGroup } from '../../../../../redux/structure/GroupsAdapter';
import getPathOfGroups from '../../../../../redux/structure/util/getPathOfGroups';
import useAlarmsByGroup from '../../../../../redux/alarm/hooks/useAlarmsByGroup';
import useDeviceStatusFromGroup from '../../../../../service/device/CommanderAPI/hooks/useDeviceStatusFromGroup';
import { getDevicesIdsFromGroupDictionary } from '../../../../../redux/structure/selectors/Group.selector';
import { toBase64 } from '../../../../../shared/util/base64';
import _ from 'lodash';
import orComparator from '../../../../../shared/util/lodash/merge/orComparator';

type Options = Parameters<typeof StructureAPI.useGetStructureV2Query>[1];

function useTypeEntity(options?: Options) {
  const { groupId = '' } = useParams<{ groupId: string }>();
  const encodedGroupId = toBase64(groupId);

  const { data, ...rest } = StructureAPI.useGetStructureV2Query(
    undefined,
    options as any
  );
  let role: any = 'businessUnits';
  if (!data?.businessUnits.length) {
    role = 'regions';
    if (!data?.regions.length) {
      role = 'districts';
    }
  }

  const entity = data?.entities?.groups[encodedGroupId];

  const childGroups: NormalizedGroup[] | undefined = useMemo(
    () =>
      !encodedGroupId
        ? (data?.[role as keyof typeof data] as string[])?.map(
            (id) => data?.entities?.groups?.[id] as NormalizedGroup
          )
        : entity?.childGroups
            ?.map(
              (id) => (data as any)?.entities?.groups?.[id] as NormalizedGroup
            )
            .filter((group) => !group.composableDevice),
    [encodedGroupId, entity?.childGroups, role, data]
  );

  const { data: alarmsData, ...restAlarms } = useAlarmsByGroup({
    groupId,
  });

  const {
    numberFailingMqttConnection,
    data: devicesStatus,
    ...restDeviceStatus
  } = useDeviceStatusFromGroup(groupId);

  const [searchBarParams] = useSearchParams();
  const isTemperatureActive = searchBarParams.get('alarmsActive') === 'true';
  const isCommanderStatusFalling =
    searchBarParams.get('deviceStatusFailing') === 'true';
  const commanderIds =
    data?.entities?.groups &&
    getDevicesIdsFromGroupDictionary(data?.entities?.groups, encodedGroupId);

  const filteredChildGroups = useMemo(
    () =>
      childGroups
        ?.filter((group) => {
          const commanderIds =
            data?.entities?.groups &&
            getDevicesIdsFromGroupDictionary(data?.entities?.groups, group.id);

          if (isTemperatureActive && alarmsData?.length) {
            return alarmsData?.some((alarm) =>
              commanderIds?.includes(alarm.deviceId)
            );
          }
          if (isCommanderStatusFalling && numberFailingMqttConnection) {
            return (
              devicesStatus &&
              commanderIds?.some(
                (commanderId) =>
                  devicesStatus?.entities[commanderId]?.mqttConnected === false
              )
            );
          }
          return true;
        })
        .sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        ),
    [
      childGroups,
      data?.entities?.groups,
      isTemperatureActive,
      alarmsData,
      isCommanderStatusFalling,
      numberFailingMqttConnection,
      devicesStatus,
    ]
  );

  const pathGroups = useMemo(
    () => getPathOfGroups(data?.entities?.groups, encodedGroupId),
    [data?.entities?.groups, encodedGroupId]
  );
  const businessUnit = useMemo(
    () =>
      pathGroups.find((group) => getTypeFromGroup(group) === 'businessUnit'),
    [pathGroups]
  );
  return {
    entity,
    type: getTypeFromGroup(entity),
    childGroups,
    filteredChildGroups,
    commanderIds,
    groupId,
    encodedGroupId,
    numberFailingMqttConnection,
    devicesStatus,
    alarmsData,
    businessUnit,
    pathGroups,
    role,
    ..._.chain(rest)
      .mergeWith(restDeviceStatus, orComparator)
      .mergeWith(restAlarms, orComparator)
      .value(),
  };
}

export default useTypeEntity;
