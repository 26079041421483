import { AxiosInstance } from 'axios';
import AxiosFlexeServeInstance from '../../shared/AxiosFlexeServe';
import confirmCorrelationIdRecursive from '../../shared/confirmCorrelationIdRecursive';
import {
  AirConnectNode,
  AirConnectNodeScanned,
  AirConnectNodeTypes,
  ControlUnitInstallation,
} from './CommanderAirConnectAPI.model';
import { waitPromise } from '../../../shared/util/waitPromise';
import recursiveExponentialBackOff from '../../shared/recursiveExponentialBackOff';
import { isNotNullOrUndefined } from '../../../shared/util/collection/isNotNullOrUndefined';
import FlexeserveDeviceAPI from '../FlexeserveDevice/FlexeserveDeviceAPI';
import { fromBase64 } from '../../../shared/util/base64';
import store from '../../../redux/Store';
import _ from 'lodash';

export class CommanderAirConnectAPIService {
  public constructor(private axios: AxiosInstance = AxiosFlexeServeInstance) {}

  private getNodesInit = (encodedCommanderId: string): Promise<any> => {
    return this.axios({
      url: `/commander/${encodedCommanderId}/configuration/control-unit-management`,
      method: 'post',
      data: {
        subtype: 'AC_START_SCAN',
      },
    })
      .then((response) => response.data)
      .then(({ correlationID }) =>
        confirmCorrelationIdRecursive(
          encodedCommanderId,
          correlationID,
          this.getConfirmation
        )
      )
      .then((data) => data?.response?.result);
  };

  private getNodesResultScanned = (
    encodedDeviceId: string
  ): Promise<AirConnectNodeScanned[]> => {
    return this.axios({
      url: `/commander/${encodedDeviceId}/configuration/control-unit-management`,
      method: 'post',
      data: {
        subtype: 'AC_SCAN_RESULTS',
      },
    })
      .then((response) => response.data)
      .then(({ correlationID }) =>
        confirmCorrelationIdRecursive(
          encodedDeviceId,
          correlationID,
          this.getConfirmation
        )
      )
      .then((data) => data?.response?.result?.scanResults);
  };

  getNodesAvailableToPair = (
    encodedCommanderId: string
  ): Promise<AirConnectNodeScanned[]> => {
    return this.getNodesInit(encodedCommanderId).then(() =>
      this.getNodesResultScanned(encodedCommanderId)
    );
  };

  getNodesInstalled = (
    encodedCommanderId: string
  ): Promise<AirConnectNode[]> => {
    return this.axios({
      url: `/commander/${encodedCommanderId}/configuration/control-unit-management`,
      method: 'post',
      data: {
        subtype: 'AC_INSTALLED_NODES',
      },
    })
      .then((response) => response.data)
      .then(({ correlationID }) =>
        confirmCorrelationIdRecursive(
          encodedCommanderId,
          correlationID,
          this.getConfirmation
        )
      )
      .then((data) => data?.response?.result?.installedNodes);
  };

  installNode = (encodedCommanderId: string, macAddress: string) => {
    return this.axios({
      url: `/commander/${encodedCommanderId}/configuration/control-unit-management`,
      method: 'post',
      data: {
        subtype: 'AC_INSTALL_NODE',
        nodeMacAddressToInstall: macAddress,
      },
    })
      .then((response) => response.data)
      .then(({ correlationID }) =>
        confirmCorrelationIdRecursive(
          encodedCommanderId,
          correlationID,
          this.getConfirmation
        )
      )
      .then((data) => data?.response?.result);
  };

  saveConfigurationNodes = (
    encodedCommanderId: string,
    node: AirConnectNode
  ) => {
    return this.axios({
      url: `/commander/${encodedCommanderId}/configuration/control-unit-management`,
      method: 'post',
      data: {
        subtype: 'AC_SAVE_NODE_CONTROLLERS_CONFIG',
        nodeNumber: node.nodeNumber,
      },
    })
      .then((response) => response.data)
      .then(({ correlationID }) =>
        confirmCorrelationIdRecursive(
          encodedCommanderId,
          correlationID,
          this.getConfirmation
        )
      )
      .then((data) => data?.response?.result);
  };

  installNodes = async (encodedCommanderId: string, macAddress: string[]) => {
    let lastNodeState: AirConnectNode[] = await this.getNodesInstalled(
      encodedCommanderId
    );
    await waitPromise(1000 * 10); // Wait 10 seconds
    for (const mac of macAddress) {
      await this.installNode(encodedCommanderId, mac);
      await waitPromise(1000 * 10); // Wait 10 seconds
      lastNodeState = await this.getNodesInstalled(encodedCommanderId);
      await waitPromise(1000 * 10); // Wait another 10 seconds
    }
    const nodesToSave = macAddress
      .map((mac) => lastNodeState.find((node) => node.macAddress === mac))
      .filter(isNotNullOrUndefined);
    for (const node of nodesToSave) {
      if (node.nodeType === AirConnectNodeTypes.STAirConnectNode) {
        await this.saveConfigurationNodes(encodedCommanderId, node);
        await waitPromise(1000 * 2); // Wait 2 seconds because is falling
      }
    }

    await waitPromise(1000 * 20); // Wait another 20 seconds
    await recursiveExponentialBackOff(
      () => this.getNodesInstalled(encodedCommanderId),
      this.isNodesReachable
    );

    return this.installNewAndMissingControlUnits(
      encodedCommanderId,
      nodesToSave
    );
  };

  isNodesReachable = (nodes: AirConnectNode[]) => {
    return nodes.every(this.isNodeReachable);
  };

  isNodeReachable = (node: AirConnectNode) => {
    return node.nodeReachable && !Number.isNaN(node.status)
      ? node.status !== 0
      : true;
  };

  reInstallControlUnitsFromNode = async (
    encodedCommanderId: string,
    nodeNumber: number,
    nodeType: string
  ) => {
    let lastNodeState: AirConnectNode[] = await this.getNodesInstalled(
      encodedCommanderId
    );
    let node = lastNodeState.find((node) => node.nodeNumber === nodeNumber);
    if (!node) throw new Error('Node not found');
    await this.unInstallControlUnits(encodedCommanderId, [node]);
    if (nodeType === '1')
      await this.factoryResetNode(encodedCommanderId, nodeNumber);
    await waitPromise(1000 * 20); // Wait 20 seconds
    lastNodeState = await recursiveExponentialBackOff(
      () => this.getNodesInstalled(encodedCommanderId),
      (response) => {
        const node = response.find((node) => node.nodeNumber === nodeNumber);
        return !!node && this.isNodeReachable(node);
      }
    );

    if (node?.nodeType === AirConnectNodeTypes.STAirConnectNode)
      await this.saveConfigurationNodes(encodedCommanderId, node);

    await waitPromise(1000 * 20); // Wait another 20 seconds
    lastNodeState = await recursiveExponentialBackOff(
      () => this.getNodesInstalled(encodedCommanderId),
      (response) => {
        const node = response.find((node) => node.nodeNumber === nodeNumber);
        return !!node?.nodeReachable;
      }
    );
    node = lastNodeState.find((node) => node.nodeNumber === nodeNumber);
    node && this.installControlUnits(encodedCommanderId, [node]);
    return node;
  };

  rebootNode = (encodeCommanderId: string, nodeNumber: number) => {
    return this.axios({
      url: `/commander/${encodeCommanderId}/configuration/control-unit-management`,
      method: 'post',
      data: {
        subtype: 'AC_REBOOT',
        nodeNumber,
      },
    })
      .then((response) => response.data)
      .then(({ correlationID }) =>
        confirmCorrelationIdRecursive(
          encodeCommanderId,
          correlationID,
          this.getConfirmation
        )
      )
      .then((data) => data?.response?.result);
  };

  factoryResetNode = (encodeCommanderId: string, nodeNumber: number) => {
    return this.axios({
      url: `/commander/${encodeCommanderId}/configuration/control-unit-management`,
      method: 'post',
      data: {
        subtype: 'AC_FACTORY_RESET',
        nodeNumber,
      },
    })
      .then((response) => response.data)
      .then(({ correlationID }) =>
        confirmCorrelationIdRecursive(
          encodeCommanderId,
          correlationID,
          this.getConfirmation
        )
      )
      .then((data) => data?.response?.result);
  };

  getConfirmation = (encodedCommanderId: string, correlationId: string) => {
    return this.axios({
      url: `/commander/${encodedCommanderId}/configuration/control-unit-management/response/${correlationId}`,
      method: 'get',
    }).then((response) => response.data);
  };

  installNewAndMissingControlUnits = (
    encodedCommanderId: string,
    nodesToInstall: AirConnectNode[],
    extraArgs: Partial<ControlUnitInstallation> = {}
  ) => {
    return this.collectMissingAddressControlUnits(encodedCommanderId).then(
      (missingAddress) =>
        this.installControlUnits(encodedCommanderId, nodesToInstall, {
          ...extraArgs,
          addresses: missingAddress,
        })
    );
  };

  collectMissingAddressControlUnits = (encodedCommanderId: string) => {
    return store
      .dispatch(
        FlexeserveDeviceAPI.endpoints.getDeviceByFlexeserveDeviceId.initiate(
          fromBase64(encodedCommanderId)
        )
      )
      .unwrap()
      .then((flexeServeDevice) =>
        this.getNodesInstalled(encodedCommanderId)
          .then((nodesInstalled) =>
            this.inferAddressesFromNodes(nodesInstalled)
          )
          .then((addresses) =>
            // find if the address is not already in the flexeServeDevice
            addresses.filter(
              (address) =>
                !flexeServeDevice?.shelfMappings.some(
                  (controlUnitMap) => controlUnitMap?.cuaddr === address
                )
            )
          )
      );
  };

  inferAddressesFromNodes = (nodes: AirConnectNode[]): string[] => {
    return nodes
      .map((node) =>
        Array.from({ length: node.numberOfControllers }, (_, i) =>
          (node.nodeAddress + i).toString()
        )
      )
      .flat();
  };

  installControlUnits = (
    encodedCommanderId: string,
    nodes: AirConnectNode[],
    extraArgs: Partial<ControlUnitInstallation> = {}
  ) => {
    // map AirConnectNodeScanned to ControlUnitInstallation
    const inferAddresses = this.inferAddressesFromNodes(nodes);

    const addresses = _(inferAddresses)
      .concat(extraArgs.addresses ?? [])
      .uniq()
      .value();
    if (addresses.length === 0) return Promise.resolve();
    const controlUnitInstallation: ControlUnitInstallation = {
      ...extraArgs,
      addresses,
    };
    return this.axios({
      url: `/commander/${encodedCommanderId}/configuration/cabinet/installation-control-unit`,
      method: 'post',
      data: controlUnitInstallation,
    })
      .then((response) => response.data)
      .then(({ correlationID }) =>
        confirmCorrelationIdRecursive(
          encodedCommanderId,
          correlationID,
          this.getConfirmationInstallationControlUnit
        )
      )
      .then((data) => data?.response?.result);
  };

  unInstallControlUnits = (
    encodedCommanderId: string,
    nodesInstalled: AirConnectNode[]
  ) => {
    return this.installControlUnits(encodedCommanderId, nodesInstalled, {
      uninstall: true,
    });
  };

  getConfirmationInstallationControlUnit = (
    encodedCommanderId: string,
    correlationId: string
  ) => {
    return this.axios({
      url: `/commander/${encodedCommanderId}/configuration/cabinet/installation-control-unit/response/${correlationId}`,
      method: 'get',
    }).then((response) => response?.data);
  };
}

export default new CommanderAirConnectAPIService();
