import { ThemeOptions, Theme } from '@mui/material/styles';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import React from 'react';
import { ButtonBaseProps, LinkProps, ListItemButtonProps } from '@mui/material';

// eslint-disable-next-line react/display-name
export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const sharedThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      // Orange
      main: '#D95128',
    },
    secondary: {
      // Red used for gradient
      main: '#FF3B4E',
    },
    gradientColor: {
      extended:
        'linear-gradient(135deg, #F6A144 0%, #EA440A   25%, #EA440A 50%, #EA440A 75%, #F6A144 100%)',
      short: 'linear-gradient(135deg, #F6A144 0%, #EA440A 50%, #EA440A 100%)',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    background: {
      default: '#EDEDEE',
    },
  },
  typography: {
    subtitle2: {
      fontSize: 24,
      // fontWeight: 600,
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      marginBottom: '1rem',
      marginTop: '1rem',
    },
    h3: {
      marginTop: '1rem',
      marginBottom: '0.5rem',
    },
    fontFamily: [
      'Inter',
      'Roboto',
      '"Segoe UI"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },

    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      } as ButtonBaseProps,
    },
    MuiButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        component: LinkBehavior,
      } as ListItemButtonProps,
    },
  },
};

// Nothing to polyfill for now
export const polyfillTheme = (theme: Theme) => {};

export default sharedThemeOptions;
