import Stack from '@mui/material/Stack';
import ZoneConnected from './ZoneConnected';
import useDevicesOperator, {
  ComposableDevicesStoreInfoResponse,
} from '../../../../service/store/hooks/useDevicesOperator';
import styled from '@emotion/styled';
import { getShelfMapping } from '../../../../service/device/util/getDeviceMappings';
import { ShelfCardContainer } from './ShelfCardContainer';
import { useDevicesFilteredByZone } from '../../../../service/device/hooks/useDevicesFilteredByZone';

export default function DeviceOperation() {
  const { devices } = useDevicesOperator();
  const { upperZoneDevices, lowerZoneDevices } =
    useDevicesFilteredByZone(devices);

  return (
    <Stack>
      <DeviceOperationContainer>
        {upperZoneDevices?.map((device) => (
          <ShelfCard key={device.uuid} device={device} />
        ))}
      </DeviceOperationContainer>
      <DeviceOperationContainer>
        {lowerZoneDevices?.map((device) => (
          <ShelfCard key={device.uuid} device={device} />
        ))}
      </DeviceOperationContainer>
    </Stack>
  );
}

export const DeviceOperationContainer = styled(Stack)`
  display: grid;
  grid-auto-columns: 230px;
  grid-auto-flow: column;
  align-items: start;
  grid-gap: 2rem;
  overflow: auto;
  padding: 1rem;
`;
const ShelfCard = (props: { device: ComposableDevicesStoreInfoResponse }) => {
  const { device } = props;
  return (
    <ShelfCardContainer title={device.unitName}>
      {getShelfMapping(device).map((shelf, i) => (
        <ZoneConnected
          key={`${device.uuid}-${i}`}
          item={{
            device: device,
            zone: device.shelfSettings[shelf.id],
          }}
          zoneIndex={shelf.id}
        />
      ))}
    </ShelfCardContainer>
  );
};
