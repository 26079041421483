import {
  isAuxiliaryDevice,
  isCommonDevice,
} from '../../store/hooks/useZoneDevice';
import { ComposableDevicesManagerStoreInfoResponse } from './useDevicesManager';

const sortComposableDevice = (
  a: ComposableDevicesManagerStoreInfoResponse,
  b: ComposableDevicesManagerStoreInfoResponse
) =>
  a?.unitName
    .toLowerCase()
    .localeCompare(b?.unitName.toLowerCase(), undefined, { numeric: true });

export function useDevicesFilteredByZone(
  devices: ComposableDevicesManagerStoreInfoResponse[]
) {
  const upperZoneDevices = devices
    ?.filter((device) => !device.composableDevice || isCommonDevice(device))
    .sort(sortComposableDevice);

  const lowerZoneDevices = devices
    ?.filter(isAuxiliaryDevice)
    .sort(sortComposableDevice);

  return {
    upperZoneDevices,
    lowerZoneDevices,
  };
}
