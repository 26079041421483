import { CommanderUpdateStateType } from '../CommanderUpdate.model';

function getProgressFromStep(step?: CommanderUpdateStateType) {
  switch (step) {
    case CommanderUpdateStateType.CUSTOM:
      return 10;
    case CommanderUpdateStateType.DOWNLOAD_STARTED:
      return 20;
    case CommanderUpdateStateType.DOWNLOAD_FINISHED:
      return 40;
    case CommanderUpdateStateType.VERIFICATION_STARTED:
      return 50;
    case CommanderUpdateStateType.READY_TO_UPDATE:
      return 60;
    case CommanderUpdateStateType.INSTALLATION_STARTED:
      return 70;
    case CommanderUpdateStateType.INSTALLATION_FINISHED:
      return 100;
    default:
      return 0;
  }
}

export default getProgressFromStep;
