import _ from 'lodash';
import orComparator from '../../../shared/util/lodash/merge/orComparator';
import useMyWorkspace from '../../workspace/hooks/useMyWorkspace';
import AlarmAPI from '../AlarmAPI';
import { UseQuerySubscription } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { ComposableDevicesManagerStoreInfoResponse } from '../../../service/device/hooks/useDevicesManager';
import { useMemo } from 'react';
import { ALARMS_POLLING_INTERVAL } from '../Alarm.model';
import { ComposableDevicesStoreInfoResponse } from '../../../service/store/hooks/useDevicesOperator';

type Options = Parameters<UseQuerySubscription<any>>[1];

function useAlarmsByDevice(
  args: {
    commanderId?: string;
    device?:
      | ComposableDevicesManagerStoreInfoResponse
      | ComposableDevicesStoreInfoResponse;
  },
  options: Options = {}
) {
  const { data: workspaceData, ...responseWorkspaceAPI } = useMyWorkspace(
    undefined,
    options
  );
  const { data: alarmsData, ...responseAlarmAPI } =
    AlarmAPI.useGetMyIncidentByWorkspaceIdQuery(
      {
        workspaceId: workspaceData?.id ?? '',
      },
      {
        skip: !workspaceData?.id,
        pollingInterval: ALARMS_POLLING_INTERVAL,
        ...options,
      }
    );

  const data = useMemo(
    () =>
      alarmsData
        ?.filter?.((alarm) =>
          args.commanderId ? alarm.deviceId === args.commanderId : true
        )
        .filter((alarm) => {
          if (!args.device) return true;
          if (args.device.commanderId !== alarm.deviceId) return false;
          // it means that is not a composable device so is a common device
          if (!args.device.composableZoneMapping) return true;
          const idZones = Object.values(
            args.device?.composableZoneMapping ?? {}
          );
          const controlUnitIds = args.device?.shelfMappings
            ?.filter((shelf) => shelf && idZones.includes(shelf.id + ''))
            .map((shelf) => shelf?.cuid);
          return controlUnitIds?.includes(alarm.cu ?? -1) ?? [];
        }),
    [alarmsData, args.commanderId, args.device]
  );

  return {
    ..._.mergeWith(responseWorkspaceAPI, responseAlarmAPI, orComparator),
    data,
  };
}

export default useAlarmsByDevice;
