import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LogoFlexeserve from '../../../theme/ui/Atoms/Icons/LogoFlexeserve';
import Button from '../../../theme/ui/Atoms/Button';

export default function LoginFooter() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      marginTop={(theme) => theme.spacing(8)}
    >
      <Box display="flex" alignItems="center">
        <Box sx={{ marginRight: 1 }} component="span">
          {t('info_pages.copyright')}
        </Box>
        <LogoFlexeserve height={'1em'} />
      </Box>
      <Stack
        flexDirection={'row'}
        flexWrap="wrap"
        color={(theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.grey[400]
            : theme.palette.grey[600]
        }
      >
        <Link
          component={RouterLink}
          to="/contact-us"
          color="inherit"
          underline="none"
        >
          <Button variant="text" color="inherit">
            {t('info_pages.contact_us')}
          </Button>
        </Link>
        <Link
          href="https://www.flexeserve.com/"
          color="inherit"
          underline="none"
        >
          <Button variant="text" color="inherit">
            {t('info_pages.about_us')}
          </Button>
        </Link>
        <Link
          component={RouterLink}
          to="/terms"
          color="inherit"
          underline="none"
        >
          <Button variant="text" color="inherit">
            {t('info_pages.terms')}
          </Button>
        </Link>
        <Link
          component={RouterLink}
          to="/legal"
          color="inherit"
          underline="none"
        >
          <Button variant="text" color="inherit">
            {t('info_pages.legal')}
          </Button>
        </Link>
      </Stack>
    </Box>
  );
}
