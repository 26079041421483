import { Box, Typography } from '@mui/material';
import React from 'react';

interface TitleIconProps {
  icon: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
}
function TitleIcon(props: TitleIconProps) {
  const { icon, subtitle, children } = props;
  return (
    <div>
      <Typography
        variant="h1"
        mt={2}
        mb={0}
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box component={'span'} marginRight={2}>
          {icon}
        </Box>
        {children}
      </Typography>
      {!!subtitle && <Typography ml={10}>{subtitle}</Typography>}
    </div>
  );
}

export default TitleIcon;
