import styled from '@emotion/styled';
import { Container, Paper } from '@mui/material';
import React from 'react';

function ContainerBox({
  children,
  maxWidth = 'lg',
}: {
  children: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}) {
  return (
    <StyledContainer maxWidth={maxWidth}>
      <StyledPaper elevation={5}>{children}</StyledPaper>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  margin-top: 2rem;
`;

const StyledPaper = styled(Paper)`
  padding: 2rem;
`;

export default ContainerBox;
