import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ErrorOutlineIcon } from '../../../assets';
import { useParseErrorMessage } from '../../../shared/hooks/useParseErrorMessage';
import { StackProps } from '@mui/material/Stack';

export interface AlertMessageProps extends StackProps {
  error: unknown;
}
const AlertMessage = ({ error, ...stackProps }: AlertMessageProps) => {
  const parseErrorMessage = useParseErrorMessage();
  return (
    <Stack textAlign={'center'} {...stackProps} spacing={2}>
      <Typography color="error.main">
        <ErrorOutlineIcon fontSize={'5em'} />
      </Typography>

      <Typography>{parseErrorMessage(error)}</Typography>
    </Stack>
  );
};

export default AlertMessage;
