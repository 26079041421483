import React, { useContext } from 'react';
import { Button, Stack } from '@mui/material';
import { Search } from '@mui/icons-material';
import Modal from '../../Modal/Modal';
import { useTranslation } from 'react-i18next';
import { SearchBarProps, SearchContext } from '../SearchBar';
import SearchInput from '../SearchInput/SearchInput';

export const SearchBarMobile = (props: SearchBarProps) => {
  const { children, ...rest } = props;
  const { search, setSearch, open, setOpen, selectedItem, hasMatch } =
    useContext(SearchContext);

  const onClose = () => {
    setOpen(false);
  };
  const handleBlur = () => {
    if (selectedItem && !hasMatch) {
      setSearch(selectedItem.value);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <Button>
        <Search color="primary" onClick={() => setOpen(true)} />
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        title={t('search.title') || ''}
        fullWidth
        maxWidth="md"
        buttonActions={undefined}
      >
        <SearchInput
          color="primary"
          type="text"
          value={search}
          placeholder={t('search.placeholder') || ''}
          onChange={(e: any) => {
            setSearch(e.target.value);
          }}
          onBlur={handleBlur}
          {...rest}
        />

        {search.length > 2 && <Stack overflow={'auto'}>{children}</Stack>}
      </Modal>
    </>
  );
};
