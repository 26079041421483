import React from 'react';
import { useParams } from 'react-router-dom';
import ManagerTemperaturePreference from '../../ManagerTemperaturePreference';

function ManagerTemperaturePreferenceRegion() {
  const { businessUnitId = '' } = useParams<{
    businessUnitId: string;
  }>();

  return (
    <ManagerTemperaturePreference
      groupId={businessUnitId}
      title="settings.temperature_preference.title_region"
      hintText="settings.temperature_preference.manager_region_hint"
      recursive
    />
  );
}

export default ManagerTemperaturePreferenceRegion;
