import { DeviceAPI } from '../DeviceAPI';
import { toBase64 } from '../../../shared/util/base64';
import CommanderUpdateAPIService from './CommanderUpdateAPIService';
import {
  CommanderTriggerUpdate,
  CommanderUpdate,
  CommanderUpdateLastStateResponseAPI,
  CommanderUpdateStateResponseAPI,
} from './CommanderUpdate.model';

const CommanderUpdateAPI = DeviceAPI.injectEndpoints({
  endpoints: (build) => ({
    getUpdatesAvailable: build.query<CommanderUpdate[], string>({
      query: (commanderId) => {
        const encodedCommanderId = toBase64(commanderId);
        return {
          url: `/update/device/${encodedCommanderId}`,
          method: 'get',
        };
      },
      providesTags: ['commander-update-actions'],
    }),

    triggerUpdate: build.mutation<
      any,
      {
        commanderId: string;
        data: CommanderTriggerUpdate;
      }
    >({
      queryFn: async ({ commanderId, data }, api) => {
        const encodedCommanderId = toBase64(commanderId);
        const response = await CommanderUpdateAPIService.commanderUpdate(
          encodedCommanderId,
          data
        )
          .then((data) => ({ data, meta: {} }))
          .catch((error) => ({
            error: error.message,
            data: undefined,
            meta: {},
          }));
        return response;
      },
      invalidatesTags: ['commander-update-actions'],
    }),
    getStateUpdateInstallation: build.query<
      CommanderUpdateStateResponseAPI,
      string
    >({
      query: (commanderId) => {
        const encodedCommanderId = toBase64(commanderId);
        return {
          url: `/update/device/${encodedCommanderId}/update/progress`,
          method: 'get',
        };
      },
      providesTags: ['commander-update-actions'],
    }),

    getCommanderLastStateInstallation: build.query<
      CommanderUpdateLastStateResponseAPI,
      string
    >({
      query: (commanderId) => {
        const encodedCommanderId = toBase64(commanderId);
        return {
          url: `/update/device/${encodedCommanderId}/state`,
          method: 'get',
        };
      },
      providesTags: ['commander-update-actions'],
    }),
  }),
});

export default CommanderUpdateAPI;
