import { createApi } from '@reduxjs/toolkit/query/react';
import { WorkspaceAPIResponse } from './WorkspaceAPI.model';
import axiosBaseQuery from '../../service/shared/axiosBaseQuery';

const WorkspaceAPI = createApi({
  reducerPath: 'workspaceId',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'create-request',
    'cancel-self',
    'sales-team-actions',
    'workspace-actions',
    'workspace-usage',
  ],
  endpoints(build) {
    return {
      getMyWorkspaces: build.query<WorkspaceAPIResponse[], undefined>({
        query: () => ({ url: `workspace/self` }),
        providesTags: ['cancel-self', 'workspace-actions'],
      }),
    };
  },
});

export default WorkspaceAPI;
