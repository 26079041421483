import React from 'react';
import Button from '../../../../theme/ui/Atoms/Button';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { DeviceOverviewIcon } from '../../../../assets';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../BackButton';
import ZoneManagerConnected from '../ZoneManagerConnected';
import { DeviceOperationContainer } from '../../Operator/DeviceOperation/DeviceOperation';
import { ShelfCardContainer } from '../../Operator/DeviceOperation/ShelfCardContainer';
import { getShelfMapping } from '../../../../service/device/util/getDeviceMappings';
import useWatchError from '../../../../shared/hooks/useWatchError';
import { ComposableDevicesManagerStoreInfoResponse } from '../../../../service/device/hooks/useDevicesManager';
import useDevicesByCommanderManager from '../../../../service/device/hooks/useDevicesByCommanderManager';
import { useDevicesFilteredByZone } from '../../../../service/device/hooks/useDevicesFilteredByZone';

function DevicesOperatorOverviewSetUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickBack = () => {
    navigate('/');
  };
  const onClickNext = () => {
    navigate('../time-zone');
  };
  const { commanderId = '', storeId = '' } = useParams<{
    commanderId: string;
    serialNumber: string;
    storeId: string;
  }>();
  const {
    data: devices,
    isFetching: loadingDevices,
    error: errorDevices,
  } = useDevicesByCommanderManager(
    { storeId, commanderId },
    {
      refetchOnMountOrArgChange: true,
      skip: !storeId,
    }
  );

  const { upperZoneDevices, lowerZoneDevices } =
    useDevicesFilteredByZone(devices);

  const loading = loadingDevices;
  const error = errorDevices;
  useWatchError(error);
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: 2,
          fill: (theme) => theme.palette.text.primary,
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          {loading ? (
            <CircularProgress />
          ) : (
            <DeviceOverviewIcon height={'2em'} />
          )}
          <Typography
            variant="subtitle2"
            marginY={2}
            sx={{
              display: 'flex',
              marginLeft: 3,
              alignItems: 'center',
            }}
          >
            {t('initial_setup.overview.operator_overview')}
          </Typography>
        </Stack>
      </Box>

      <Container maxWidth="xl" sx={{ mt: 4, overflow: 'auto' }}>
        <Stack>
          <DeviceOperationContainer>
            {upperZoneDevices?.map((device) => (
              <ShelfCard
                key={device.uuid}
                device={device}
                loadingDevices={loadingDevices}
              />
            ))}
          </DeviceOperationContainer>
          <DeviceOperationContainer>
            {lowerZoneDevices?.map((device) => (
              <ShelfCard
                key={device.uuid}
                device={device}
                loadingDevices={loadingDevices}
              />
            ))}
          </DeviceOperationContainer>
        </Stack>
      </Container>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={2} justifyContent={'space-between'} mt={2}>
          <Grid item xs={12} sm={2}>
            <BackButton onClick={onClickBack} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              rounded
              fullWidth
              onClick={onClickNext}
            >
              {t('button.next')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

const ShelfCard = (props: {
  device: ComposableDevicesManagerStoreInfoResponse;
  loadingDevices: boolean;
}) => {
  const { device, loadingDevices } = props;
  return (
    <ShelfCardContainer title={device.unitName}>
      {getShelfMapping(device).map((shelf, i) => (
        <ZoneManagerConnected
          key={`${device.uuid}-${i}`}
          item={{
            device: device,
            zone: device.shelfSettings[shelf.id],
          }}
          zoneIndex={shelf.id}
          loadingState={loadingDevices}
        />
      ))}
    </ShelfCardContainer>
  );
};

export default DevicesOperatorOverviewSetUp;
