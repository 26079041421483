import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import ActionButton from '../../../../theme/ui/Atoms/ActionButton';
import { ScanAirConnectIcon } from '../../../../assets';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import reconnect_devices from '../../../../assets/images/reconnect_devices.gif';

function ReconnectDevices() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      height={'100%'}
      pt={2}
    >
      <Box textAlign={'center'}>
        <Typography variant={'h4'}>
          {t('initial_setup.scan_air_connect.user_action_title')}
        </Typography>
        <Typography variant={'h5'}>
          {t('initial_setup.scan_air_connect.user_action_description')}
        </Typography>
        <img src={reconnect_devices} alt="reconnect_devices" height={400} />
      </Box>

      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'center'}
        width={'100%'}
      >
        <Box>
          <Typography variant={'h5'} textAlign={'center'}>
            {t('initial_setup.scan_air_connect.action')}
          </Typography>
          <ActionButton
            IconComponent={ScanAirConnectIcon}
            onClick={() => navigate(`../scan-air-connect`)}
          />
        </Box>
      </Stack>
    </Box>
  );
}

export default ReconnectDevices;
