import {
  GroupAPIResponse,
  GroupCreateAPI,
  GroupCreateAPIResponse,
  GroupWithComposableDevice,
} from './group.model';
import getGroupsListFromGroupsTree from './serialize/getGroupsListFromGroupsTree';
import { toBase64 } from '../../shared/util/base64';
import LocationAPI from '../locations/LocationAPI';

const GroupAPI = LocationAPI.injectEndpoints({
  endpoints(build) {
    return {
      getGroupsList: build.query<GroupAPIResponse[], void>({
        query: () => ({ url: 'groups/', method: 'get' }),
        transformResponse: (response: GroupAPIResponse[]) => {
          return getGroupsListFromGroupsTree(response);
        },
      }),
      getGroupById: build.query<GroupWithComposableDevice, string>({
        query: (id) => ({ url: `groups/${toBase64(id)}`, method: 'get' }),
        transformResponse: (response: GroupWithComposableDevice) => {
          return response;
        },
      }),
      createGroup: build.mutation<GroupCreateAPIResponse, GroupCreateAPI>({
        query: (data) => ({ url: 'groups/', method: 'post', data }),
        invalidatesTags: ['group-actions'],
      }),
    };
  },
});

export default GroupAPI;

export const { useGetGroupsListQuery, useGetGroupByIdQuery } = GroupAPI;
