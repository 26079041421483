import { useParams } from 'react-router-dom';
import LocationAPI from '../../locations/LocationAPI';
import useWatchError from '../../../shared/hooks/useWatchError';
import { TEMPERATURE_UNIT_TYPES } from '..';
import StoreAPI from '../../store/StoreAPI';
import useTemperatureDefault from './useTemperatureDefault';

export function useTemperaturePreference() {
  // Manager by region
  const params = useParams();
  const { groupId, businessUnitId, storeId } = params;

  const entityId = storeId ?? groupId ?? businessUnitId ?? '';
  const {
    error: errorTemperaturePreferenceByRegion,
    data: temperaturePreferenceByRegion,
  } = LocationAPI.useGetTemperaturePreferenceByGroupQuery(entityId, {
    skip: !groupId,
  });
  useWatchError(errorTemperaturePreferenceByRegion);
  const temperatureDefault = useTemperatureDefault();

  // Operator by store
  const { data: storeInfoDetails } = StoreAPI.useGetStoreInfoDetailsQuery(
    undefined,
    {
      skip: !!entityId,
    }
  );
  const temperaturePreferenceStore = storeInfoDetails?.preferredUnit;
  // merge
  const temperaturePreference =
    temperaturePreferenceByRegion ??
    temperaturePreferenceStore ??
    temperatureDefault;

  // support DONT_CARE
  if (temperaturePreference === TEMPERATURE_UNIT_TYPES.DONT_CARE)
    return temperatureDefault;

  return temperaturePreference;
}
