import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { setAutoFreeze } from 'immer';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import LocationAPI from '../service/locations/LocationAPI';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import ZoneSettings from '../service/zoneSettings/ZoneSettingsAPI';
import TimerSettingsAPI from '../service/timerSettings/TimerSettingsAPI';
import { setExternalStore } from './StoreExternal';
import postRehydrate from './middleware/postRehydrate';
import ScheduleAPI from '../service/schedule/ScheduleAPI';
import StoreAPI from '../service/store/StoreAPI';
import StructureAPI from './structure/StructureAPI';
import WorkspaceAPI from './workspace/WorkspaceAPI';
import AlarmAPI from './alarm/AlarmAPI';
import UserAPI from './user/UserAPI';

// Fixes "Cannot assign to read only property" error message
// when modifying objects from Redux state directly.
// it is only for compatible production bundle meanwhile whe fix all mutation errors
// https://github.com/reduxjs/redux-toolkit/issues/424
if (process.env.NODE_ENV === 'production') {
  setAutoFreeze(false);
}

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['apiProductSlice'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};
const middleware = [
  thunk,
  postRehydrate,
  AlarmAPI.middleware,
  LocationAPI.middleware,
  ZoneSettings.middleware,
  TimerSettingsAPI.middleware,
  ScheduleAPI.middleware,
  StoreAPI.middleware,
  StructureAPI.middleware,
  UserAPI.middleware,
  WorkspaceAPI.middleware,
];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: true,
    }).concat(middleware),
  devTools: true,
  preloadedState: initialState,
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
export const persistor = persistStore(store);
setExternalStore(store);

export default store;
