import React, { useEffect } from 'react';
import * as yup from 'yup';
import Paper from '@mui/material/Paper';
import Input from '../../../theme/ui/Atoms/Input';
import Button from '../../../theme/ui/Atoms/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useErrorMessage from '../../../shared/hooks/useErrorMessage';
import { useTranslation } from 'react-i18next';
import { login } from '../../../redux/auth/action/thunks';
import LoginHeaderLogo from './LoginHeaderLogo';
import LoginFooter from './LoginFooter';
import ContainerFloatCenter from '../../../theme/ui/Layout/ContainerFloatCenter';

interface LoginProps extends ConnectedComponentProps {}
function Login(props: LoginProps) {
  const navigate = useNavigate();
  const errorMessage = useErrorMessage();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const error = searchParams.get('error');
    if (error) errorMessage(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = yup.object({
    storeId: yup.string().required(t('login.store_id_required') ?? ''),
    password: yup.string().required(t('login.password_required') ?? ''),
  });

  const formik = useFormik({
    initialValues: {
      storeId: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) =>
      props
        .login(values)
        .then(() => {
          navigate('/operation');
        })
        .catch((error) => {
          const message =
            error.code === 'ERR_BAD_REQUEST'
              ? t('error.login_failed')
              : error.message;
          errorMessage(message);
        }),
  });

  const onClickAsAManager = () => {
    navigate('/login-manager');
  };
  return (
    <ContainerFloatCenter>
      <LoginHeaderLogo />
      <Paper elevation={5} sx={{ padding: 3 }}>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h1">{t('login.title')}</Typography>
          <Input
            id="storeId"
            name="storeId"
            sx={{ mt: 2, mb: 2 }}
            label={t('login.store_id_label') ?? ''}
            placeholder={t('login.store_id_placeholder') ?? ''}
            required
            aria-required
            fullWidth
            value={formik.values.storeId}
            onChange={formik.handleChange}
            error={formik.touched.storeId && Boolean(formik.errors.storeId)}
            helperText={(formik.touched.storeId && formik.errors.storeId) || ''}
          />
          <Input
            id="password"
            name="password"
            sx={{ mt: 2, mb: 2 }}
            label={t('login.password')}
            placeholder={t('login.password_placeholder') ?? ''}
            type="password"
            required
            aria-required
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              (formik.touched.password && formik.errors.password) || ''
            }
          />
          <Button
            variant="text"
            size="large"
            rounded
            sx={{ mt: 4, mb: 2 }}
            disabled={formik.isSubmitting}
            fullWidth
            onClick={onClickAsAManager}
          >
            {t('login.login_with_manager')}
          </Button>

          <Button
            variant="contained"
            size="large"
            rounded
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            loading={formik.isSubmitting}
          >
            {t('login.button_title')}
          </Button>
        </form>
      </Paper>
      <LoginFooter />
    </ContainerFloatCenter>
  );
}

const connector = connect(null, {
  login: login,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(Login);
