import { AnyAction, combineReducers } from 'redux';
import LocationAPI from '../../service/locations/LocationAPI';
import TimerSettingsAPI from '../../service/timerSettings/TimerSettingsAPI';
import ZoneSettingsAPI from '../../service/zoneSettings/ZoneSettingsAPI';
import { AuthManagerSlice } from '../auth-manager/slice/AuthManagerSlice';
import { AuthSlice } from '../auth/slice/AuthSlice';
import { ControlUnitSlice } from '../controlUnit/slice/controlUnitSlice';
import { CUTranslationSlice } from '../CUTranslation/slice/CUTranslationSlice';
import { FeaturesSlice } from '../features/slice/FeaturesSlice';
import { MonitoringSlice } from '../monitoring/slice';
import { NetworkSlice } from '../network/slice/NetworkSlice';
import { ZoneSlice } from '../zone/slice/ZoneSlice';
import { GLOBAL_DESTROY_SESSION } from './types';
import { FeatureToggleSlice } from '../../shared/featureToggle';
import ScheduleAPI from '../../service/schedule/ScheduleAPI';
import AsyncTasksSlice from '../../pages/AuthenticatedPages/Operator/AsyncTasks/redux/slice/AsyncTasksSlice';
import StoreAPI from '../../service/store/StoreAPI';
import WorkspaceAPI from '../workspace/WorkspaceAPI';
import AlarmAPI from '../alarm/AlarmAPI';
import UserAPI from '../user/UserAPI';

const appReducer = combineReducers({
  [AlarmAPI.reducerPath]: AlarmAPI.reducer,
  [AuthSlice.name]: AuthSlice.reducer,
  [AuthManagerSlice.name]: AuthManagerSlice.reducer,
  [ZoneSlice.name]: ZoneSlice.reducer,
  [FeaturesSlice.name]: FeaturesSlice.reducer,
  [FeatureToggleSlice.name]: FeatureToggleSlice.reducer,
  [ControlUnitSlice.name]: ControlUnitSlice.reducer,
  [MonitoringSlice.name]: MonitoringSlice.reducer,
  [CUTranslationSlice.name]: CUTranslationSlice.reducer,
  [NetworkSlice.name]: NetworkSlice.reducer,
  [AsyncTasksSlice.name]: AsyncTasksSlice.reducer,
  [LocationAPI.reducerPath]: LocationAPI.reducer,
  [ZoneSettingsAPI.reducerPath]: ZoneSettingsAPI.reducer,
  [TimerSettingsAPI.reducerPath]: TimerSettingsAPI.reducer,
  [ScheduleAPI.reducerPath]: ScheduleAPI.reducer,
  [StoreAPI.reducerPath]: StoreAPI.reducer,
  [UserAPI.reducerPath]: UserAPI.reducer,
  [WorkspaceAPI.reducerPath]: WorkspaceAPI.reducer,
});

const rootReducer: typeof appReducer = (state, action: AnyAction) => {
  // Clear all data in redux store to initial.
  if (action.type === GLOBAL_DESTROY_SESSION) {
    state = undefined;
  }
  // eslint-disable-next-line no-console
  // console.log({ state, action });

  return appReducer(state, action);
};

export default rootReducer;
