import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../theme/ui/Atoms/Button';
import useBack from '../../../../shared/util/route-dom/hooks/useBack';
import ContainerBox from '../ContainerBox';
import InputSelect from '../../../../theme/ui/Atoms/InputSelect';
import { CheckIcon, TimeZoneIcon } from '../../../../assets';
import { getIn, useFormik } from 'formik';
import * as yup from 'yup';

// # get from ConfigServer.getTimezones from Commander Platform, updated if is necessary
import timezones from './timezones.json';
import BackButton from '../BackButton';
import useWatchError from '../../../../shared/hooks/useWatchError';
import { CommanderAPI } from '../../../../service/device/CommanderAPI/CommanderAPI';

const validationSchema = yup.object({
  timezone: yup
    .string()
    .required('initial_setup.timezone.timezone_required')
    .nullable(),
});

/**
 * Select the timezone for this specific scenario
 * - America/Chicago for CST (Hora Central, GMT -6)
 * - America/New_York for EST (Hora del Este, GMT -5)
 * - America/Los_Angeles for PDT (Hora del Pacífico, GMT -7)
 * - America/Denver for MDT (Hora de la Montaña, GMT -6)
 */
const SUPPORTED_ZONETIMES = [
  'America/Chicago',
  'America/New_York',
  'America/Los_Angeles',
  'America/Denver',
];
const mapTimezone = timezones
  .flatMap((timezone) => timezone.timezones)
  .filter((timezone) => SUPPORTED_ZONETIMES.includes(timezone.timezone));

function CommanderTimeZone() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickBack = useBack('/');
  const { commanderId = '' } = useParams<{ commanderId: string }>();

  const [
    changeTimezone,
    {
      isLoading: isChangingTimezone,
      error: errorChangingTimezone,
      isSuccess,
      data: updated,
    },
  ] = CommanderAPI.useSetTimezoneCommanderMutation();

  const formik = useFormik({
    initialValues: {
      timezone: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      navigate('../devices-setup-success');
    },
  });
  useWatchError(errorChangingTimezone);

  return (
    <ContainerBox>
      <Typography variant="h1" textAlign={'center'}>
        {t('initial_setup.timezone.title')}
      </Typography>

      <Box textAlign={'center'} my={4}>
        <TimeZoneIcon height={'4rem'} />
        <Typography mt={2}>
          {t('initial_setup.timezone.description')}
        </Typography>
        <Typography>
          {t('initial_setup.timezone.description_action')}
        </Typography>
      </Box>

      <Box minHeight={320} padding={4}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <InputSelect
            id={`timezone`}
            options={mapTimezone.map((timezone) => ({
              text: timezone.label || timezone.timezone,
              value: timezone.timezone,
            }))}
            onChange={(e: any, v: any) => {
              formik.setFieldValue('timezone', v);
              changeTimezone({ commanderId: commanderId, timezone: v });
            }}
            onBlur={formik.handleBlur}
            value={formik.values.timezone}
            disabled={isChangingTimezone}
            error={
              getIn(formik.errors, 'timezone') &&
              getIn(formik.touched, 'timezone')
            }
            helperText={
              !!updated ? (
                <Typography color={'success.main'}>
                  {t('initial_setup.timezone.timezone_updated')}
                </Typography>
              ) : getIn(formik.errors, 'timezone') &&
                getIn(formik.touched, 'timezone') ? (
                t(getIn(formik.errors, 'timezone'))
              ) : undefined
            }
            required
            aria-required
            fullWidth
          />
          {isChangingTimezone ? (
            <Box color={'success.main'}>
              <CircularProgress />
            </Box>
          ) : isSuccess ? (
            <Box color={'success.main'}>
              <CheckIcon height={'2rem'} />
            </Box>
          ) : null}
        </Stack>
      </Box>

      <Grid container spacing={2} justifyContent={'space-between'}>
        <Grid item xs={12} sm={2}>
          <BackButton onClick={onClickBack} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            rounded
            fullWidth
            onClick={() => formik.handleSubmit()}
          >
            {t('button.next')}
          </Button>
        </Grid>
      </Grid>
    </ContainerBox>
  );
}

export default CommanderTimeZone;
