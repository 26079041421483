import React, { useEffect, useState } from 'react';
import Table from '../../../../theme/ui/Atoms/Table/Table';
import Button from '../../../../theme/ui/Atoms/Button';
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { AirConnectIcon, ScanAirConnectIcon } from '../../../../assets';
import { useTranslation } from 'react-i18next';
import PaperButton from '../../../../theme/ui/Atoms/PaperButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useBack from '../../../../shared/util/route-dom/hooks/useBack';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../BackButton';
import CommanderAirConnectAPI from '../../../../service/device/CommanderAirConnectAPI/CommanderAirConnectAPI';
import useParseErrorMessage from '../../../../shared/hooks/useParseErrorMessage';
import { waitPromise } from '../../../../shared/util/waitPromise';

// one array of at least one string
const validationSchema = yup.array().of(yup.string()).min(1);

const initialValues: string[] = [];
function ScanAirConnect() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const parseErrorMessage = useParseErrorMessage();
  const { commanderId = '' } = useParams<{
    commanderId: string;
    storeId: string;
  }>();

  const [
    refetchNodesToInstall,
    {
      isFetching: isFetchingNodesToInstall,
      data: nodesToInstall,
      error: errorNodesToInstall,
    },
  ] = CommanderAirConnectAPI.useLazyGetNodesToInstallQuery();

  // Filter values with refetchNodesToInstall
  useEffect(() => {
    const newValues = formik.values.filter((value) =>
      nodesToInstall?.some((node) => node.macAddress === value)
    );
    formik.setValues(newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesToInstall]);

  const [
    refetchNodesInstalled,
    {
      isFetching: isFetchingNodesInstalled,
      data: nodesInstalled,
      error: errorNodesInstalled,
    },
  ] = CommanderAirConnectAPI.useLazyGetNodesInstalledQuery();

  const [
    installNodes,
    { isLoading: isInstallingNodes, error: errorInstallNodes },
  ] = CommanderAirConnectAPI.useInstallNewNodesMutation();
  const [loading, setLoading] = useState(false);

  const error = errorNodesToInstall || errorNodesInstalled || errorInstallNodes;
  const isFetching =
    isFetchingNodesToInstall ||
    isFetchingNodesInstalled ||
    isInstallingNodes ||
    loading;
  const refetch = () => {
    Promise.resolve(setLoading(true))
      .then(() => refetchNodesToInstall(commanderId).unwrap())
      .then(() => waitPromise(1000 * 10))
      .then(() => refetchNodesInstalled(commanderId).unwrap())
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      return installNodes({ commanderId, macAddresses: values })
        .unwrap()
        .then(() =>
          navigate('../devices-setup', { state: { macAddresses: values } })
        );
    },
  });

  const onClickMacAddress = (macAddress: string) => {
    if (formik.values.includes(macAddress)) {
      formik.setValues(formik.values.filter((v) => v !== macAddress));
    } else {
      formik.setValues([...formik.values, macAddress]);
    }
  };

  const toggleAll = () => {
    if (nodesToInstall?.length === formik.values.length) {
      formik.setValues(initialValues);
    } else {
      formik.setValues(nodesToInstall?.map((node) => node.macAddress) || []);
    }
  };

  const onClickBack = useBack('/');
  return (
    <Container maxWidth="xl">
      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {parseErrorMessage(error)}
        </Alert>
      ) : null}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: 2,
          fill: (theme) => theme.palette.text.primary,
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <AirConnectIcon height={'2em'} />
          <Typography
            variant="subtitle2"
            marginY={2}
            sx={{
              display: 'flex',
              marginLeft: 3,
              alignItems: 'center',
            }}
          >
            {t('initial_setup.scan_air_connect.node_found', {
              count: nodesToInstall?.length || 0,
            })}
            : {nodesToInstall?.length || 0}
          </Typography>
        </Stack>
        <Button
          variant="contained"
          color="primary"
          size="large"
          loading={isFetching}
          onClick={refetch}
        >
          <Stack
            direction={'row'}
            spacing={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <ScanAirConnectIcon height={'2em'} />
            <p>{t('initial_setup.scan_air_connect.action_rescan')}</p>
          </Stack>
        </Button>
      </Box>

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        {!isFetchingNodesToInstall && !isFetchingNodesInstalled && !loading ? (
          <Table
            sx={{
              '& th.header_store': {
                minWidth: 400,
              },
            }}
          >
            <caption></caption>

            <thead style={{ border: 'none' }}>
              <PaperButton component={'tr'} onChange={toggleAll}>
                <Typography
                  component={'th'}
                  color="primary"
                  variant="subtitle2"
                >
                  <Checkbox
                    checked={nodesToInstall?.length === formik.values.length}
                  />
                </Typography>
                <Typography
                  component={'td'}
                  variant="subtitle2"
                  sx={{ textAlign: 'left!important' }}
                >
                  {t('initial_setup.scan_air_connect.select_all')}
                </Typography>
              </PaperButton>
            </thead>
            <tbody>
              {nodesToInstall?.map((node) => {
                return (
                  <PaperButton
                    component={'tr'}
                    key={node.macAddress}
                    elevation={undefined}
                    onClick={() => onClickMacAddress(node.macAddress)}
                  >
                    <td>
                      <Typography variant="body1">
                        <Checkbox
                          checked={formik.values.includes(node.macAddress)}
                        />
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1" textAlign={'left'}>
                        {t(
                          'initial_setup.scan_air_connect.node_type_' +
                            node?.nodeType
                        )}
                        <Tooltip title={node.macAddress}>
                          <Typography
                            variant="body2"
                            color={'primary'}
                            component={'span'}
                            ml={2}
                          >
                            {t('initial_setup.scan_air_connect.more_info')}
                          </Typography>
                        </Tooltip>
                      </Typography>
                    </td>
                  </PaperButton>
                );
              })}

              {nodesInstalled?.map((node) => {
                return (
                  <Paper
                    component={'tr'}
                    key={node.macAddress}
                    elevation={undefined}
                  >
                    <td>{t('initial_setup.scan_air_connect.node_found')}</td>
                    <td>
                      <Typography variant="body1" textAlign={'left'}>
                        {t(
                          'initial_setup.scan_air_connect.node_type_' +
                            node?.nodeType
                        )}
                        <Tooltip title={node.macAddress}>
                          <Typography
                            variant="body2"
                            color={'primary'}
                            component={'span'}
                            ml={2}
                          >
                            {t('initial_setup.scan_air_connect.more_info')}
                          </Typography>
                        </Tooltip>
                      </Typography>
                    </td>
                  </Paper>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Box textAlign={'center'} mt={4}>
            <Stack spacing={2} alignItems={'center'}>
              <CircularProgress size={'8em'} />
              <Typography>
                {t('initial_setup.scan_air_connect.scan_loading')}
              </Typography>
            </Stack>
          </Box>
        )}

        <Grid container spacing={2} justifyContent={'space-between'} mt={2}>
          <Grid item xs={12} sm={2}>
            <BackButton onClick={onClickBack} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              rounded
              fullWidth
              disabled={!formik.isValid || isFetching}
              loading={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              {t('button.next')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default ScanAirConnect;
