import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../theme/ui/Atoms/Button';
import { fromBase64 } from '../../../../shared/util/base64';
import Input from '../../../../theme/ui/Atoms/Input';
import useBack from '../../../../shared/util/route-dom/hooks/useBack';
import BackButton from '../BackButton';
import ContainerBox from '../ContainerBox';
import { GroupAPIResponse } from '../../../../service/group/group.model';
import LocationAPI from '../../../../service/locations/LocationAPI';
import { CheckIcon, ScanAirConnectIcon } from '../../../../assets';
import useErrorMessage from '../../../../shared/hooks/useErrorMessage';
import AlertMessage from '../AlertMessage';
import { CommanderAPI } from '../../../../service/device/CommanderAPI/CommanderAPI';

const validationSchema = yup.object({
  serialNumber: yup
    .string()
    .required(
      'initial_setup.connect_commander.commander_serial_number_required'
    ),
});
function SearchCommanderSetUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [triggerGetCommander, resultCommander] =
    CommanderAPI.useLazyGetDeviceBySerialNumberQuery();

  const { storeId = '' } = useParams<{
    commanderId: string;
    serialNumber: string;
    storeId: string;
  }>();

  const {
    error: errorCommander,
    data: dataCommander,
    isFetching: isFetchingCommander,
    isSuccess: isSuccessCommander,
  } = resultCommander;

  const errorCommanderNotFound =
    isSuccessCommander && !dataCommander
      ? new Error('initial_setup.connect_commander.commander_not_found_error')
      : undefined;
  const errorMitigated =
    // eslint-disable-next-line eqeqeq
    (errorCommander as AxiosResponse)?.status == 404
      ? new Error('initial_setup.connect_commander.commander_not_found_error')
      : errorCommander;

  const catchEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const formik = useFormik<{
    store: GroupAPIResponse | null;
    storeId: string;
    serialNumber: string;
  }>({
    initialValues: {
      serialNumber: '',
      store: null,
      storeId: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      triggerGetCommander(`900333.014-${values.serialNumber}`);
    },
  });
  const onClickBack = useBack('/');
  const showError = useErrorMessage();
  const [
    registerCommanderToStore,
    { isLoading: isLoadingRegisteringCommander },
  ] = LocationAPI.useRegisterCommanderStoreMutation();
  const onClickNext = () => {
    const extendedSerialNumber = `900333.014-${formik.values.serialNumber}`;
    registerCommanderToStore({
      storeId: storeId,
      serialNumber: extendedSerialNumber,
      data: {
        serialnumber: extendedSerialNumber,
        unitName: dataCommander?.name,
      },
    })
      .unwrap()
      .then(() => {
        navigate(
          `../commander/${fromBase64(
            dataCommander?.uuid ?? ''
          )}/serialNumber/${extendedSerialNumber}/store/${storeId}/check-list`
        );
      })
      .catch((error) => showError(error));
  };

  const error = errorMitigated || errorCommanderNotFound;

  return (
    <ContainerBox maxWidth="sm">
      <Typography variant="h1">
        {t('initial_setup.connect_commander.title')}
      </Typography>

      <Grid container spacing={2} my={4}>
        <Grid item xs={12}>
          <Typography>
            {t('initial_setup.connect_commander.commander_serial_number_label')}
          </Typography>
        </Grid>
        <Grid item xs>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Typography variant="body2" color="textSecondary">
              900333.014-
            </Typography>
            <Input
              placeholder={
                t(
                  'initial_setup.connect_commander.commander_serial_number_placeholder'
                ) ?? undefined
              }
              fullWidth
              required
              aria-required
              name="serialNumber"
              value={formik.values.serialNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={catchEnter}
              error={
                formik.touched.serialNumber &&
                Boolean(formik.errors.serialNumber)
              }
              helperText={
                formik.touched.serialNumber && formik.errors.serialNumber
                  ? t(formik.errors.serialNumber)
                  : ''
              }
            />

            {dataCommander ? (
              <Box color={'success.main'} fontSize={'2rem'} display={'flex'}>
                <CheckIcon />
              </Box>
            ) : null}
          </Stack>
        </Grid>

        <Grid item width={'150px'}>
          <Button
            variant="contained"
            color="primary"
            rounded
            fullWidth
            disabled={!formik.isValid}
            onClick={() => formik.handleSubmit()}
            loading={isFetchingCommander}
          >
            <ScanAirConnectIcon height={'2em'} />
          </Button>
        </Grid>
      </Grid>

      <Divider />

      <Box mt={2} minHeight={'100px'} padding={4}>
        {!!error && <AlertMessage error={error} />}
      </Box>

      <Grid container spacing={2} justifyContent={'space-between'}>
        <Grid item xs={12} sm={2}>
          <BackButton onClick={onClickBack} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            rounded
            fullWidth
            loading={isLoadingRegisteringCommander}
            disabled={
              !!errorCommander ||
              !dataCommander ||
              isLoadingRegisteringCommander
            }
            onClick={onClickNext}
          >
            {t('button.next')}
          </Button>
        </Grid>
      </Grid>
    </ContainerBox>
  );
}

export default SearchCommanderSetUp;
