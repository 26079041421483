import useMyWorkspace from './useMyWorkspace';
import { useMemo } from 'react';

type IFun = typeof useMyWorkspace;
function useMachineTypes(
  arg1?: Parameters<IFun>[0],
  arg2?: Parameters<IFun>[1]
) {
  const response = useMyWorkspace(arg1, arg2);
  const sorted = useMemo(
    () =>
      response.data?.machineTypes.slice().sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      ),
    [response.data?.machineTypes]
  );
  return { ...response, data: sorted };
}

export default useMachineTypes;
