import { createSelector } from '@reduxjs/toolkit';
import StructureAPI from '../StructureAPI';

export const getStructure = StructureAPI.endpoints.getStructureV2.select();

export const structureInfo = createSelector(
  [getStructure],
  (structure) => structure
);

export const isLoadingStructure = createSelector(
  [getStructure],
  (structure) => structure.isLoading
);

export const structureStatus = createSelector(
  [getStructure],
  (structure) => structure.status
);

export const structureData = createSelector(
  [getStructure],
  (structure) => structure.data
);

export const structureError = createSelector(
  [getStructure],
  (structure) => structure.error
);
