import { createSlice } from '@reduxjs/toolkit';
import { setWith } from 'lodash';
import { ControlUnitAPIResponse } from '../../../service/controlUnit/ControlUnit.model';
import { DeviceControlUnitsAPIResponse } from '../../../service/device/device.model';
import {
  DigitalChannelEvent,
  SensordataNewest,
  UUID,
} from '../../../service/monitoring/monitoring.model';
import { arrayToRecord } from '../../../shared/ArrayUtil';
import { RecordLVL2, RecordLVL3 } from '../../../shared/types/Records';

import {
  fetchCurrentEventsControlUnits,
  fetchCurrentSensorDataControlUnits,
  fetchDeviceControlUnits,
} from '../actions/asyncThunks';
import { nameReducer } from '../controlUnit.model';

export const initialStateControlUnits: ControlUnitState = {
  dictionaryControlUnit: {},
  loadingDeviceControlUnit: {},
  errorDeviceControlUnit: {},
  dictionaryCurrentEventsControlUnit: {},
  loadingCurrentEventsControlUnit: {},
  errorCurrentEventsControlUnit: {},
  dictionaryCurrentSensorDataControlUnit: {},
  loadingCurrentSensorDataControlUnit: {},
  errorCurrentSensorDataControlUnit: {},
};

type LinkedDigitalEvent = RecordLVL3<
  UUID,
  ControlUnitAPIResponse['id'],
  DigitalChannelEvent['eventID'],
  DigitalChannelEvent
>;

type LinkedDigitalSensorData = RecordLVL3<
  UUID,
  ControlUnitAPIResponse['id'],
  SensordataNewest['channel'],
  SensordataNewest
>;

export interface ControlUnitState {
  dictionaryControlUnit: Record<
    UUID,
    | Record<DeviceControlUnitsAPIResponse['id'], DeviceControlUnitsAPIResponse>
    | undefined
  >;
  loadingDeviceControlUnit: Record<UUID, boolean | undefined>;
  errorDeviceControlUnit: Record<UUID, any | undefined>;
  // Current Events
  dictionaryCurrentEventsControlUnit: LinkedDigitalEvent;
  loadingCurrentEventsControlUnit: Record<UUID, boolean | undefined>;
  errorCurrentEventsControlUnit: Record<UUID, any | undefined>;
  // Current SensorData
  dictionaryCurrentSensorDataControlUnit: LinkedDigitalSensorData;
  loadingCurrentSensorDataControlUnit: Record<UUID, boolean | undefined>;
  errorCurrentSensorDataControlUnit: Record<UUID, any | undefined>;
}

export const ControlUnitSlice = createSlice({
  name: nameReducer,
  initialState: initialStateControlUnits,
  reducers: {},
  extraReducers: (builder) => {
    // fetchDeviceControlUnits Thunk
    builder.addCase(fetchDeviceControlUnits.pending, (state, action) => {
      state.loadingDeviceControlUnit[action.meta.arg.deviceId] = true;
      state.errorDeviceControlUnit[action.meta.arg.deviceId] = undefined;
    });
    builder.addCase(fetchDeviceControlUnits.fulfilled, (state, action) => {
      // fulfilled are in other action
      state.loadingDeviceControlUnit[action.meta.arg.deviceId] = false;
      state.errorDeviceControlUnit[action.meta.arg.deviceId] = undefined;
      if (action.payload)
        state.dictionaryControlUnit[action.meta.arg.deviceId] = arrayToRecord(
          action.payload.controlunits,
          'id'
        );
    });
    builder.addCase(fetchDeviceControlUnits.rejected, (state, action) => {
      state.loadingDeviceControlUnit[action.meta.arg.deviceId] = false;
      state.errorDeviceControlUnit[action.meta.arg.deviceId] = action.error;
    });

    // fetchCurrentAlarmsControlUnits Thunk
    builder.addCase(fetchCurrentEventsControlUnits.pending, (state, action) => {
      state.loadingCurrentEventsControlUnit[action.meta.arg.deviceId] = true;
      state.errorCurrentEventsControlUnit[action.meta.arg.deviceId] = undefined;
    });
    builder.addCase(
      fetchCurrentEventsControlUnits.fulfilled,
      (state, action) => {
        // fulfilled are in other action
        state.loadingCurrentEventsControlUnit[action.meta.arg.deviceId] = false;
        state.errorCurrentEventsControlUnit[action.meta.arg.deviceId] =
          undefined;
        if (action.payload)
          state.dictionaryCurrentEventsControlUnit[action.meta.arg.deviceId] =
            arrayToRecord(action.payload, 'idCU', (cuEvents) =>
              arrayToRecord(cuEvents.events, 'eventID')
            );
      }
    );

    builder.addCase(
      fetchCurrentEventsControlUnits.rejected,
      (state, action) => {
        state.loadingCurrentEventsControlUnit[action.meta.arg.deviceId] = false;
        state.errorCurrentEventsControlUnit[action.meta.arg.deviceId] =
          action.error;
      }
    );

    // fetchCurrentAlarmsControlUnits Thunk
    builder.addCase(
      fetchCurrentSensorDataControlUnits.pending,
      (state, action) => {
        state.loadingCurrentSensorDataControlUnit[action.meta.arg] = true;
        state.errorCurrentSensorDataControlUnit[action.meta.arg] = undefined;
      }
    );
    builder.addCase(
      fetchCurrentSensorDataControlUnits.fulfilled,
      (state, action) => {
        // fulfilled are in other action
        state.loadingCurrentSensorDataControlUnit[action.meta.arg] = false;
        state.errorCurrentSensorDataControlUnit[action.meta.arg] = undefined;
        if (action.payload)
          state.dictionaryCurrentSensorDataControlUnit[action.meta.arg] =
            action.payload.reduce((acc, currentItem) => {
              setWith(
                acc,
                [currentItem.cuId, currentItem.channel],
                currentItem,
                Object
              );
              return acc;
            }, {} as RecordLVL2<ControlUnitAPIResponse['id'], SensordataNewest['channel'], SensordataNewest>);
      }
    );

    builder.addCase(
      fetchCurrentSensorDataControlUnits.rejected,
      (state, action) => {
        state.loadingCurrentEventsControlUnit[action.meta.arg] = false;
        state.errorCurrentEventsControlUnit[action.meta.arg] = action.error;
      }
    );
  },
});

export const {
  actions: actionsControlUnit,
  reducer: reducerControlUnit,
  name: nameReducerControlUnit,
} = ControlUnitSlice;
