import { createAsyncThunk } from '@reduxjs/toolkit';
import { nameReducer } from '../controlUnit.model';
import { MonitoringCalls } from '../../../service/monitoring/MonitoringCalls';
import ControlUnitCalls from '../../../service/controlUnit/ControlUnitCalls';

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

export const fetchDeviceControlUnits = createAsyncThunk(
  `${nameReducer}/fetchDeviceControlUnits`,
  async (params: { deviceId: string; commanderId: string }) => {
    const { deviceId, commanderId } = params;
    const controlUnitCalls = new ControlUnitCalls();
    return await controlUnitCalls.getControlUnitsByDeviceId(
      deviceId,
      commanderId
    );
  }
);

export const fetchCurrentEventsControlUnits = createAsyncThunk(
  `${nameReducer}/fetchCurrentEventsControlUnits`,
  async (params: { deviceId: string; commanderId: string }) => {
    const { deviceId, commanderId } = params;
    const monitoringCalls = new MonitoringCalls();
    const response = await monitoringCalls.getCurrentEventsByID({
      deviceId,
      commanderId,
    });
    return response;
  }
);

export const fetchCurrentSensorDataControlUnits = createAsyncThunk(
  `${nameReducer}/fetchCurrentSensorDataControlUnits`,
  async (commanderId: string) => {
    const monitoringCalls = new MonitoringCalls();
    const response = await monitoringCalls.getCurrentSensorDataByID(
      commanderId
    );
    return response;
  }
);
