import React, { createContext, useRef, ReactNode, useCallback } from 'react';

interface ErrorEntry {
  message: string;
  timestamp: number;
}

interface ErrorContextProps {
  errors: React.MutableRefObject<ErrorEntry[]>;
  addError: (error: string) => void;
}

export const ErrorContext = createContext<ErrorContextProps>({
  errors: { current: [] },
  addError: () => {},
});

export const ErrorProvider = ({ children }: { children: ReactNode }) => {
  const errors = useRef<ErrorEntry[]>([]);

  const addError = useCallback((error: string) => {
    const now = Date.now();
    errors.current = errors.current.filter(
      (entry) => now - entry.timestamp < 5000
    );
    errors.current.push({ message: error, timestamp: now });
  }, []);

  return (
    <ErrorContext.Provider value={{ errors, addError }}>
      {children}
    </ErrorContext.Provider>
  );
};
